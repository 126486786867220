export const longText = `
The term “terrain” was coined by French physiologist Claude Bernard in the 19th century, who used the term for the body’s internal environment. Since the body comprises mostly water, the terrain of the body is in essence an aqueous medium. This includes the blood, lymph and the fluids inside of and between the cells.

The state of the terrain is determined by four factors, namely: its acid/alkaline balance; its electric/magnetic charge; its level of poisoning and its nutritional status (including oxygenation). The condition of the terrain is absolutely central to the balance between health and disease. We can easily understand the notion that unhealthy soil will yield unhealthy, disease-ridden plants, while healthy soil will produce strong, healthy plants. The same concept applies when we look at the terrain of the body: if it is balanced it will promote health and if it is unbalanced it will lead to disease. An unbalanced terrain disrupts the normal functions of the body: the immune system is less active, enzymes, hormones and other chemical messengers do not function properly, the elimination of toxins is less effective, prostaglandins and other pro-inflammation chemicals accumulate, digestion becomes inadequate, etc. The body struggles to maintain balance through its many mechanisms, but eventually fails when the level of disruption reaches a tipping point, especially when there is a lack of proper nutrition.

This is when a variety of symptoms may begin, such as headaches, recurring infections, skin problems, etc, depending on our individual susceptibility, types of toxins and deficiencies. Most people then turn to chemical drugs to suppress the symptoms, like painkillers to numb the pain, cortisone creams, antihistamines, antibiotics, etc. These unnatural measures only fuel the fire by aggravating the levels of toxicity, acidity and the degree of disruption of normal functions. They afford only temporary relief, while worsening the underlying imbalances that led to the development of the symptoms in the first place.

A large part of any natural treatment protocol, for any given disease, is focused on correcting the imbalances of the terrain. We maintain that if the terrain is healthy, the rest of the body will be healthy and resistant to disease and infection. We strive to understand the question why some people stay healthy when exposed to contagious diseases, instead of labouring to identify a causative organism for a given infection and trying to kill it. Just like seeds will not germinate when thrown on asphalt, bacteria will not take hold in an unfavourable environment. Our aim is therefore to improve the condition of the terrain so that all functions in the body are optimal and harmonious and the terrain is unsuitable to infection. 

The following factors are required for a balanced, healthy terrain: Water, Oxygen, Minerals, Alkalinity, Nutrition and low toxicity levels.

What is meant by “ecological imbalance”? The body is a very complex system that is also a home to a number of beneficial organisms. Many people are familiar with the condition Candida. This condition is a prime example of ecological balance gone wrong. Here the beneficial yeast organism, Candida albicans, which normally lives in the intestinal tract, begins to multiply and reach population numbers that begin to have a negative effect on the body. The symbiotic relationship, which was mutually beneficial to both the yeast and the host, has been disturbed and becomes unhealthy. This change is triggered by many factors, including antibiotics, diarrhoea, stress, sugar, etc.

When we talk of “ecological imbalance” we are referring to the many different mutually beneficial relationships between the body and its many inhabitants that have been derailed by an unbalanced terrain. In normal conditions the system is able to maintain the balance of the many organisms that inhabit it (called “commensals”). When the terrain becomes unbalanced, the conditions become favourable for disease- causing forms of the commensals, or the conditions become favourable for overgrowth of these organisms. This disturbs many other delicate balances in the body, just like any ecological imbalance in a natural environment would do.

Dietary changes to correct an unbalanced terrain: Correcting the state of the terrain takes more than simply swallowing a pill, unfortunately. The diet and lifestyle, as well as mental/emotional factors such as stress, play a very important role. A few basic rules to keep in mind include the following: (I know that this is very different from what most people are used to, but try to make the changes where you can and make changes that you will be able to maintain. It only works if you can keep it up!) 

1.) Avoid all junk “non-foods”. These include most fast foods, any processed food, refined starch (including sugar), saturated food/fried food and additives. This also includes all soft drinks, caffeinated beverages (coffee, tea), tobacco and alcohol. It also includes condiments and foods with vinegar, sugar and other additives.

2.) Reduce the intake of animal protein. Avoid pork and all processed meat completely. Intake of lean meat, chicken, game and fish is allowed in moderation, provided they are not eaten with starch (only vegetables or a salad).

3.) Avoid heated oils and hydrogenated fats (found in processed food and margarine).

4.) Avoid microwaved food.

5.) Increase water intake. You should be getting at least 2 litres water in daily – in the form of water. Boiling and filtering the water will remove most impurities.

6.) Eat as much dark green and yellow vegetables as you can – as close to its natural state as you can.

7.) Include more raw food in your diet.

8.) Eat sprouted seeds and grains, they’re alkalinizing.

9.) Include raw oils in your diet (flax oil, olive oil, hempseed oil, fish oil capsules).

10.) Vegetable juices (freshly prepared) are very beneficial.

11.) Eat smaller meals, more frequently and take smaller bites. Eat slowly and chew properly. Try to put your stresses and other preoccupations aside when eating and focus on eating calmly.
`;

export const disclaimer = [
  [{ content: "Disclaimer:", styles: { fontStyle: "bold" } }],
  [
      "BloodWorksTM Naturopathic Blood Analysis is not a medical diagnostic procedure. The information provided in this report should not be construed as medical advice and is not intended to replace the advice of a medical practitioner or standard pathological testing. BloodWorksTM analysis is utilized as a nutritional assessment and education tool to assist with dietary and lifestyle recommendations.",
  ],
];

export const tableIntro = [
  [
    "Your blood analysis report consists of results of two separate samples: 1) Live blood and 2) Dried, layered blood. The findings /anomalies listed in your report are based on deviations from “Normal Blood”, as illustrated below. The Grade noted under each anomaly is an indication of the degree of deviation from normal. We use 5-point grading scale:\n 1/5: Minimal\n 2/5: Mild\n 3/5: Moderate\n 4/5: Severe\n 5/5: Extreme\nThe dry blood samples consist of 8 separate samples (layers) and anomalies can occur in more than one layer, indicated in the report Observed in Layer(s).",
  ],
];
export function getTableHeader(visitId, displayId, date, results) {
  const patientData = results && results[0] && results[0].data ? results[0].data : {};
  
  return [
    ["", "", "", "", "", "", "", "", "", "", "TEST NO:", `${displayId}` ?? ""],
    ["", "", "", "", "", "", "", "", "", "", "TEST DATE:", `${date}` ?? ""],
    ["", "", "", "", "", "", "", "", "", "", "CLIENT NAME:", `${patientData.name ?? ""} ${patientData.surname ?? ""}`],
    ["", "", "", "", "", "", "", ""],
    ["TEL:", patientData.contactDetails?.homeNumber ?? ""],
    ["CELL:", patientData.contactDetails?.cellphoneNumber ?? ""],
    ["EMAIL:", patientData.contactDetails?.email ?? ""],
    ["DOB:", patientData.dateOfBirth ?? ""],
  ];
}

