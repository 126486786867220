import axios from 'axios';
import { apiBaseUrl } from '../utilities';

// Function to create a new practice
export const createPractice = async (token, practiceData) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/admin/v1/practices`, practiceData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating practice:', error);
    throw error;
  }
};

// Function to delete an existing practice
export const deletePractice = async (token, practiceId) => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/admin/v1/practices/${practiceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting practice:', error);
    throw error;
  }
};

// Function to fetch practice details by practiceId
export const getPracticeDetailsById = async (token, practiceId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/cmn/v1/practices/${practiceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching practice details:', error);
    throw error;
  }
};

// Function to update an existing practice
export const updatePractice = async (token, practiceId, practiceData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/admin/v1/practices/${practiceId}`, practiceData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating practice:', error);
    throw error;
  }
};
