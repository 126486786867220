import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import RecentSnap from "./RecentSnap";

function RecentSnaps({ snaps, ...rest }) {
  return (
    <>
      <Typography variant="h4" sx={{ m: 1 }}>
        Recent Snapshots
      </Typography>
      <Grid container spacing={2} marginBottom={2} width="100%">
        {Object.keys(snaps).map((snapKey) => {
          const snap = snaps[snapKey];
          return (
            <Grid key={`recent_snap_${snap.id}`} xs={12} md={6} lg={4}>
              <RecentSnap snap={snap} {...rest} />
            </Grid>
          );
        })}
      </Grid>
      {Object.keys(snaps).length === 0 && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          Please capture or upload some snapshots
        </Alert>
      )}
    </>
  );
}

export default RecentSnaps;
