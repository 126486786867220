import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Unstable_Grid2";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Divider,
  Switch,
  FormControl,
  FormHelperText,
  InputLabel,
  FormControlLabel,
  Stack,
  Alert,
} from "@mui/material";
import {
  QueryClient,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import "./PatientDetailsForm.css";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { selectBearerToken, selectUserGroup } from "../../globalSlice";
import MaterialField from "../../Components/Formik/MaterialField";
import { useLocation, useNavigate } from "react-router-dom";
import { createPatient, updatePatient } from "../../fetchers/patients";
import { FormMode } from "../../utilities";
import { provinceOptions } from "../../utilities_forms";
import { getCompaniesPaginated } from "../../fetchers/companies";
import { parse, format, parseISO } from "date-fns";
import styled from "@emotion/styled";
import { getNames, getCode } from "country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getMyPracticeDetails } from "../../api/practiceUserApi";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PageHeader = styled.h4`
  padding: 15px 20px;
  margin: 0 0 30px;
  font-size: 20px;
  font-weight: 600;
  color: #333366;
  background-color: #ffffff;
  border-bottom: 2px solid #333366;
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Column = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
`;

const Row10 = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
`;

const Row20 = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const Row15 = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
`;

const Heading = styled.h2`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
  font-size: 26px;
  font-weight: 500;
`;

const ColumnHide = styled.div`
  display: none;
  width: 100%;
`;

const countryOptions = getNames()
  .map((name) => ({ key: name, value: getCode(name) }))
  .sort((a, b) => a.key.localeCompare(b.key));

export default function AddPatientPage({ existingPatient = null }) {
  let mode = existingPatient ? FormMode.EDIT : FormMode.ADD;

  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [usePhysicalAddress, setUsePhysicalAddress] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(selectBearerToken);
  const [companyId, setCompanyId] = useState(null);
  const userGroup = useSelector(selectUserGroup);

  useEffect(() => {
    // Check if the user is a Practice User
    if (userGroup === 'PRACTICE_USER') {
      getMyPracticeDetails(token)
        .then((data) => {
          setCompanyId(data.companyId);
        })
        .catch((error) => {
          console.error("Failed to fetch practice details:", error);
          setErrorMsg("Failed to load practice details.");
        });
    }
  }, [token, userGroup]); // Ensure the effect runs when the token or userGroup changes


  const { isPreviousData, isLoading, data } = useQuery({
    queryKey: ["companiesPaginated", page],
    queryFn: async () => getCompaniesPaginated(token, page),
    keepPreviousData: true,
    staleTime: 5000,
    retry: false,
  });

  useEffect(() => {
    if (!isPreviousData && data?.totalPages < page - 1) {
      queryClient.prefetchQuery({
        queryKey: ["companiesPaginated", page],
        queryFn: () => getCompaniesPaginated(token, page),
      });
    }
  }, [isLoading, data, queryClient]);

  existingPatient = location.state?.existingPatient
    ? location.state.existingPatient
    : null;

  mode = location.state?.existingPatient ? FormMode.EDIT : FormMode.ADD;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const companyOptions = data?.content.map((option) => {
    return { key: option.name, value: option.id };
  });

  const genderOptions = [
    { key: "Male", value: "MALE" },
    { key: "Female", value: "FEMALE" },
  ];

  const patientTitles = [
    { key: "Mr.", value: "Mr." },
    { key: "Ms.", value: "Ms." },
    { key: "Miss", value: "Miss" },
    { key: "Mrs.", value: "Mrs." },
    { key: "Dr.", value: "Dr." },
    { key: "Prof.", value: "Prof." },
  ];

  const initialValues = {
    fName: existingPatient?.name || "",
    lName: existingPatient?.surname || "",
    countryCode: existingPatient?.countryCode || "",
    cNumber: existingPatient?.contactDetails?.cellphoneNumber || "",
    ...(mode === FormMode.EDIT
      ? {
          // Personal details
          dateOfBirth: existingPatient?.dateOfBirth || null,
          title: existingPatient?.title || "",
          gender: existingPatient?.gender || "",
          southAfricanId: existingPatient?.idNumber || "",
          company: existingPatient?.companyId || "",
          // Contact details
          hNumber: existingPatient?.contactDetails?.homeNumber || "",
          wNumber: existingPatient?.contactDetails?.workNumber || "",
          email: existingPatient?.contactDetails?.email || "",
          relativeName: existingPatient?.contactDetails?.familyMember || "",
          rContactNumber:
            existingPatient?.contactDetails?.familyMemberNumber || "",
          // Address details
          addressLine1: existingPatient?.physicalAddress?.addressLine1 || "",
          addressLine2: existingPatient?.physicalAddress?.addressLine2 || "",
          city: existingPatient?.physicalAddress?.city || "",
          province: existingPatient?.physicalAddress?.province || "",
          postalCode: existingPatient?.physicalAddress?.postalCode || "",
          // Medical details
          medicalAidProvider: existingPatient?.medicalDetails?.provider || "",
          medicalAidNumber:
            existingPatient?.medicalDetails?.medicalNumber || "",
          mainMember: existingPatient?.medicalDetails?.mainMember || "",
          currentConditions:
            existingPatient?.medicalDetails?.currentConditions || "",
          currentSupplements:
            existingPatient?.medicalDetails?.currentSupplements || "",
          currentMedication:
            existingPatient?.medicalDetails?.currentMedication || "",
          // Optional postal address
          pAddressLine1: existingPatient?.postalAddress?.addressLine1 || "",
          pAddressLine2: existingPatient?.postalAddress?.addressLine2 || "",
          pCity: existingPatient?.postalAddress?.city || "",
          pProvince: existingPatient?.postalAddress?.province || "",
          pPostalCode: existingPatient?.postalAddress?.postalCode || "",
        }
      : {}),
  };

  const validationSchema = Yup.object({
    fName: Yup.string().required("Required field"),
    lName: Yup.string().required("Required field"),
    countryCode: Yup.string().required("Required field"),
    
    ...(mode === FormMode.EDIT && {
      dateOfBirth: Yup.date().nullable(),
      title: Yup.string(),
      gender: Yup.string(),
      southAfricanId: Yup.string()
        .length(13, "Invalid ID Number: Too short")
        .test(
          "Numbers Only",
          "Invalid ID Number: ID can only contain numbers",
          (value) => !value || /^\d+$/.test(value),
        ),
      company: Yup.string(),
      cNumber: Yup.string().required("Required field"),
      hNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
      wNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
      email: Yup.string().email("Invalid email format"),
      relativeName: Yup.string(),
      rContactNumber: Yup.string().matches(
        phoneRegExp,
        "Phone number is not valid",
      ),
      addressLine1: Yup.string(),
      city: Yup.string(),
      province: Yup.string(),
      postalCode: Yup.string()
        .length(4, "Invalid postal code. Should be 4 digits long")
        .test(
          "Numbers Only",
          "Postal code can only contain numbers",
          (value) => !value || /^\d+$/.test(value),
        ),
      medicalAidProvider: Yup.string(),
      medicalAidNumber: Yup.string(),
      mainMember: Yup.string(),
      currentConditions: Yup.string(),
      currentSupplements: Yup.string(),
      currentMedication: Yup.string(),
    }),
  });

  const triggerSuccessMsg = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  };

  const handleSubmit = (values) => {
    setErrorMsg(null);

    const formatDate = (date) => {
      return format(new Date(date), "yyyy-MM-dd");
    };

    let formattedDateOfBirth = null;
    if (values.dateOfBirth) {
      formattedDateOfBirth = formatDate(values.dateOfBirth);
    }

    const jsonForm = {
      name: values.fName,
      surname: values.lName,
      countryCode: values.countryCode,
      companyId: companyId,
      contactDetails: {
        cellphoneNumber: values.cNumber,
        ...(values.hNumber && { homeNumber: values.hNumber }),
        ...(values.wNumber && { workNumber: values.wNumber }),
        ...(values.email && { email: values.email }),
        ...(values.relativeName && { familyMember: values.relativeName }),
        ...(values.rContactNumber && {
          familyMemberNumber: values.rContactNumber,
        }),
      },
      ...(values.dateOfBirth && { dateOfBirth: formattedDateOfBirth }),
      ...(values.title && { title: values.title }),
      ...(values.gender && { gender: values.gender }),
      ...(values.southAfricanId && { idNumber: values.southAfricanId }),
      medicalDetails: {
        ...(values.medicalAidProvider && {
          provider: values.medicalAidProvider,
        }),
        ...(values.medicalAidNumber && {
          medicalNumber: values.medicalAidNumber,
        }),
        ...(values.mainMember && { mainMember: values.mainMember }),
        ...(values.currentConditions && {
          currentConditions: values.currentConditions,
        }),
        ...(values.currentMedication && {
          currentMedication: values.currentMedication,
        }),
        ...(values.currentSupplements && {
          currentSupplements: values.currentSupplements,
        }),
      },
      physicalAddress: {
        ...(values.addressLine1 && { addressLine1: values.addressLine1 }),
        ...(values.addressLine2 && { addressLine2: values.addressLine2 }),
        ...(values.city && { city: values.city }),
        ...(values.province && { province: values.province }),
        ...(values.postalCode && { postalCode: values.postalCode }),
      },
      ...(values.company && { companyId: values.company }),
    };

    if (!usePhysicalAddress) {
      jsonForm.postalAddress = {
        ...(values.pAddressLine1 && { addressLine1: values.pAddressLine1 }),
        ...(values.pAddressLine2 && { addressLine2: values.pAddressLine2 }),
        ...(values.pCity && { city: values.pCity }),
        ...(values.pProvince && { province: values.pProvince }),
        ...(values.pPostalCode && { postalCode: values.pPostalCode }),
      };
    }

    setSubmitting(true);

    if (mode === FormMode.ADD) {
      createPatient(token, jsonForm)
        .then((results) => {
          triggerSuccessMsg();
          queryClient.invalidateQueries(["personal_details", page]);
        })
        .catch((error) => {
          try {
            setErrorMsg(error.response.data.message);
          } catch (e) {
            setErrorMsg(error.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });

        setTimeout(() => {
          navigate(-1); // Navigate back one step
        }, 2000);

    } else if (mode === FormMode.EDIT) {
      updatePatient(token, existingPatient.id, jsonForm)
        .then((results) => {
          triggerSuccessMsg();
          queryClient.invalidateQueries(["personal_details", page]);
        })
        .catch((error) => {
          try {
            setErrorMsg(error.response.data.message);
          } catch (e) {
            setErrorMsg(error.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const datePickerProps = {
    label: "Date of Birth",
    inputFormat: "yyyy-MM-dd",
    disableFuture: true,
    value: formik.values.dateOfBirth,
    onChange: (value) => formik.setFieldValue("dateOfBirth", value, true),
    renderInput: (params) => (
      <TextField
        {...params}
        fullWidth
        error={Boolean(formik.touched.dateOfBirth && formik.errors.dateOfBirth)}
        helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
        margin="normal"
        name="dateOfBirth"
      />
    ),
  };


  return (
    <Root>
      <form onSubmit={formik.handleSubmit}>
        <GoBackButton sx={{ marginX: 3, marginY: 2 }} />

        <PageHeader>
          {mode === FormMode.ADD ? "Add Client" : "Edit Client"}
        </PageHeader>

        <Wrapper>
          <MidContainer>
            <Column>
              <Heading>Personal Details</Heading>
              <MaterialField formik={formik} name="fName" label="*First Name" />
              <MaterialField formik={formik} name="lName" label="*Last Name" />
              <MaterialField
                formik={formik}
                name="countryCode"
                label="*Country"
                select
              >
                {countryOptions.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    disabled={!Boolean(option.value)}
                  >
                    {option.key}
                  </MenuItem>
                ))}
              </MaterialField>
             

              {mode === FormMode.EDIT && (
                <Column>
                <Box>
                {formik.values.countryCode === "ZA" && (
                    <MaterialField
                      formik={formik}
                      name="southAfricanId"
                      label="South African ID"
                    />
                  )}
                  </Box>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <DesktopDatePicker {...datePickerProps} />
                  </Box>
                  <Box>
                    <MaterialField
                      formik={formik}
                      name="title"
                      label="Title"
                      select
                    >
                      {patientTitles.map((option) => (
                        <MenuItem
                          key={option.key}
                          value={option.value}
                          disabled={!Boolean(option.value)}
                        >
                          {option.key}
                        </MenuItem>
                      ))}
                    </MaterialField>

                    <MaterialField
                      formik={formik}
                      name="gender"
                      label="Gender"
                      select
                    >
                      {genderOptions.map((genderOption) => (
                        <MenuItem
                          key={genderOption.key}
                          value={genderOption.value}
                          disabled={!Boolean(genderOption.value)}
                        >
                          {genderOption.key}
                        </MenuItem>
                      ))}
                    </MaterialField>

                    <ColumnHide>
                    {data?.content.length > 0 && (
                      <MaterialField
                        formik={formik}
                        name="company"
                        label="Company"
                        select
                      >
                        {companyOptions.map((option) => (
                          <MenuItem
                            key={option.key}
                            value={option.value}
                            disabled={!Boolean(option.value)}
                          >
                            {option.key}
                          </MenuItem>
                        ))}
                      </MaterialField>
                    )}
                    </ColumnHide>
                    


                  </Box>
                </Column>
              )}
            </Column>

            {mode === FormMode.EDIT && (
            <Column>
              <Heading>Contact Details</Heading>

              <FormControl className="phone-input" fullWidth margin="normal">
                <InputLabel shrink>Cell Number</InputLabel>
                <PhoneInput
                  country={formik.values.countryCode.toLowerCase()}
                  value={formik.values.cNumber}
                  onChange={(phone) => formik.setFieldValue("cNumber", phone)}
                  inputProps={{
                    name: "cNumber",
                    required: true,
                  }}
                />
                <FormHelperText
                  error={Boolean(
                    formik.touched.cNumber && formik.errors.cNumber,
                  )}
                >
                  {formik.touched.cNumber && formik.errors.cNumber}
                </FormHelperText>
              </FormControl>

              <FormControl className="phone-input" fullWidth margin="normal">
                <InputLabel shrink>Home Number</InputLabel>
                <PhoneInput
                  country={formik.values.countryCode.toLowerCase()}
                  value={formik.values.hNumber}
                  onChange={(phone) => formik.setFieldValue("hNumber", phone)}
                  inputProps={{
                    name: "hNumber",
                  }}
                />
                <FormHelperText
                  error={Boolean(
                    formik.touched.hNumber && formik.errors.hNumber,
                  )}
                >
                  {formik.touched.hNumber && formik.errors.hNumber}
                </FormHelperText>
              </FormControl>

              <FormControl className="phone-input" fullWidth margin="normal">
                <InputLabel shrink>Work Number</InputLabel>
                <PhoneInput
                  country={formik.values.countryCode.toLowerCase()}
                  value={formik.values.wNumber}
                  onChange={(phone) => formik.setFieldValue("wNumber", phone)}
                  inputProps={{
                    name: "wNumber",
                  }}
                />
                <FormHelperText
                  error={Boolean(
                    formik.touched.wNumber && formik.errors.wNumber,
                  )}
                >
                  {formik.touched.wNumber && formik.errors.wNumber}
                </FormHelperText>
              </FormControl>

              <MaterialField
                formik={formik}
                name="email"
                label="Email Address"
              />

              <MaterialField
                formik={formik}
                name="relativeName"
                label="Relative First Name"
              />

              <FormControl className="phone-input" fullWidth margin="normal">
                <InputLabel shrink>Relative Contact Number</InputLabel>
                <PhoneInput
                  country={formik.values.countryCode.toLowerCase()}
                  value={formik.values.rContactNumber}
                  onChange={(phone) =>
                    formik.setFieldValue("rContactNumber", phone)
                  }
                  inputProps={{
                    name: "rContactNumber",
                  }}
                />
                <FormHelperText
                  error={Boolean(
                    formik.touched.rContactNumber &&
                      formik.errors.rContactNumber,
                  )}
                >
                  {formik.touched.rContactNumber &&
                    formik.errors.rContactNumber}
                </FormHelperText>
              </FormControl>
            </Column>
            )}

            {mode === FormMode.EDIT && (
              <>
                <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
                  <Box>
                    <Heading>Physical Address</Heading>
                    <MaterialField
                      formik={formik}
                      name="addressLine1"
                      label="Address Line 1"
                    />
                    <MaterialField
                      formik={formik}
                      name="addressLine2"
                      label="Address Line 2"
                    />
                    <MaterialField formik={formik} name="city" label="City" />
                    {formik.values.countryCode === "ZA" ? (
                      <MaterialField
                        formik={formik}
                        name="province"
                        label="Province"
                        select
                      >
                        {provinceOptions.map((provinceOption) => (
                          <MenuItem
                            key={provinceOption.key}
                            value={provinceOption.value}
                            disabled={!Boolean(provinceOption.value)}
                          >
                            {provinceOption.key}
                          </MenuItem>
                        ))}
                      </MaterialField>
                    ) : (
                      <MaterialField formik={formik} name="province" label="State" />
                    )}
                    <MaterialField
                      formik={formik}
                      name="postalCode"
                      label={formik.values.countryCode === "ZA" ? "Postal Code" : "Zip Code"}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
                  <Box>
                    <Row20>
                      <Heading>Postal Address</Heading>
                      <FormControlLabel
                        control={
                          <Switch
                            size="small"
                            checked={usePhysicalAddress}
                            onChange={(event) => {
                              setUsePhysicalAddress(event.target.checked);
                            }}
                          />
                        }
                        label="Same as Physical"
                      />
                    </Row20>

                    <MaterialField
                      formik={formik}
                      name="pAddressLine1"
                      label="Address Line 1"
                      disabled={usePhysicalAddress}
                    />
                    <MaterialField
                      formik={formik}
                      name="pAddressLine2"
                      label="Address Line 2"
                      disabled={usePhysicalAddress}
                    />
                    <MaterialField
                      formik={formik}
                      name="pCity"
                      label="City"
                      disabled={usePhysicalAddress}
                    />
                    {formik.values.countryCode === "ZA" ? (
                      <MaterialField
                        formik={formik}
                        name="pProvince"
                        label="Province"
                        select
                        disabled={usePhysicalAddress}
                      >
                        {provinceOptions.map((provinceOption) => (
                          <MenuItem
                            key={`postal_${provinceOption.key}`}
                            value={provinceOption.value}
                            disabled={!Boolean(provinceOption.value)}
                          >
                            {provinceOption.key}
                          </MenuItem>
                        ))}
                      </MaterialField>
                    ) : (
                      <MaterialField
                        formik={formik}
                        name="pProvince"
                        label="State"
                        disabled={usePhysicalAddress}
                      />
                    )}
                    <MaterialField
                      formik={formik}
                      name="pPostalCode"
                      label={formik.values.countryCode === "ZA" ? "Postal Code" : "Zip Code"}
                      disabled={usePhysicalAddress}
                    />
                  </Box>
                </Grid>
                <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
                  <Box>
                    <Heading>Medical Detail</Heading>
                    <MaterialField
                      formik={formik}
                      name="medicalAidProvider"
                      label="Medical Aid Provider"
                    />
                    <MaterialField
                      formik={formik}
                      name="medicalAidNumber"
                      label="Medical Aid Number"
                    />
                    <MaterialField
                      formik={formik}
                      name="mainMember"
                      label="Main Member"
                    />
                    <MaterialField
                      formik={formik}
                      name="currentConditions"
                      label="Current Conditions"
                    />
                    <MaterialField
                      formik={formik}
                      name="currentMedication"
                      label="Current Medication"
                    />
                    <MaterialField
                      formik={formik}
                      name="currentSupplements"
                      label="Current Supplements"
                    />
                  </Box>
                </Grid>
              </>
            )}
          </MidContainer>
        </Wrapper>
        <Wrapper>
        <MidContainer>
        
        
       
       
        <Box paddingBottom={3}>
          {showSuccess && (
            <Alert severity="success">Details successfully captured.</Alert>
          )}
          {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end", my: 3 }}>
          {mode === FormMode.ADD && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={submitting}
            >
              {submitting ? "Submitting" : "Submit"}
            </Button>
          )}
          {mode === FormMode.EDIT && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={submitting}
            >
              {submitting ? "Updating" : "Update"}
            </Button>
          )}
        </Box>
        </MidContainer>
        </Wrapper>

      </form>
    </Root>
  );
}
