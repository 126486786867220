import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import { DataGrid } from '@mui/x-data-grid';

function CompanyGrid({ data }) {

  const columns = [
    {
      field: 'code',
      headerName: 'Code',
      width: 100,
      editable: true,
    }, {
      field: 'details',
      headerName: 'Name',
      width: 280, renderCell: (params) =>
        <Link to={`/companies/${params.value.id}`}>{params.value.name}</Link>,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 280,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110,
    },
    // {
    //   field: 'practices',
    //   headerName: 'View Practices',
    //   width: 110,
    //   renderCell: (params) =>
    //     <Link to={`/companies/${params.value}/practices`}>View Practices</Link>,
    // }
  ];

  const rows = data?.content.map((company) => (
    {
      id: company.id,
      code: company.code,
      details: { name: company.name, id: company.id, },
      city: company.address.city,
      status: company.status,
      practices: company.id,


    }
  ));

  return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </>
  );
}

export default CompanyGrid;
