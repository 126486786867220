import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { Link, useParams, useNavigate } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectBearerToken } from '../../globalSlice';
import { apiBaseUrl } from '../../utilities';
import ConfirmationModal from '../../Components/ConfirmationModal/ConfirmationModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px 10px;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 20px;
`;

const Right = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 465px;
  padding: 20px 20px 20px 0;
`;

const Arrow = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  width: 8px;
  height: 8px;
  border: 2px solid #D0D5DD;
  border-left: none;
  border-bottom: none;
  transform: rotate(45deg);
  margin: 0 8px;
`;

const Crumb = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 8px;
  color: ${props => props.active ? '#191947' : '#475467'};
  font-weight: ${props => props.active ? '500' : '400'};
  background-color: ${props => props.active ? '#E8E8FC' : 'transparent'};
`;

const DeleteButton = styled.button`
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 8px;
  color: #000000;
  font-weight: 600;
  background-color: #ffffff;
  border: 2px solid #9393CB;
  cursor: pointer;
  margin: 0 5px;
`;

const EditButton = styled.button`
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  background-color: #9AA4B2;
  border: 2px solid #9AA4B2;
  cursor: pointer;
  margin: 0 5px;
`;

const AddButton = styled.button`
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 8px;
  color: #ffffff;
  font-weight: 600;
  background-color: #252552;
  border: 2px solid #252552;
  cursor: pointer;
  margin: 0 5px;
`;

function UserHeader({ onEdit, existingCompany }) {
  const { companyId, practiceId } = useParams();
  const navigate = useNavigate();
  const token = useSelector(selectBearerToken);
  const [companyName, setCompanyName] = useState('');
  const [practiceName, setPracticeName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  // Retrieve companyId from local storage if not in URL
  const storedCompanyId = localStorage.getItem('companyId');

  useEffect(() => {
    if (companyId) {
      // Store companyId in local storage when navigating
      localStorage.setItem('companyId', companyId);
    }

    const fetchCompanyAndPractice = async () => {
      const currentCompanyId = companyId || storedCompanyId;
      if (currentCompanyId) {
        try {
          const response = await axios.get(`${apiBaseUrl}/admin/v1/companies/${currentCompanyId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setCompanyData(response.data);
          setCompanyName(response.data.name);
        } catch (error) {
          console.error("Error fetching company data:", error);
        }
      }

      // Fetch practice data if practiceId is available
      if (practiceId) {
        try {
          const response = await axios.get(`${apiBaseUrl}/cmn/v1/practices/${practiceId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setPracticeName(response.data.name);
        } catch (error) {
          console.error("Error fetching practice data:", error);
        }
      }
    };

    fetchCompanyAndPractice();
  }, [companyId, practiceId, token, storedCompanyId]);

  const handleEditCompany = () => {
    if (companyData) {
      navigate(`/companies/${companyId}/edit`, { state: { existingCompany: companyData } });
    }
  };

  const handleRemoveClick = (type) => {
    setDeleteType(type);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteType(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      const currentCompanyId = companyId || storedCompanyId;

      if (deleteType === 'company' && currentCompanyId) {
        // DELETE /admin/v1/companies/{companyId}
        await axios.delete(`${apiBaseUrl}/admin/v1/companies/${currentCompanyId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        navigate('/companies', { replace: true });
      } else if (deleteType === 'practice' && practiceId && currentCompanyId) {
        // DELETE /admin/v1/practices/{practiceId}
        await axios.delete(`${apiBaseUrl}/admin/v1/practices/${practiceId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        navigate(`/companies/${currentCompanyId}`, { replace: true });
      }

      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting:', error);
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/companies') {
      localStorage.removeItem('companyId');
      setCompanyName('');
    }
  }, [window.location.pathname]);

  return (
    <Wrapper>
      <Left>
        <BusinessIcon style={{ fill: "black", fontSize: 28 }} />
        <Arrow />
        <Crumb active={!companyId && !practiceId}>
          <Link to="/companies">Companies</Link>
        </Crumb>
        {(companyId || storedCompanyId) && (
          <>
            <Arrow />
            <Crumb active={!practiceId}>
              <Link to={`/companies/${companyId || storedCompanyId}`}>
                {companyName || 'Company'}
              </Link>
            </Crumb>
          </>
        )}

        {practiceId && (
          <>
            <Arrow />
            <Crumb active>
              <Link to={`/practices/${practiceId}`}>
                {practiceName || 'Practice'}
              </Link>
            </Crumb>
          </>
        )}
      </Left>

      <Right>
        {!companyId && !practiceId && (
          <AddButton onClick={() => navigate('/companies/add')}>
            Add Company
          </AddButton>
        )}

        {companyId && !practiceId && (
          <>
            <DeleteButton onClick={() => handleRemoveClick('company')}>Remove Company</DeleteButton>
            <EditButton onClick={handleEditCompany}>Edit Company</EditButton>
          </>
        )}

        {companyId && !practiceId && (
          <>
            <AddButton onClick={() => navigate(`/companies/${companyId}/add-practice`)}>
              Add Practice
            </AddButton>
          </>
        )}

        {practiceId && (
          <>
            <DeleteButton onClick={() => handleRemoveClick('practice')}>
              Delete Practice
            </DeleteButton>
            <EditButton onClick={() => navigate(`/practices/${practiceId}/edit`)}>
              Edit Practice
            </EditButton>
            <AddButton onClick={() => navigate(`/practices/${practiceId}/add-user`)}>
              Add User
            </AddButton>
          </>
        )}
      </Right>

      <ConfirmationModal
        isOpen={showDeleteModal}
        title={`Delete ${deleteType === 'company' ? 'Company' : 'Practice'}`}
        body={`Are you sure you want to delete this ${deleteType}? Warning: all child entities will also be deleted and this action cannot be undone.`}
        onConfirm={handleDeleteConfirm}
        onCancel={handleCloseDeleteModal}
      />
    </Wrapper>
  );
}

export default UserHeader;
