import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { selectBearerToken } from "../../globalSlice";
import CompanyDetail from "../../Components/CompanyDetails/CompanyDetail";
import { getCompanyDetailsById } from "../../fetchers/companies";
import { getPatientsByCompanyId } from "../../fetchers/patients";
import CompanyPracticeDetail from "../../Components/CompanyDetails/CompanyPracticeDetail";
import { getPracticesByCompanyIdPaginated } from "../../fetchers/practices";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

const SubWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 0 30px;
`;

const SubHeading = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
  color: #000000;
  margin: 10px 0;
`;

function CompanyDetailsPage({ isGoBackable = true }) {
  const { companyId } = useParams();
  const token = useSelector(selectBearerToken);
  const navigate = useNavigate();

  const results = useQueries({
    queries: [
      {
        queryKey: ["companyDetailsById", companyId],
        queryFn: () => getCompanyDetailsById(token, companyId),
      },
      {
        queryKey: ["practicesByCompanyId", companyId],
        queryFn: () => getPracticesByCompanyIdPaginated(token, companyId),
      },
      {
        queryKey: ["patientsByCompany", companyId],
        queryFn: () => getPatientsByCompanyId(token, companyId),
      },
    ],
  });

  return (

      <Wrapper>
      <GoBackButton />

        {results.isLoading && <CircularProgress />}
        
        <SubHeading>Company Details</SubHeading>

        {results[0].data && (
          <>
            <SubWrapper>
            <CompanyDetail companyDetails={results[0].data} />
            </SubWrapper>
            
            

            {results[1]?.data && (
             
                <CompanyPracticeDetail practiceDetails={results[1].data} />
              
            )}

       
          </>
        )}
      </Wrapper>
   
  );
}

export default CompanyDetailsPage;
