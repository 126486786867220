import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectBearerToken } from '../../globalSlice';
import { apiBaseUrl } from '../../utilities';
import styled from "@emotion/styled";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PageHeader = styled.h4`
  padding: 15px 20px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #333366;
  background-color: #ffffff;
  border-bottom: 2px solid #333366;
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

export default function GetUserProfile() {
  const [profileData, setProfileData] = useState(null);
  const [practiceData, setPracticeData] = useState(null);
  const [error, setError] = useState(null);
  const token = useSelector(selectBearerToken);

  useEffect(() => {
    // Fetch user profile data
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/cmn/v1/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        });
        setProfileData(response.data);
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError('Failed to fetch user profile');
      }
    };

    // Fetch practice data
    const fetchPracticeData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/cmn/v1/my-practice`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        });
        setPracticeData(response.data);
      } catch (err) {
        console.error('Error fetching practice data:', err);
        setError('Failed to fetch practice data');
      }
    };

    fetchUserProfile();
    fetchPracticeData();
  }, [token]);

  return (
    <Root>
    <GoBackButton />
      <PageHeader>Practice User Profile</PageHeader>
      <Wrapper>
        {error && <p style={{ color: 'red' }}>{error}</p>}

        {profileData && practiceData ? (
          <div>
            <p><strong className="margin-r-5">Name:</strong> {profileData.name}</p>
            <p><strong className="margin-r-5">Username:</strong> {profileData.username}</p>
            <p><strong className="margin-r-5">Email:</strong> {profileData.email}</p>
            <p><strong className="margin-r-5">Account Enabled:</strong> {profileData.accountEnabled ? 'Yes' : 'No'}</p>
            <p><strong className="margin-r-5">Group:</strong> {profileData.group}</p>
            <p className="margin-t-35"><strong className="margin-r-5">Practice Name:</strong> {practiceData.name}</p>
            <p><strong className="margin-r-5">Company Name:</strong> {practiceData.company.name}</p>
          </div>
        ) : (
          <p>Loading profile data...</p>
        )}
      </Wrapper>
    </Root>
  );
}
