import axios from "axios";
import { apiBaseUrl } from "../utilities";

const baseUrl = `${apiBaseUrl}/cmn/v1/patient-details`;

export const getPatientDetailsPaginated = async (
  token,
  page = 0,
  size = 12,
  companyId = null // Optional companyId for filtering
) => {
  const params = { page, size };
  
  // Add companyId to params if it's provided
  if (companyId) {
    params.companyId = companyId;
  }

  const { data } = await axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });
  return data;
};

export const getPatientDetailsById = async (token, patientDetailsId) => {
  try {
    const response = await axios.get(`${apiBaseUrl}/cmn/v1/patient-details/${patientDetailsId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
