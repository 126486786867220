import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { selectBearerToken } from "../../globalSlice";
import { Button } from "@material-ui/core";
import { useQueries } from "@tanstack/react-query";
import { getPatientDetailsById } from "../../fetchers/patientDetails";
import { visitByPatientDetailsId } from "../../fetchers/visits";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { longText, disclaimer, tableIntro, getTableHeader } from "../data.js";
import { Download } from '@mui/icons-material';
import styled from "@emotion/styled";
import header2 from "../../images/expanded-header2.png";
import header1 from "../../images/concise-header.png";
import introduction from "../../images/introduction.png";
import blood1 from "../../images/a1.png";
import blood2 from "../../images/a2.png";
import frontBlood1 from "../../images/blood-1.png";
import frontBlood2 from "../../images/blood-2.png";
import { apiBaseUrl } from "../../utilities";
import { getMyPracticeDetails } from "../../api/practiceUserApi.js"

const Block = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: 10px;
`;

export default function GenerateConciseReport({
  date,
  patientDetails,
  patientDetailsId,
  visitId,
  displayId,
}) {
  const [anomalies, setAnomalies] = useState([]);
  const token = useSelector(selectBearerToken);
  const [visitDetails, setVisitDetails] = useState(null);
  const [practiceDetails, setPracticeDetails] = useState(null);

  useEffect(() => {
    // Fetching visit details
    axios
      .get(`${apiBaseUrl}/cmn/v1/visits/${visitId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setVisitDetails(response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [visitId, token]);

  useEffect(() => {
    // Fetch practice details
    const fetchPracticeDetails = async () => {
      try {
        const practiceData = await getMyPracticeDetails(token);
        setPracticeDetails(practiceData);
      } catch (error) {
        console.error("Error fetching practice details:", error);
      }
    };
    fetchPracticeDetails();
  }, [token]);

  const results = useQueries({
    queries: [
      {
        queryKey: ["patientDetailsById", patientDetailsId],
        queryFn: () => getPatientDetailsById(token, patientDetailsId),
      },
      {
        queryKey: ["personalVisitsById", patientDetailsId],
        queryFn: () => visitByPatientDetailsId(token, patientDetailsId),
      },
    ],
  });

  useEffect(() => {
    axios
      .get(
        `${apiBaseUrl}/cmn/v1/visits/${visitId}/snapshots`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setAnomalies(response.data);
      })
      .catch((error) => console.error("Error fetching anomalies:", error));
  }, [visitId, token]);

  const tablePracticeDetails = practiceDetails
  ? [
      ["Practice Name", practiceDetails.name],
      ["Address", `${practiceDetails.address.addressLine1}, ${practiceDetails.address.addressLine2}`],
      ["City", practiceDetails.address.city],
      ["Province", practiceDetails.address.province],
      ["Postal Code", practiceDetails.address.postalCode],
      ["Company Name", practiceDetails.company.name]
    ]
  : [];

  // Function to add content to the first page
  const addFirstPageContent = (doc, displayId, date, practiceResult, practiceDetails, ) => {

    const tableHeader = getTableHeader(visitId, displayId, date, results);

    const image1 = new Image();
    image1.src = header1;

    const image2 = new Image();
    image2.src = header2;

    const image3 = new Image();
    image3.src = introduction;

    const image4 = new Image();
    image4.src = blood1;

    const image5 = new Image();
    image5.src = blood2;

    const image6 = new Image();
    image6.src = frontBlood1;

    const image7 = new Image();
    image7.src = frontBlood2;

    doc.autoTable({
      startY: 110,
      body: tableIntro,
      theme: "plain",
      styles: {
        fillColor: "white",
        fontSize: 11,
      },
      margin: { left: 8 },
      rowStyles: {
        fontStyle: "normal",
        textColor: [0, 0, 0],
      },
    });

    doc.setFontSize(11);

    // Set font to bold for specific text
    doc.setFont("helvetica", "bold");

    doc.text("Normal Live Blood", 38, 183);
    doc.text("Normal Dry Blood", 138, 183);

    const maxWidth = 200;
    const maxHeightA = maxWidth / 4;
    const maxHeightB = maxWidth / 13;

    // Header image
    doc.addImage(image1, "PNG", 5, 0, maxWidth, maxHeightA);
    // Analysis report introduction text as image
    doc.addImage(image3, "PNG", 5, 92, maxWidth, maxHeightB);

    const image4Height = 90;
    const image5Width = 90;
    const image7Width = 90;
    const image7Height = 90;

    // 2 primary blood samples
    doc.addImage(image6, "PNG", 9, 185, image7Width, image7Height);
    doc.addImage(image7, "PNG", 110, 185, image7Width, image7Height);

    const lineX = 104;
    const lineYStart = 175; // Y-coordinate where the line starts
    const lineYEnd = lineYStart + image4Height + 15; // Y-coordinate where the line ends
    doc.setLineWidth(0.25);
    doc.line(lineX, lineYStart, lineX, lineYEnd);

    // Draw a horizontal above primary images
    const horizontalLineY = 175; // Adjust the vertical position of the line
    const horizontalLineXStart = 10; // X-coordinate where the line starts
    const horizontalLineXEnd = horizontalLineXStart + image5Width + 100; // X-coordinate where the line ends
    doc.line(
      horizontalLineXStart,
      horizontalLineY,
      horizontalLineXEnd,
      horizontalLineY
    );

    // Draw a horizontal line below primary images
    const horizontalLineYB = 280; // Adjust the vertical position of the line
    const horizontalLineXStartB = 10; // X-coordinate where the line starts
    const horizontalLineXEndB = horizontalLineXStartB + image5Width + 100; // X-coordinate where the line ends
    doc.line(
      horizontalLineXStartB,
      horizontalLineYB,
      horizontalLineXEndB,
      horizontalLineYB
    );

    doc.setFontSize(12);

    doc.autoTable({
      startY: 25,
      body: tableHeader,
      theme: "plain",
      styles: {
        fillColor: false,
      },
      margin: { left: 7 },
      rowStyles: {
        lineColor: 1,
        lineWidth: 1,
        fontSize: 12,
        fontStyle: "normal",
        fillColor: false,
        textColor: [0, 0, 0],
      },
    });

    doc.autoTable({
      startY: 51,
      body: tablePracticeDetails,
      theme: "plain",
      styles: {
        fillColor: false,
        fontStyle: "bold",
        cellPadding: 1,
        fontSize: 10,
        lineWidth: 0,
      },
      margin: { left: 115 },
      tableLineWidth: 0.5,
      tableLineColor: [0, 0, 0],
    });
    
  };

  const generatePDF = async () => {

    const clientName = results[0].data.name;
    const visitNumber = displayId; // Assuming displayId represents the visit number
    const practiceName = practiceDetails.name;
    const dateTime = new Date().toISOString().replace("T", "-").replace(/:/g, "-"); // Format as "YYYY-MM-DD-HH-MM-SS"

    const doc = new jsPDF("p", "mm", "a4");
    const chart1 = document.getElementById("graph-1");
    const addGraphToNewPage = async (element, headingText) => {
      doc.addPage();
      addHeaderImage();
      addFooter();
      doc.setFontSize(14);
      doc.text(headingText, 10, 40);
      
      // Capture and add the graph
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210; // A4 width in mm
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      doc.addImage(imgData, 'PNG', 0, 50, imgWidth, imgHeight);
    };

    doc.setFontSize(10);
    addFirstPageContent(doc, displayId, date, practiceDetails);

    // Function to add header image to each page
    const addHeaderImage = () => {
      doc.addImage(header2, "PNG", 5, 2, 200, 22);

      const smallDetails = [
        ["TEST NO:", `${displayId}`],
        ["TEST DATE:", `${date}`],
        ["CLIENT NAME:", `${results[0].data.name} ${results[0].data.surname}`],
      ];

      doc.autoTable({
        startY: 15,
        body: smallDetails,
        theme: "plain",
        styles: {
          fillColor: false,
          cellPadding: { top: 0, right: 2, bottom: 0, left: 1 },
          fontSize: 9,
          fontStyle: "normal",
          textColor: [0, 0, 0],
          lineColor: [255, 255, 255],
          lineWidth: 0.1,
        },
        margin: { left: 101 },
        columnStyles: {
          0: { halign: "right", cellWidth: 50 },
          1: { halign: "right", cellWidth: 50 },
        },
      });
    };

    // Function to add footer to each page
    const addFooter = () => {
      const pageHeight = doc.internal.pageSize.getHeight();
      const footerY = pageHeight - 10;

      // Draw lines
      doc.setLineWidth(0.1);
      doc.line(10, footerY - 6, 200, footerY - 6);
      doc.line(10, footerY - 5, 200, footerY - 5);

      // Footer text
      doc.setFontSize(9);
      doc.text(
        "BloodWorksTM Analysis Report Copyright © Neogenesis Medical Systems CC",
        10,
        footerY
      );

      // Page number - right aligned
      const pageNumber = doc.internal.getNumberOfPages(); // Get total number of pages
      doc.text(`Page ${pageNumber}`, 190, footerY); // Adjust x coordinate for alignment
    };

    const anomaliesByType = {};
    anomalies.forEach((anomaly) => {
      if (!anomaliesByType[anomaly.anomaly.type]) {
        anomaliesByType[anomaly.anomaly.type] = [];
      }
      anomaliesByType[anomaly.anomaly.type].push(anomaly);
    });

    const marginLeft = 10;
    const marginRight = 5;
    const pageWidth = doc.internal.pageSize.getWidth();
    const contentWidth = pageWidth - marginLeft - marginRight;
    const columnWidth = contentWidth / 2;
    const imageWidth = columnWidth - 5; // padding
    const imageHeight = 70; // image aspect ratio
    const textHeight = 12; // text under each image
    const blockHeight = imageHeight + textHeight; // height per block

    // Calculate position for each image and text block
    function calculatePosition(index) {
      const column = index % 2;
      const row = Math.floor(index / 2) % 3;
      const x = marginLeft + columnWidth * column;
      const yImage = 36 + blockHeight * row; // start a bit below the top margin
      const yText = yImage + imageHeight + 4; // space between image and text

      return { x, yImage, yText };
    }

    // Add one new page
    doc.addPage();
    addHeaderImage();
    addFooter();
    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.text("Your Blood Analysis images:", 10, 33);
    doc.setFontSize(9);

    const addAnomalyToPDF = async (anomaly, index) => {
      const { x, yImage, yText } = calculatePosition(index % 6);

      // Add Image
      try {
        const imageResponse = await axios.get(
          `${apiBaseUrl}/opn/v1/files?fileName=${anomaly.imageFileName}`,
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const reader = new FileReader();
        reader.readAsDataURL(imageResponse.data);
        await new Promise((resolve) => {
          reader.onloadend = () => {
            doc.addImage(
              reader.result,
              "JPEG",
              x,
              yImage,
              imageWidth,
              imageHeight
            );
            resolve();
          };
        });
      } catch (error) {
        console.error("Error fetching or adding image:", error);
      }

      // Add Text
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.text(`${anomaly.anomalyName}, Grade: ${anomaly.grade} / 5`, x, yText);

      if (anomaly.layers && anomaly.layers.length > 0) {
        doc.setFontSize(9);
        doc.setFont("helvetica", "normal");
        doc.text(
          `Observed in Layer(s): ${anomaly.layers.join(", ")}`,
          x,
          yText + 6
        );
      }
    };

    let anomaliesInPage = 0;
    for (const type in anomaliesByType) {
      for (const anomaly of anomaliesByType[type]) {
        if (anomaliesInPage % 6 === 0 && anomaliesInPage > 0) {
          doc.addPage();
          addHeaderImage();
          addFooter();
        }

        await addAnomalyToPDF(anomaly, anomaliesInPage);
        anomaliesInPage++;
      }
    }

    // Add the chart

    // Add Graph 1
    await addGraphToNewPage(chart1, "Implications:");
    
    // Add Summary of Results
    doc.addPage();
    addHeaderImage();
    addFooter();

    doc.setFontSize(14);
    doc.setFont("helvetica", "normal");
    doc.text("Summary of Results:", 10, 33);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    if (visitDetails && visitDetails.summary) {
      var linesB = doc.splitTextToSize(visitDetails.summary, 180);

      var yOffsetA = 40;
      linesB.forEach(function (line, index) {
        if (yOffsetA > 270) {
          doc.addPage();
          addHeaderImage();
          addFooter();
          yOffsetA = 40;
        }
        doc.setFontSize(10);
        doc.text(line, 10, yOffsetA);
        yOffsetA += 5;
      });
    }

    // Add Supplement Recommendations
    doc.addPage();
    addHeaderImage();
    addFooter();

    doc.setFontSize(14);
    doc.text("Supplement Recommendations:", 10, 33);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    if (visitDetails && visitDetails.recommendations) {
      var linesC = doc.splitTextToSize(visitDetails.recommendations, 180);

      var yOffsetB = 40;
      linesC.forEach(function (line, index) {
        if (yOffsetB > 270) {
          doc.addPage();
          addHeaderImage();
          addFooter();
          yOffsetB = 40;
        }
        doc.setFontSize(10);
        doc.text(line, 10, yOffsetB);
        yOffsetB += 5;
      });
    }

    // Add new page
    doc.addPage();
    addHeaderImage();
    addFooter();

    doc.setFontSize(14);
    doc.text("Additional Notes", 10, 33);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(9);

    const lines = doc.splitTextToSize(longText, 180); // Split text into lines

    let yOffset = 40;
    lines.forEach((line, index) => {
      if (yOffset > 270) {
        doc.addPage();
        addHeaderImage();
        addFooter();
        yOffset = 40;
      }
      doc.text(line, 10, yOffset);
      yOffset += 5;
    });

    doc.autoTable({
      startY: 190,
      body: disclaimer,
      theme: "plain",
      styles: {
        fillColor: "white",
      },
      margin: { left: 10 },
      tableLineWidth: 0.5,
      tableLineColor: [0, 0, 0],
    });

    const fileName = `${clientName}.${visitNumber}.${practiceName}.concise.${dateTime}.pdf`;

    doc.save(fileName);

  };

  return (
    <Block>
      <Button
        onClick={generatePDF}
        variant="contained"
        color="secondary"
        className="pad-lr-15"
        startIcon={<Download />}
      >
        Concise
      </Button>
    </Block>
  );
}
