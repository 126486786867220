import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Zoom from "@mui/material/Zoom";

function SelectionCard({
  to,
  selected,
  children,
  LinkProps,
  CardProps,
  LinkStyles,
  CardStyles,
  onClick
}) {
  const WrapperComp = to ? Link : Box;
  return (
    <WrapperComp to={to} onClick={onClick} {...LinkProps} sx={{ ...LinkStyles }}>
      <Zoom in sx={{ transitionDelay: "300ms" }}>
        <div>
          <Card
            className={selected ? 'selected' : undefined}
            sx={{
              textAlign: "center",
              cursor: "pointer",
              border: "1px solid transparent",
              transition: "all 0.15s ease-in-out",
              transform: "scale(1)",
              ":hover": {
                borderColor: "primary.main",
                transform: "scale(1.02)",
              },
              "&.selected": {
                borderColor: "primary.main",
                backgroundColor: 'grey.300',
              },
              ...CardStyles,
            }}
            {...CardProps}
          >
            {children}
          </Card>
        </div>
      </Zoom>
    </WrapperComp>
  );
}

export default SelectionCard;
