import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { format, parseISO } from 'date-fns';

function PatientDetail({ patientDetails }) {
  let genderColor;
  switch (patientDetails.gender) {
    case "MALE":
      genderColor = "genderMale";
      break;
    case "FEMALE":
      genderColor = "genderFemale";
      break;
    default:
    // Keep undefined
  }

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, 'yyyy-MM-dd');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box sx={{ m: 2 }}>
          <Typography
            color="secondary.light"
            variant="h5"
            sx={{
              transition: "color 0.15s ease-in-out",
              textTransform: "capitalize",
            }}
          >
            {patientDetails?.title ?? ''} {patientDetails?.name ?? ''} {patientDetails?.surname ?? ''}
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            ID Number: <b>{patientDetails?.idNumber ?? ''}</b>
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Date of Birth: <b>{patientDetails?.dateOfBirth ? formatDate(patientDetails.dateOfBirth) : ''}</b>
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Company: 
            <b>
              <Link to={`/companies/${patientDetails?.company?.id ?? ''}`}>
                {patientDetails?.company?.name ?? 'Unknown Company'}
              </Link>
            </b>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ m: 2 }}>
          <Typography
            color="secondary.light"
            variant="h6"
            sx={{
              transition: "color 0.15s ease-in-out",
              textTransform: "capitalize",
            }}
          >
            Contact Details
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Cell Phone Number: <b>{patientDetails?.contactDetails?.cellphoneNumber ?? ''}</b>
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Email Address: <b>{patientDetails?.contactDetails?.email ?? ''}</b>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ m: 2 }}>
          <Typography
            color="secondary.light"
            variant="h6"
            sx={{
              transition: "color 0.15s ease-in-out",
              textTransform: "capitalize",
            }}
          >
            Address
          </Typography>
          <Typography sx={{ color: "grey.800" }}>
            <b>{patientDetails?.physicalAddress?.addressLine1 ?? ''} {patientDetails?.physicalAddress?.addressLine2 ?? ''}</b>
          </Typography>
          <Typography fontWeight={600} sx={{ color: "grey.800" }}>
            {patientDetails?.physicalAddress?.city ?? "-"},{` `}
            {patientDetails?.physicalAddress?.province ?? "-"},{` `}
            {patientDetails?.physicalAddress?.postalCode ?? "-"}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ mx: 2 }}>
          <Typography
            color="secondary.light"
            variant="h6"
            sx={{
              transition: "color 0.15s ease-in-out",
              textTransform: "capitalize",
            }}
          >
            Medical Details
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Medical Provider: <b>{patientDetails?.medicalDetails?.provider ?? ''}</b>
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Main Member: <b>{patientDetails?.medicalDetails?.mainMember ?? ''}</b>
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Medical Aid Number: <b>{patientDetails?.medicalDetails?.medicalNumber ?? ''}</b>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}


export default PatientDetail;
