export const provinceOptions = [
    { key: "Limpopo", value: "Limpopo" },
    { key: "Mpumalanga", value: "Mpumalanga" },
    { key: "North West", value: "North West" },
    { key: "Gauteng", value: "Gauteng" },
    { key: "Kwazulu-Natal", value: "Kwazulu Natal" },
    { key: "Free State", value: "Free State" },
    { key: "Northern Cape", value: "Northern Cape" },
    { key: "Eastern Cape", value: "Eastern Cape" },
    { key: "Western Cape", value: "Western Cape" },
];