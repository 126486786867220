import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectBearerToken } from '../../globalSlice';
import { apiBaseUrl } from '../../utilities';

const CompanyLayout = () => {
  const [userGroup, setUserGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = useSelector(selectBearerToken);

  // Test if ADMIN
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/cmn/v1/users/profile`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserGroup(response.data.group);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [token]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (userGroup !== 'ADMIN') {
    return <p>Access Denied. You are not authorized to view this page.</p>; // Show access denied message for non-admins
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default CompanyLayout;
