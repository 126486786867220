import axios from 'axios';
import { apiBaseUrl } from "../../utilities";

export const fetchImageAsBase64 = async (fileName, token) => {
  console.log("fetchImageAsBase64 called with fileName:", fileName);
  console.log("Fetching image with fileName:", fileName, "and token:", token);

  // Updated URL with query parameter
  const imageUrl = `${apiBaseUrl}/opn/v1/files?fileName=${fileName}`;

  try {
    const response = await axios.get(imageUrl, {
      responseType: 'blob',
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

    console.log("Response received, status:", response.status);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("Converted base64 image:", reader.result);
        resolve(reader.result);
      };
      reader.onerror = () => {
        console.error("Error converting blob to base64");
        reject();
      };
      reader.readAsDataURL(response.data);
    });
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};
