import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TypeSelectionCard from '../../Components/TypeSelectionCard/TypeSelectionCard';

function VisitsSelectionPage() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 'calc(100vh - 77px)' }}>
      <Typography color="grey.800" variant="subtitle1" sx={{ fontSize: 22 }}>What would you like to do?</Typography>
      <TypeSelectionCard title="View Visits" link="/visits/view" />
      <TypeSelectionCard title="Record Visit" link="/visits/add" />
    </Box>
  );
}

export default VisitsSelectionPage;
