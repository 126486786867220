import axios from "axios";

import { apiBaseUrl } from "../utilities";

// Function to update a visit by its ID
export const updateVisit = async (token, visitId, visitData) => {
  try {
    const response = await axios.put(`${apiBaseUrl}/cmn/v1/visits/${visitId}`, visitData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

async function getVisitById(token, patientDetailsId, visitId) {
  try {
    const response = await axios.get(`${apiBaseUrl}/cmn/v1/patient-details/${patientDetailsId}/visits/${visitId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error("Visit not found");
    } else {
      throw error;
    }
  }
}

export const visitByPatientDetailsId = async (token, patientDetailsId) => {
  const { data } = await axios.get(
    `${apiBaseUrl}/cmn/v1/patient-details/${patientDetailsId}/visits`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const visitByPracticeId = async (token, practiceId) => {
  const { data } = await axios.get(
    `${apiBaseUrl}/cmn/v1/practices/${practiceId}/visits`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};