import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom"; // Import useNavigate for programmatic navigation

import PatientGrid from "../PatientGrid/PatientGrid";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";

import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { selectBearerToken, selectUserGroup } from "../../globalSlice";
import { getPatientDetailsPaginated } from "../../fetchers/patientDetails";
import { getMyPracticeDetails } from "../../api/practiceUserApi";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const PageHeader = styled.h4`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 15px 20px;
  margin: 0;
  font-size: 20px;
  z-index: 1000;
  font-weight: 600;
  color: #333366;
  background-color: #ffffff;
  border-bottom: 2px solid #333366;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 25px;
`;

function PatientManagementPage({ title = 'Client Management', baseLink, isGoBackable = true }) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [companyId, setCompanyId] = useState(null);
  const token = useSelector(selectBearerToken);
  const group = useSelector(selectUserGroup);
  const navigate = useNavigate(); // Initialize navigate for programmatic navigation

  // Fetch company details if Practice User
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (group === "PRACTICE_USER") {
        try {
          const data = await getMyPracticeDetails(token);
          setCompanyId(data.companyId);
        } catch (error) {
          console.error("Error fetching practice details:", error);
        }
      }
    };
    fetchCompanyDetails();
  }, [token, group]);

  // Fetch and filter patient details based on user group
  const { isLoading, isError, data, isPreviousData } = useQuery({
    queryKey: ["personal_details", page, companyId],
    queryFn: async () => {
      const patientsData = await getPatientDetailsPaginated(token, page, 2000);
  
      // Filter only if user is PRACTICE_USER and companyId is defined
      if (group === "PRACTICE_USER" && companyId) {
        return patientsData.content.filter(patient => 
          patient.company && patient.company.id === companyId
        );
      }
      
      // If user is ADMIN, return all patients regardless of company association
      return patientsData.content;
    },
    enabled: group === "ADMIN" || (!!companyId && group === "PRACTICE_USER"),
    keepPreviousData: true,
    staleTime: 5000,
    retry: false,
  });
  

  // Prefetch the next page for smoother pagination
  useEffect(() => {
    if (!isPreviousData && data?.totalPages > page + 1) {
      queryClient.prefetchQuery({
        queryKey: ["personal_details", page + 1],
        queryFn: () => getPatientDetailsPaginated(token, page + 1, 2000),
      });
    }
  }, [data, isPreviousData, page, queryClient]);

  return (
    <Wrapper>
      {isGoBackable && <GoBackButton />}

      <PageHeader>{title}</PageHeader>

      {isError && <Alert severity="error">An error occurred</Alert>}

      {isLoading ? (
        <CircularProgress />
      ) : (
        <ContentWrapper>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            sx={{ width: "100%", backgroundColor: 'common.white' }}
          >
            {data?.length > 0 ? (
              <PatientGrid data={data} />
            ) : (
              <Alert severity="error">No data to display</Alert>
            )}
          </Grid>
        </ContentWrapper>
      )}
      <Row>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate("/patientManagement/add")}
        >
          Add Client
        </Button>
      </Row>
    </Wrapper>
  );
}

export default PatientManagementPage;
