import React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

function TypeSelectionCard({ title, link }) {
  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          margin: 2,
          padding: 4,
          minWidth: "350px",
          textAlign: "center",
          backgroundColor: "background.paper",
          ":hover": {
            backgroundColor: "primary.main",
            transition: "background-color 0.15s ease-in-out",
          },
          ":hover h3": {
            color: "common.white",
            transition: "color 0.15s ease-in-out",
          },
        }}
      >
        <Typography variant="h3">
          {title}
        </Typography>
      </Box>
    </Link>
  );
}

export default TypeSelectionCard;
