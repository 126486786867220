import React from 'react';
import { Box, Link, Typography } from '@mui/material';

import GoBackButton from '../Components/GoBackBtn/GoBackBtn';

function WorkInProgress() {
  return (
    <Box sx={{ height: '100vh', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Typography variant="h3">Welcome to the beta version.</Typography>
      <Typography variant="h5">This page is a work in progress.</Typography>
      <Typography>The page will be available in the full version. Please go back to the homepage with the link below.</Typography>
      <Link to="/">Home page</Link>
      <GoBackButton sx={{ marginTop: 2 }} />
    </Box>
  );
}

export default WorkInProgress;