import React from 'react';
import styled from "@emotion/styled";
import { Outlet, Navigate } from 'react-router-dom';
import { Box, Card, CardContent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectGlobalUser } from '../../globalSlice';


const PageWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #000000;
`;

const BackgroundBlock = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const LoginForm = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0,0,0,0.5);
`;

function AuthWrapper() {
  const { user } = useSelector(selectGlobalUser);
  if (user) {
    return <Navigate to="/" />
  }
  return (
    <PageWrapper>
      <BackgroundBlock className="login">
        <LoginForm>
          <Outlet />
        </LoginForm>
      </BackgroundBlock>
    </PageWrapper>
  )
}

export default AuthWrapper;