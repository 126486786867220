import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import styled from "@emotion/styled";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { selectBearerToken, selectUserGroup } from "../../globalSlice";
import { getPatientDetailsPaginated } from "../../fetchers/patientDetails";
import PatientCard from "./PatientCard";

const GridBase = styled.div`
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const CardWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: calc(25% - 20px);
  justify-content: center;
  align-items: center;
  min-width: 342px !important;
  margin: 10px;
  background-color: #ffffff;
`;

function PatientSelection({
  title = "Client Selection",
  subtitle = "Please select a client:",
  baseLink,
  isGoBackable = true,
}) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const token = useSelector(selectBearerToken);
  const group = useSelector(selectUserGroup);
  console.log("group", group);
  
  const { isLoading, isError, data, isPreviousData } = useQuery({
    queryKey: ["personal_details", page],
    queryFn: async () => getPatientDetailsPaginated(token, page, 12),
    keepPreviousData: true,
    staleTime: 5000,
    retry: false,
  });

  useEffect(() => {
    if (!isPreviousData && data?.totalPages < page - 1) {
      queryClient.prefetchQuery({
        queryKey: ["personal_details", page + 1],
        queryFn: () => getPatientDetailsPaginated(token, page + 1, 2),
      });
    }
  }, [data, isPreviousData, page, queryClient]);

  return (
    <>
      {isGoBackable && <GoBackButton sx={{ marginX: 3, marginY: 2 }} />}
      <Typography
        variant="h3"
        color="grey.900"
        textAlign="center"
        sx={{ marginTop: 2 }}
      >
        {title}
      </Typography>
      <Typography
        color="grey.800"
        variant="subtitle1"
        textAlign="center"
        gutterBottom
      >
        {subtitle}
      </Typography>
      <Stack alignItems="center" direction="column" marginX={2}>
        {isLoading && <CircularProgress />}
        {isError && <Alert severity="error">An error occurred</Alert>}
        <GridBase>
          {data?.content.map((patient) => (
            <CardWrapper key={`patient_card_${patient.id}`}>
              <PatientCard patient={patient} baseLink={baseLink} />
            </CardWrapper>
          ))}
          {data?.content.length === 0 && (
            <Alert severity="error">No data to display</Alert>
          )}
        </GridBase>
        <Box>
          <Pagination
            disabled={isLoading || isError}
            count={data?.totalPages}
            page={page + 1}
            onChange={(e, nextPage) => setPage(nextPage - 1)}
            shape="rounded"
          />
        </Box>
      </Stack>
    </>
  );
}

export default PatientSelection;
