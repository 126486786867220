import axios from "axios";
import { apiBaseUrl } from "../utilities";
const baseUrl = `${apiBaseUrl}/cmn/v1/patient-details`;

export const getPatientDetailsById = async (token, patientDetailsId) => {
  const { data } = await axios.get(`${baseUrl}/${patientDetailsId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}

// Create patient
export const createPatient = (token, patient) => {
  return axios.post(`${baseUrl}`, JSON.stringify(patient), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export const updatePatient = (token, patientId, patient) => {
  return axios.patch(`${baseUrl}/${patientId}`, JSON.stringify(patient), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export const deletePatient = async (token, patientDetailsId) => {
  try {
    const response = await axios.delete(`${baseUrl}/${patientDetailsId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Failed to delete patient");
  }
};

export const getPatientsByCompanyId = async (
  token, 
  companyId, 
  page = 0, 
  size = 12
) => {
  const { data } = await axios.get(`${apiBaseUrl}/cmn/v1/company/${companyId}/patients`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { page, size }
  });
  return data;
}