import { css } from '@emotion/react'

export const globalStyles = css`
  * {
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  #root {
    height: 100%;
  }
  
  body {
    font-family: 'Poppins', sans-serif !important;
    background-color: #f7f7f7 !important;
  }

  a {
    color: #333366 !important;
    font-weight: 600 !important;
  }

  a svg {
    color: #ffffff !important;
    font-weight: normal !important;
  }
  
  .App {
    display: flex;
    justify-content: center;
  }
  
  .MuiBox-root > .MuiBox-root {
    background-color: #f7f7f7;
  }
  
  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-indent: none;
  }

  .header {
    background-color: rgb(51, 51, 102);
  }
  
  .login-button {
    padding: 10px 50px !important;
    font-size: 16px !important;
  }
  
  .column {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .m-t-25 {
    margin-top: 25px;
  }
  
  .transform-y-15 {
    transform: translateY(13.5px);
  }

  .margin-r-5 {
    margin-right: 5px;
  }

  .margin-t-35 {
    margin-top: 35px !important;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .max-w-300 {
    max-width: 300px;
  }
  
  .max-w-100 {
    max-width: 84px;
  }
  
  .max-w-70 {
    max-width: 70px;
  }

  .button-plus {
    min-width: 20px !important;
    max-width: 20px !important;
  }

  .pad-lr-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  
  .margin-r-10 {
    margin-right: 10px !important;
  }

  .margin-lr-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }

  .max-w-20 {
    max-width: 20px !important;
  }

  .min-w-20 {
    max-width: 20px !important;
  }

  .max-w-12 {
    max-width: 12px !important;
  }

  .min-w-12 {
    min-width: 12px !important;
  }

  .if-block {
    position: relative;
    display: inline-flex;
    width: 100% !important;
  }
  
  .if-col {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100% !important;
  }
  
  .border-b-1 {
    border-bottom: 1px solid #a1a1a1;
  }
  
  .document-creation textarea {
    min-height: 250px !important;
    height: 250px !important;
    width: 100% !important;
  }
  
  .document-creation .MuiInputBase-colorPrimary {
    width: 100% !important;
  }
  
  .MuiCardContent-root {
    padding: 12px !important;
  }
  
  .MuiChip-root {
    height: 24px !important;
    border-radius: 0 !important;
    margin: 0 !important;
  }
  
  [hidden] {
    display: none !important;
  }
  
  [class*="MuiButtonBase-root-MuiListItem-root"]:hover {
    background-color: #333366 !important;
  }
  
  [class*="MuiButtonBase-root-MuiListItem-root"].Mui-selected {
    background-color:  #333366 !important;
    color: #ffffff  !important;
  }
  
  [class*="MuiButtonBase-root-MuiAccordionSummary-root"]:hover {
    background-color: #333366 !important;
    color: #ffffff  !important;
  }
  
  .MuiButton-containedSecondary {
    color: #fff;
    background-color: #1d43c2 !important;
  }
  
  [class*="MuiButton-containedPrimary"] {
    background-color:#e53935 !important;
    color: #ffffff  !important;
  }
  
  .if-loader {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 63px;
    height: 63px;
  }
  
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  
  .loader {
    margin: auto;
    font-size: 10px;
    position: absolute;
    top: 15%;
    left: 15%;
    transform: translate(-50%, -50%);
    border-top: 4px solid rgba(229, 57, 53, 0.2);
    border-right: 4px solid rgba(229, 57, 53, 0.2);
    border-bottom: 4px solid rgba(229, 57, 53, 0.2);
    border-left: 4px solid #e53935;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
  
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .yesCell {
    background-color: rgb(0, 189, 0, 1);
    color: rgb(255, 255, 255);
    text-align: center;
  }
  
  .noCell {
    background-color: rgb(255, 0, 0, 1);
    color: rgb(255, 255, 255);
    text-align: center;
  }
  
  .customCell {
    padding: 0 5px !important;
    border: 1px solid rgb(237, 237, 237);
  }
  
  .go-back {
    border: 2px solid #333366 !important;
    color: #333366 !important;
    position: relative;
    display: inline-flex;
    align-content: center;
    padding: 8px 20px !important;
    margin: 0 !important;
  }
  
  .go-back:hover {
   background-color: #333366 !important;
   color: #ffffff !important;
  }

  .generated-items div {
    font-size: 12px !important;
  }

  .list-items {
    margin-top: 51px !important;
  }
  
  .list-items .font-s-14 span {
    font-size: 14px !important;
  }

  .saved-grade {
    position: relative;
    display: inline-flex;
    width: 24px;
    height: 46px;
    min-width: 24px;
    max-width: 24px;
    max-height: 46px;
    background-color: #00be00;
    font-size: 12px !important;
    color: #ffffff;
    font-weight: 600;
    justify-content: center;
    align-items: center;
  }

  .saved-layers {
    position: relative;
    display: inline-flex;
    flex-wrap: nowrap;
    width: 100%;
    min-width: 80px;
    height: 46px;
    max-height: 46px;
    padding: 0 10px;
    background-color: #e53935;
    font-size: 12px !important;
    color: #ffffff;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .button-active {
    background-color: #00be00 !important;
  }

  .graphs {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }

  .visit-card {
    position: relative;
    min-width: 300px !important;
  }

  /* international phone numbers */

  .phone-input label {
    background: #f7f7f7 !important
  }

  .phone-input .form-control {
    background: #f7f7f7 !important;
    width: 100% !important;
    min-height: 55px;
  }  
  
  .margin-t-2-b-3 .form-control {
    margin-top: 2px;
    margin-bottom: 3px;
  }

  .margin-b-5 .form-control {
    margin-bottom: 5px;
  }

  .slide-button-active {
    background-color: #333366 !important;
    color: #ffffff !important;
  }

  [class*="MuiAccordion-root"] {
    position: relative !important;
    display: inline-flex !important;
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;
  }

  [class*="MuiCollapse-root"] {
    width: 100% !important;
  }
  
  [class*="MuiAccordion-root"] .MuiButtonBase-root {
    width: 100% !important;
  }

  [class*="MuiAccordion-root"] .MuiButtonBase-root:hover {
    background-color: #333366 !important;
    color: #ffffff !important;
  }

  [class*="MuiAccordion-root"] .MuiAccordionSummary-content {
    width: 100% !important;
  }

  [class*="MuiCollapse-wrapper"] {
    position: relative !important;
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
  }

  [class*="MuiCollapse-wrapperInner"] {
    position: relative !important;
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
  }

  [class*="MuiCollapse-wrapperInner"] > div {
    position: relative !important;
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
  }

  [class*="MuiAccordionDetails-root"] {
    position: relative !important;
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
  }

  [class*="MuiTypography-root"] {
    font-size: 14px !important;
  }

  [class*="MuiList-root"] {
    height: 100%;
    overflow-y: auto !important;
  }

  .MuiChip-root.MuiChip-filled {
    border-radius: 12px !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .child-grid .MuiBox-root {
    background-color: #ffffff !important;
  }

  .country-dropdown {
    font: inherit;
    letter-spacing: inherit;
    color:rgba(0, 0, 0, 0.7) !important;
    padding: 0 12px 3px !important;
    border: 1px solid #dadada !important;
    border-radius: 6px !important;
    outline-color: none;
    box-sizing: border-box;
    background: none;
    height: 56px !important;
    min-height: 56px !important;
    margin: 12px 0 0;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
  }

  .country-dropdown:focus-visible {
    outline-color: rgba(0, 0, 0, 0.7) !important;
  }

  .compact-form-heading {
    font-size: 16px !important;
    margin: 0 0 25px !important;
    font-weight: 600;
  }

  .compact-form {
    font-size: 14px !important;
    margin: 5px 0 -5px !important;
  }

  .MuiPopover-root [class*="MuiButtonBase-root"]:hover {
    color: #000000 !important;
  }

  [class*="MuiListItemIcon-root"] {
    min-width: 42px !important;
  }

  [class*="MuiLink-root-MuiListItem-root"] {
    padding-left: 10px !important;
  }
  
  .dark-hover:hover {
    color: #000000 !important;
  }

  [class*="MuiButtonBase-root-MuiCheckbox-root"] {
    padding: 6px !important;
  }

  .z-i-100 {
    z-index: 100;
  }

`;
