import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "@emotion/styled";
import { Stack, Button, CircularProgress, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobalUser, userLoginAPI } from '../../globalSlice';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import logo from "../../images/blood-works-logo.png";
import MaterialField from '../../Components/Formik/MaterialField';
import './LoginPage.css';


const PageWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  font-family: 'Poppins', sans-serif !important;
  min-height: 400px;
`;

const Column = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height:60px;
`;

const ImageRow = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 120px;
`;
function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const { loading, error } = useSelector(selectGlobalUser);

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.'),
  });

  const submitForm = async (values) => {
    const response = await dispatch(userLoginAPI(values));
    if (response.payload && response.payload.success) {
      navigate('/');
    } else {
      formik.setErrors({ username: 'Login failed', password: 'Please check your credentials' });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    if (error && error.code === 'SPECIFIC_ERROR_CODE') {
      formik.resetForm();
    }
  }, [error]);

  return (
    <PageWrapper>
      <Wrapper>
        <ImageRow>
          <img src={logo} alt="Blood Works" style={{ maxWidth: '220px' }} />
        </ImageRow>
        <Stack alignItems="center" justifyContent="center" marginTop={4} sx={{ minWidth: { xs: '80vw', md: '400px', xl: '600px' } }}>
          <Stack component="form" onSubmit={formik.handleSubmit} noValidate autoComplete="off" justifyContent="center" alignItems="center" spacing={2} flexGrow="1" minWidth="80%">
            <MaterialField
              formik={formik}
              name="username"
              label="Username"
              variant="outlined"
              fullWidth
              color="primary"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <MaterialField
              formik={formik}
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              color="primary"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Column>
              <Button 
                type="submit" 
                disabled={loading} 
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            </Column>
            <Column>
              {loading && <CircularProgress />}
              {error && <Alert severity="error">{error.message}</Alert>}
            </Column>
          </Stack>
        </Stack>
      </Wrapper>
    </PageWrapper>
  );
}

export default LoginPage;