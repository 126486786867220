import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

function PracticeDetailSection({ practiceDetails }) {
    return (
        <Grid container spacing={2} >
            <Grid item xs={6}>
                <Box sx={{ m: 2 }} >
                <Typography
                    color="grey.800"
                    variant="subtitle1"
                    sx={{
                    transition: "color 0.15s ease-in-out",
                    textTransform: "capitalize",
                    }}
                >
                name:   <b>{practiceDetails.name ?? ''}</b>
                </Typography>

                <Typography color="grey.800" variant="subtitle1">
                    Company: <b>{practiceDetails.company.name ?? ''}</b>
                </Typography>
                <Typography color="grey.800" variant="subtitle1">
                    Status: <Chip
                    sx={{ marginTop: 2 }}
                    color={practiceDetails.status == 'ACTIVE'? 'active': 'disabled' }
                    label={practiceDetails.status}
                    />
                </Typography>
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box sx={{ m: 2 }} >
               
                <Typography sx={{ color: "grey.800" }}>
                    <b>{practiceDetails.address.addressLine1 ?? ''} {practiceDetails.address.addressLine2 ?? ''}</b>
                </Typography>
                <Typography fontWeight={600} sx={{ color: "grey.800" }}>
                    {practiceDetails.address.city ?? "-"},{` `}
                    {practiceDetails.address.province ?? "-"},{` `}
                    {practiceDetails.address.postalCode ?? "-"}
                </Typography>

                </Box>
            </Grid>
            </Grid>
    )
}

export default PracticeDetailSection;