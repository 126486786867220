import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const Breadcrums = styled.h2`
  position: relative;
  display: inline-flex;
  font-size: 18px;
  font-weight: 400;
  margin: 20px 40px 20px 0;
  color: #000000;
`;

const Heading = styled.h2`
  position: relative;
  display: inline-flex;
  font-size: 28px;
  font-weight: 500;
  margin: 20px 0;
  color: #000000;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Block = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
`;


const ButtonBorder = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 20px 0 30px;
  border: 1px solid #333366;
  border-radius: 6px;
  font-weight: 500;
  margin-left: 15px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;

  &:before {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    content: '↑';
    color: #333366;
    font-weight: 700;
    font-size: 20px;
  }
`;

const SolidButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 20px 0 30px;
  background-color: #333366;
  border-radius: 6px;
  font-weight: 500;
  margin-left: 15px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  color: #ffffff;

  &:before {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    content: '+';
    color: #ffffff;
    font-weight: 500;
    font-size: 20px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function DigitalPathologyAnalysisHeader() {

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Header>
      <Row>
        <Block>
          <Breadcrums>
            Digital Pathology Analysis
          </Breadcrums>
          <Breadcrums>
            DPA - Imaging
          </Breadcrums>
        </Block>
      </Row>
      <Row>
        <Block>
          <Heading>
            Digital Pathology Analysis - Imaging Session
          </Heading>
        </Block>
        <Block>
          <ButtonBorder>
            Upload
          </ButtonBorder>
          <SolidButton>
            New DPA
          </SolidButton>
        </Block>
      </Row>
      </Header>
      


      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Imaging Session" id="tab-0" aria-controls="tabpanel-0" />
        <Tab label="Notes &amp; Summary" id="tab-1" aria-controls="tabpanel-1" />
        <Tab label="Reports" id="tab-2" aria-controls="tabpanel-2" />
      </Tabs>
      <TabPanel value={value} index={0}>
        Content for Tab 1
      </TabPanel>
      <TabPanel value={value} index={1}>
        Content for Tab 2
      </TabPanel>
      <TabPanel value={value} index={2}>
        Content for Tab 3
      </TabPanel>
    </Wrapper>
  )
}

export default DigitalPathologyAnalysisHeader;