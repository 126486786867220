import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import { selectGlobalUser } from "../../globalSlice";
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import styled from "@emotion/styled";
import { selectBearerToken, selectUserGroup } from "../../globalSlice";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
`;

function PracticeGrid({ data }) {

  // const queryClient = useQueryClient();
  // const [page, setPage] = useState(0);
  // const token = useSelector(selectBearerToken);

  console.log(data);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => <Link to={`/practices/${params.id}`}>{params.value}</Link>,
    }, 
    {
      field: 'company',
      headerName: 'Company',
      width: 280, 
      renderCell: (params) => <Link to={`/companies/${params.value.id}`}>{params.value.name}</Link>,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 180,
    }
  ];

  const rows = data?.content.map((practice) => (
    {
      id: practice.id,
      name: practice.name,
      company: { name: practice.company.name, id: practice.company.id, },
      city: practice.address.city,
    }
  ));

  return (
    <Wrapper>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </Wrapper>
  );
}


export default PracticeGrid;
