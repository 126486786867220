import React from "react";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { selectBearerToken } from "../../globalSlice";
import ConfirmationDialog from "../../Components/Dialog/ConfirmDialog";
import { getPracticeUserById, deletePracticeUser } from "../../api/practiceUserApi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

const SubHeading = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
  color: #000000;
  margin: 10px 0;
`;

function PracticeUserDetailsPage({ isGoBackable = true }) {
  const { userId, practiceId } = useParams();
  const token = useSelector(selectBearerToken);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(true);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const navigate = useNavigate();

  // Fetch PracticeUser details using getPracticeUserById
  const { data: userDetails, isLoading, isError } = useQuery(
    ["practiceUserById", userId],
    () => getPracticeUserById(token, userId)
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography color="error">Error loading user data. Please try again later.</Typography>;
  }

  const handleClose = (newValue) => {
    setOpen(false);
    setValue(newValue);
    
    if (newValue) {
      removeUser(newValue);
    }
  };

  const showDialog = () => {
    setOpen(true);
  };

  // Redirect to edit form
  const toEditForm = () => {
    navigate(`/practices/${practiceId}/users/${userId}/edit`, { state: { userData: userDetails } });
  };

  // Delete the PracticeUser using deletePracticeUser
  const removeUser = (confirmDelete) => {
    if (confirmDelete) {
      deletePracticeUser(token, userId)
        .then(() => {
          navigate(`/companies/${practiceId}`, { replace: true });
        })
        .catch((error) => {
          setErrorMsg(error.response?.data?.message || error.message);
        });
    }
  };

  return (
    <Wrapper>
      {isGoBackable && <GoBackButton />}
      <SubHeading>User Details</SubHeading>

      {userDetails && (
        <Box>
          <Typography variant="h6">Name: {userDetails.name}</Typography>
          <Typography variant="body1">Username: {userDetails.username}</Typography>
          <Typography variant="body1">Email: {userDetails.email}</Typography>

          <Button variant="contained" color="primary" onClick={toEditForm}>Edit User</Button>
          <Button variant="contained" color="secondary" onClick={showDialog}>Delete User</Button>

          <ConfirmationDialog
            id="DELETE"
            keepMounted
            open={open}
            onClose={handleClose}
            value={value}
            title="Confirm Delete User"
            message="Are you sure you want to delete this user?"
          />

          {errorMsg && <Typography color="error">{errorMsg}</Typography>}
        </Box>
      )}
    </Wrapper>
  );
}

export default PracticeUserDetailsPage;
