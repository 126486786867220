export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const userRoles = {
  ADMIN: 'ADMIN',
  PRACTICE_USER: 'PRACTICE_USER',
  BACK_OFFICE_USER: 'BACK_OFFICE_USER',
  COMPANY_ADMIN: 'COMPANY_ADMIN'
};

export const FormMode = {
  ADD: 'add',
  EDIT: 'edit'
};
