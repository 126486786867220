import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from "@mui/material/Pagination";

import { selectBearerToken, selectUserGroup } from "../../globalSlice";
import { getPracticesByCompanyIdPaginated } from '../../fetchers/practices';
import PracticeCard from "./PracticeCard";
import SelectionGridItem from "../../Components/SelectionGridItem/SelectionGridItem";

function PracticeSelection({ companyId }) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);

  const token = useSelector(selectBearerToken);
  const role = useSelector(selectUserGroup);

  console.log('role', role);

  const { isLoading, isError, data, isPreviousData } =
    useQuery({
      queryKey: ["practices", companyId, page],
      queryFn: async () => getPracticesByCompanyIdPaginated(token, companyId, page, 12),
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    });

  useEffect(() => {
    if (!isPreviousData && data?.totalPages < page - 1) {
      console.log("pre");
      queryClient.prefetchQuery({
        queryKey: ["practices", companyId, page + 1],
        queryFn: () => getPracticesByCompanyIdPaginated(token, companyId, page + 1, 2),
      });
    }
  }, [data, isPreviousData, page, companyId, queryClient]);

  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ marginTop: 2 }}
      >
        Practice Selection
      </Typography>
      <Typography
        color="grey.800"
        variant="subtitle1"
        textAlign="center"
        gutterBottom
      >
        Please select a practice:
      </Typography>
      <Stack alignItems="center" direction="column" marginX={2}>
        {isLoading && <CircularProgress />}
        {isError && <Alert severity="error">An error occurred</Alert>}
        <Grid container spacing={2} justifyContent="center" sx={{ margin: 2, width: '100%' }}>
          {data?.content.map((practice) => (
            <SelectionGridItem key={`patient_card_${practice.id}`}>
              <PracticeCard practice={practice} />
            </SelectionGridItem>
          ))}
          {data?.content.length === 0 && (
            <Alert severity="error">No data to display</Alert>
          )}
        </Grid>
        <Box>
          <Pagination
            disabled={isLoading || isError}
            count={data?.totalPages}
            page={page + 1}
            onChange={(e, nextPage) => setPage(nextPage - 1)}
            shape="rounded"
          />
        </Box>
      </Stack>
    </>
  )
}

export default PracticeSelection;