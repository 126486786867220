import React, { useState, useEffect } from "react";
import "jspdf-autotable";
import styled from "@emotion/styled";
import axios from 'axios';
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import SelectionCard from "../../Components/SelectionCard/SelectionCard";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { selectBearerToken } from "../../globalSlice";

const Header = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  padding-bottom: 10px;
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

const Footer = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 40px;
`;

const FooterBlock = styled.div`
  position: relative;
  display: inline-flex;
  margin: 5px 0 0 0;
  width: 100%;
`;

const FooterBlockEnd = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  margin: 5px 0 0 5px;
  width: 100%;
`;

const FooterRight = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

function VisitCard({
  id, // This is visitId
  date,
  linkBase,
  patientDetails,
  patientDetailsId,
  displayId,
}) {
 
  return (
      <SelectionCard 
        LinkStyles={{ margin: 0 }}
      >
        <CardContent className="padding-0" sx={{ textAlign: "left" }}>
          <Header>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
            <Chip
              label={
                <Typography fontSize={14} color="secondary">
                  <b>Visit:</b> {displayId}
                </Typography>
              }
            />
            </Box>
            <Box>
              <Typography display="inline-block" marginRight={1}>
                Visit Date:
              </Typography>
              <Typography
                display="inline-block"
                fontWeight="600"
                color="grey.700"
              >
                {date}
              </Typography>
            </Box>
          </Header>

          {patientDetails && (
            <Box sx={{ marginBottom: 2 }}>
             
              <Typography color="grey.800">
                Patient Name: {patientDetails.name}
              </Typography>
            
            </Box>
          )}
          <Divider sx={{ marginBottom: 2 }} />

          <Footer>
         
            <FooterRight>
              <FooterBlockEnd>
              <Button
                component={Link}
                to={`/visits/edit/${id}/visualization?patientDetailsId=${patientDetailsId}&date=${encodeURIComponent(date)}&displayId=${displayId}`}
                color="primary"
                variant="contained"
                className="margin-r-10"
              >
                Visualization
              </Button>
                <Button
                  component={Link}
                  to={`/visits/edit/${id}`}
                  color="primary"
                  variant="contained"
                >
                  Edit
                </Button>
              </FooterBlockEnd>
            </FooterRight>
          </Footer>
        </CardContent>
      </SelectionCard>
     
   
  );
}

export default VisitCard;
