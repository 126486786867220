import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

import MaterialTextarea from "../../Components/Formik/MaterialTextarea";
import { selectBearerToken } from "../../globalSlice";
import { apiBaseUrl } from "../../utilities";
import { format } from "date-fns";

function AddVisitForm({ patientDetailsId, autoSubmit, onSubmissionComplete }) {
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const token = useSelector(selectBearerToken);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const initialValues = {
    summary: "summary",
    recommendations: "recommendations",
    implications: "implications",
    medicalConsiderations: "medical considerations",
  };

  const validationSchema = Yup.object({
    summary: Yup.string().required("Required."),
    recommendations: Yup.string().required("Required."),
    implications: Yup.string().required("Required."),
    medicalConsiderations: Yup.string().required("Required."),
  });

  const triggerSuccessMsg = () => {
    setShowSuccess(true);
    setTimeout(() => {
      if (isMounted.current) {
        setShowSuccess(false);
      }
    }, 2000);
  };

  const submitForm = (values, { resetForm }) => {
    setSubmitting(true);

    const formatDate = (date) => {
      return format(new Date(date), 'yyyy-MM-dd');
    };

    const formattedDate = formatDate(new Date());

    const jsonForm = {
      date: formattedDate,
      summary: values.summary,
      recommendations: values.recommendations,
      implications: values.implications,
      medicalConsiderations: values.medicalConsiderations,
      patientDetailsId: parseInt(patientDetailsId),
    };

    // Create a visit
    axios.post(`${apiBaseUrl}/cmn/v1/visits`, JSON.stringify(jsonForm), {
      headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (isMounted.current) {
        triggerSuccessMsg();
        const newVisitId = response.data.id;
        onSubmissionComplete(newVisitId);
      }
    })
    .catch((error) => {
      if (isMounted.current) {
        try {
          setErrorMsg(error.response.data.message);
        } catch (e) {
          setErrorMsg(error.message);
        }
      }
    })
    .finally(() => {
      if (isMounted.current) {
        setSubmitting(false);
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    if (autoSubmit) {
        submitForm(initialValues, { resetForm: () => {} });
    }
}, [autoSubmit]);

  return (
    <>
      <Divider>
        <Typography
          variant="h4"
          color="grey.800"
          textAlign="center"
          gutterBottom
        >
          Add a visit:
        </Typography>
      </Divider>

      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Stack
          spacing={2}
          alignItems="center"
          sx={{
            width: { md: "75%", lg: "65%", xl: "50%" },
            marginX: { md: "12.5%", lg: "17.5%", xl: "25%" },
          }}
        >
          <MaterialTextarea
            formik={formik}
            name="summary"
            label="*Summary"
            variant="outlined"
          />
          <MaterialTextarea
            formik={formik}
            name="recommendations"
            label="*Recommendations"
            variant="outlined"
          />
          <MaterialTextarea
            formik={formik}
            name="implications"
            label="*Implications"
            variant="outlined"
          />
          <MaterialTextarea
            formik={formik}
            name="medicalConsiderations"
            label="*Medical Considerations"
            variant="outlined"
          />
          <Stack marginY={2} width="100%" alignItems="center">
            {showSuccess && (
              <Alert severity="success">Visit successfully captured.</Alert>
            )}
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </Stack>
          <Stack width="100%" alignItems="flex-end">
            <Button
              sx={{ marginTop: 2 }}
              type="submit"
              variant="contained"
              disabled={submitting}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default AddVisitForm;
