import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiBaseUrl } from './utilities';
import { LSGet, LSSet, LSRemove } from './localstorage';

const initialState = {
  authorization: {
    token: null,
    loading: false,
    user: null,
    error: null,
  }
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    callLogin: state => {
      state.authorization.error = null;
      state.authorization.token = null;
      state.authorization.user = null;
      state.authorization.loading = true;
      // console.log("callLogin");
    },
    callLoginSuccess: (state, { payload }) => {
      state.authorization.loading = false;
      state.authorization.token = payload;
      // console.log("callLoginSuccess");
    },
    callLoginFail: (state, { payload }) => {
      state.authorization.loading = false;
      state.authorization.error = payload;
      // console.log("callLoginFail");
    },
    callValidateToken: (state, { payload }) => {
      state.authorization.error = null;
      state.authorization.loading = true;
      state.authorization.user = null;
      state.authorization.token = payload;
      // console.log("callValidateToken");
    },
    callValidateTokenSuccess: (state, { payload }) => {
      state.authorization.loading = false;
      state.authorization.user = payload;
      // console.log("callValidateTokenSuccess");
    },
    callValidateTokenFail: (state, { payload }) => {
      state.authorization = Object.assign({}, initialState.authorization, {
        error: payload || null
      });
      // console.log(callValidateTokenFail);
    },

    fakeLogOut: (state) => {
      state.authorization = Object.assign({}, initialState.authorization, {
        error: {
          message: 'Logged out',
          type: 'info',
        }
      });
      LSRemove('b_tok');
    },
  },
});

export const selectGlobalUser = state => state.global.authorization;
export const selectBearerToken = state => state.global.authorization.token;
export const selectUserGroup = state => state.global.authorization.user?.group;

export const { callLogin, callLoginSuccess, callLoginFail, callValidateToken, callValidateTokenSuccess, callValidateTokenFail, fakeLogOut } = globalSlice.actions;

// Load user data based on stored token
export const loadUserViaToken = () => {
  return async (dispatch, getState) => {
    try {
      let token = selectBearerToken(getState());
      if (!token) token = LSGet('b_tok');
      if (token) {

        dispatch(callValidateToken(token));

        const apiUrl = apiBaseUrl + '/cmn/v1/users/profile';
        const headers = { "Authorization": `Bearer ${token}`, };
        const result = await axios.get(apiUrl, { headers: headers });

        dispatch(callValidateTokenSuccess(result.data));

      } else {
        dispatch(callValidateTokenFail(
          { message: 'No token found, please log in.', type: 'info' }
        ));
      }
    } catch (err) {
      let msg = 'Token has expired. Please log in.';
      dispatch(callValidateTokenFail({ message: msg, type: 'warning' }));
    }
  };
};

export const userLoginAPI = ({ username, password }) => {
  return async (dispatch) => {
    const apiUrl = apiBaseUrl + '/opn/authenticate';

    try {
      dispatch(callLogin());

      const headers = { "Content-Type": "application/json" };
      const data = { username, password };
      const result = await axios.post(apiUrl, JSON.stringify(data), { headers });
      const token = result.data.token;

      LSSet('b_tok', token);
      dispatch(callLoginSuccess(token));
      await dispatch(loadUserViaToken());
      return { payload: { success: true } };
    }
    
    catch (err) {
      let msg = 'An error occurred while trying to log in.';
      const { status, message } = err.response?.data || {};

      if (message === 'INVALID_CREDENTIALS') {
        msg = 'Incorrect username or password.';
      }

      dispatch(callLoginFail({ status, message: msg, type: 'error' }));
      return { payload: { success: false, message: msg } };
    }
  };
};

export default globalSlice.reducer;
