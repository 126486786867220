import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

function ReportLink({ href, title }) {
  return (
    <a href={`${href}`} target="_blank">
      <Card
        sx={{
          textAlign: "center",
          cursor: "pointer",
          border: "1px solid transparent",
          transition: "all 0.15s ease-in-out",
          ":hover": {
            borderColor: "primary.main",
          },
        }}
      >
        <CardContent sx={{ p: 3 }}>
          <Typography variant="h4" m={0} color="grey.800">{title}</Typography>
        </CardContent>
      </Card>
    </a>
  );
}

export default ReportLink;
