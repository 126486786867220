import axios from "axios";

import { apiBaseUrl } from "../utilities";

const baseUrl = `${apiBaseUrl}/cmn/v1/companies`;
const practiceUrl = `${apiBaseUrl}/cmn/v1/practices`;
const practiceUrl_ = `${apiBaseUrl}/cmn/v1/practice`;

export const getPracticesByCompanyIdPaginated = async (
  token,
  companyId,
  page = 0,
  size = 12
) => {
  const { data } = await axios.get(`${baseUrl}/${companyId}/practices`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { page, size },
  });
  return data;
};

export const getPracticeById = async (token, practiceId) => {
  const { data } = await axios.get(`${practiceUrl}/${practiceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}

export const getPracticeUsersById = async (token, practiceId) => {
  const { data } = await axios.get(`${practiceUrl_}/${practiceId}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}

export const createPractice = (token, practice) => {
  return axios.post(`${practiceUrl}`, JSON.stringify(practice), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export const updatePractice = (token, practiceId, practice) => {
  return axios.put(`${practiceUrl}/${practiceId}`, JSON.stringify(practice), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export const deletePractice = async (token, practiceId) => {
  return axios.delete(`${practiceUrl}/${practiceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
