import axios from 'axios';
import { apiBaseUrl } from '../utilities';

// API to get all users under a practice
export const getPracticeUsersByPracticeId = async (token, practiceId) => {
  const response = await axios.get(`${apiBaseUrl}/cmn/v1/practice/${practiceId}/users`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getPracticeUserById = async (token, userId) => {
  const response = await axios.get(`${apiBaseUrl}/cmn/v1/users/profile`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const deletePracticeUser = async (token, practiceId, userId) => {
  const response = await axios.delete(`${apiBaseUrl}/admin/v1/practice/${practiceId}/users`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { userId },
  });
  return response.data;
};

export const getUserProfile = async (token) => {
  const { data } = await axios.get(`${apiBaseUrl}/cmn/v1/users/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getMyPracticeDetails = async (token) => {
  const { data } = await axios.get(`${apiBaseUrl}/cmn/v1/my-practice`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getPatientsByCompanyId = async (token, companyId, page = 0, size = 12) => {
  const params = { page, size };

  const { data } = await axios.get(`${apiBaseUrl}/cmn/v1/company/${companyId}/patients`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  return data;
};