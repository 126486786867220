import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Wrapper = styled.div`
  position: fixed;
  top: 3px;
  left: 2px;
  z-index: 3000;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  margin: 20px 30px;
`;

function GoBackButton(props) {
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);
  return (
    <Wrapper>
    <Button
    variant="outlined"
    onClick={goBack}
    color="primary"
    className="go-back"
    startIcon={<ArrowBackIcon />}
    {...props}
  >
    Go Back
  </Button>
    </Wrapper>
   
  )
}

export default GoBackButton;