import React from 'react';
import { Alert, Box, Button, Card, CardContent } from '@mui/material';

function Unauthorized() {
  return (
    <Box padding={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
      <Card elevation={3}>
        <CardContent>
          <Alert severity="error">Unauthorized Access. Please go back.</Alert>
          <Box marginTop={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button to="/" variant="outlined" color="primary" sx={{ ':hover': { color: 'primary.main' }}}>Return</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Unauthorized;