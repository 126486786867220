import axios from "axios";

import { apiBaseUrl } from "../utilities";

const url = `${apiBaseUrl}/cmn/v1/reports/anomalies`;

export const getAnomaliesReport = async (
  token,
) => {
  const getAnomaliesReportRequest = new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
    cache: "default",
  })
  return fetch(getAnomaliesReportRequest).then(resp => {
    if (resp.status === 200) return resp.blob();
    return null;
  })
};
