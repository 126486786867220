import React from "react";

import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';

function PatientDetails({ patientDetails }) {
  let genderColor;
  switch (patientDetails.gender) {
    case "MALE":
      genderColor = "genderMale";
      break;
    case "FEMALE":
      genderColor = "genderFemale";
      break;
    default:
    // Keep undefined
  }
  return (
    <Card elevation={0} sx={{ p: 2 }}>
      <CardContent>
        <Typography
          color="secondary.light"
          variant="h5"
          sx={{
            transition: "color 0.15s ease-in-out",
            textTransform: "capitalize",
          }}
        >
          {patientDetails.name} {patientDetails.surname}
        </Typography>
        <Typography color="grey.800" variant="subtitle1">
          ID Number: <b>{patientDetails.idNumber}</b>
        </Typography>
        <Typography color="grey.800" variant="subtitle1">
          Date of Birth: <b>{patientDetails.dateOfBirth}</b>
        </Typography>
        
        <Typography sx={{ color: "grey.800" }}>
          <Typography component="span">
            Address:
          </Typography>{" "}
          <b>{patientDetails.physicalAddress.addressLine1 ?? "-"}</b>
        </Typography>
        <Typography fontWeight={600} sx={{ color: "grey.800" }}>
          {patientDetails.physicalAddress.city ?? "-"},{` `}
          {patientDetails.physicalAddress.province ?? "-"},{` `}
          {patientDetails.physicalAddress.postalCode ?? "-"}
        </Typography>
        <Chip
          sx={{ marginTop: 2 }}
          color={genderColor}
          label={patientDetails.gender}
        />
      </CardContent>
    </Card>
  );
}

export default PatientDetails;
