import axios from "axios";

import { apiBaseUrl } from "../utilities";

const baseUrl = `${apiBaseUrl}/admin/v1/companies`;

export const getCompaniesPaginated = async (
  token,
  page = 0,
  size = 12
) => {
  const { data } = await axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: { page, size },
  });
  return data;
};

export const getCompanyDetailsById = async (token, companyId) => {
  const { data } = await axios.get(`${baseUrl}/${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
}

export const addCompany = (token, company) => {
  return axios.post(`${baseUrl}`, company, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

}

export const updateCompany = (token, companyId, company) => {
  return axios.put(`${baseUrl}/${companyId}`, JSON.stringify(company), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export const deleteCompany = async (token, companyId) => {
  return axios.delete(`${baseUrl}/${companyId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
