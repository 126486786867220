import React from "react";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { selectBearerToken } from "../../globalSlice";
import ConfirmationDialog from "../../Components/Dialog/ConfirmDialog";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { deletePractice, getPracticeById, getPracticeUsersById } from "../../fetchers/practices";
import { visitByPracticeId } from "../../fetchers/visits";
import PracticeDetailSection from "./PracticeDetailSection";
import PracticeUsersGrid from "../PracticeGrid/PracticeUsersGrid";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

const SubWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 0 30px;
`;

const InnerWrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 10px 20px;
`;

const SubHeading = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
  color: #000000;
  margin: 10px 0;
`;

function PracticeDetailsPage({ isGoBackable = true }) {
    const { practiceId } = useParams();
    const token = useSelector(selectBearerToken);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState(null);

    const [practiceResult, practiceUsersResult, visitsResult] = useQueries({
        queries: [
            {
                queryKey: ["practiceById", practiceId],
                queryFn: () => getPracticeById(token, practiceId)
            },
            {
                queryKey: ["practiceUsersById", practiceId],
                queryFn: () => getPracticeUsersById(token, practiceId)
            },
            {
                queryKey: ["visitsByPracticeId", practiceId],
                queryFn: () => visitByPracticeId(token, practiceId)
            }
        ]
    });

    if (practiceResult.isLoading || practiceUsersResult.isLoading || visitsResult.isLoading) {
        return <CircularProgress />;
    }

    if (practiceResult.isError || practiceUsersResult.isError || visitsResult.isError) {
        return <Typography color="error">Error loading practice data. Please try again later.</Typography>;
    }

    const handleClose = (newValue) => {
        setOpen(false);
        setValue(newValue);
        
        if (newValue) {
          removePractice(newValue);
        }
    };
    
    const showDialog = () => {
        setOpen(true);
    };
  
    const triggerSuccessMsg = () => {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 2000);
    };

    const toEditForm = () => {
      navigate(`/practices/${practiceId}/edit`, { state: { practiceData: practiceResult.data } });
    };

    const removePractice = (confirmDelete) => {
      if (confirmDelete) {
        deletePractice(token, practiceResult.data.id)
          .then(() => {
            triggerSuccessMsg();
            const companyId = practiceResult.data.companyId;
            console.log("Navigating to company:", companyId);
            if (companyId) {
              navigate(`/companies/${companyId}`, { replace: true });
            } else {
              setErrorMsg("Company ID not found.");
            }
          })
          .catch((error) => {
            setErrorMsg(error.response?.data?.message || error.message);
          });
      }
    };    

    return (
      <Wrapper>
        {isGoBackable && <GoBackButton />}
        <SubHeading>Practice Details</SubHeading>
        {practiceResult.data && (
          <>
            <SubWrapper>
                <PracticeDetailSection practiceDetails={practiceResult.data} />
            </SubWrapper>
            <SubHeading>Practice Users</SubHeading>
            {practiceUsersResult.data && (
              <InnerWrapper>
                <PracticeUsersGrid data={practiceUsersResult.data} />
              </InnerWrapper>
            )}
            <ConfirmationDialog
                id="DELETE"
                keepMounted
                open={open}
                onClose={handleClose}
                value={value}
                title="Confirm Delete Practice"
                message="Are you sure you want to remove this practice?"
            />
            {errorMsg && (
              <Typography color="error">{errorMsg}</Typography>
            )}
          </>
        )}
      </Wrapper>
    );
}

export default PracticeDetailsPage;
