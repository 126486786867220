import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TypeSelectionCard from '../../Components/TypeSelectionCard/TypeSelectionCard';
import GoBackButton from '../../Components/GoBackBtn/GoBackBtn';

function VisitSelectionPage({ title = 'View visits by:', subtitle = 'You need to select a visit.', isGoBackable = false}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: 'calc(100vh - 77px)' }}>
      <Typography color="grey.700" variant="subtitle2" gutterBottom>{subtitle}</Typography>
      <Typography color="grey.800" variant="subtitle1" sx={{ fontSize: 22 }}>{title}</Typography>
      <TypeSelectionCard title="Practice" link="practices" />
      <TypeSelectionCard title="Client" link="patients" />

      {isGoBackable && <GoBackButton sx={{ marginTop: 2 }} />}
    </Box>
  );
}

export default VisitSelectionPage;