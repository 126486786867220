import AES from 'crypto-js/aes';

const secretPass = '42IstheAnswer';

export function LSGet(key, encrypt = false) {
  const lsItemJson = localStorage.getItem(key);
  let lsItem = JSON.parse(lsItemJson);
  return encrypt ? AES.decrypt(lsItem, secretPass) : lsItem;
}

export function LSSet(key, value, encrypt = false) {
  let lsItem = JSON.stringify(value);
  if (encrypt) {
    lsItem = AES.encrypt(JSON.stringify(lsItem), secretPass);
  }
  localStorage.setItem(key, lsItem);
}

export function LSRemove(key) {
  console.log("token cleared");
  localStorage.removeItem(key);
}
