import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';

function SelectionGridItem(props) {
  return (
    <Grid xs={12} sm={6} md={4} xl={3} {...props}>
        {props.children}
    </Grid>
  )
}

export default SelectionGridItem;