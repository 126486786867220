import React, {
  useRef,
  useState,
  createContext,
  useEffect,
} from "react";
import styled from "@emotion/styled";
import Webcam from "react-webcam";
import axios from "axios";
import { apiBaseUrl } from "../../utilities";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { fetchImageAsBase64 } from "./fetchImageAsBase64";
import ImageComponent from "./ImageComponent";
import { useSelector } from "react-redux";
import { selectBearerToken } from "../../globalSlice";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  formControlLabel: {
    flexDirection: "column", // Stack label below the control
    margin: "8px 0", // Add some space around each control
    alignItems: "center", // Align items in the center
  },
});

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const RowAnomaly = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: 2px;
  flex: 1;
`;

const Unfold = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex: 1;
  align-items: center;
`;

const RowAnomalyPart = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
`;

const RowStart = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

const RowCenter = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

const Column = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const ZoomBackground = styled.div`
  display: ${(props) => (props.zoomLevel > 0 ? "inline-block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 400;
  opacity: 1;
`;

const Shadow = styled.div`
  position: ${(props) => (props.isMaximized ? "fixed" : "relative")};
  display: inline-block;
  top: ${(props) => (props.isMaximized ? "50%" : "auto")};
  left: ${(props) => (props.isMaximized ? "50%" : "auto")};
  transform: ${(props) =>
    props.isMaximized ? "translate(-50%, -50%)" : "none"};
  width: ${(props) => (props.isMaximized ? "100vw" : "100%")};
  height: ${(props) => (props.isMaximized ? "100vh" : "auto")};
  z-index: ${(props) => (props.isMaximized ? "500" : "1")};
  background-color: ${(props) => (props.isMaximized ? "black" : "transparent")};
  justify-content: center;
  align-items: center;
`;

const Column300 = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const ColumnCollection = styled.div`
  position: relative;
   z-index: 1500;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 50px;
`;

const WhitePaper = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255,255,255,0.15);
`;


const ColumnLiveCameraWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const ColumnLiveCameraControls = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  padding: 0 14px;
  justify-content: flex-start;
  align-items: center;
`;

const ColumnLiveCamera = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 0 14px;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
  height: 46px;
  max-height: 46px;
  min-height: 46px;
  font-size: 12px;
  font-weight: 500;
  background-color: #e53935;
  color: #ffffff;
`;

const Details = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  font-size: 16px;
  min-width: 90px;
`;

const CapturedImage = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 61px;
  height: 46px;
  max-width: 61px;
  min-width: 61px;
  max-height: 46px;

  div {
    img {
      margin-top: 6px;
    }
  }

  img {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 61px;
    height: 46px;
    max-width: 61px;
    min-width: 61px;
    max-height: 46px;
   
  }
`;

const AccordionNumber = styled.div`
  position: absolute;
  transform: translate(8px, 2px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height:  18px;
  width: 18px;
  background-color: #1d43c2;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
`;

export const WebcamCaptureContext = createContext();

const titles = [
  'RED_BLOOD_CELLS',
  'WHITE_BLOOD_CELLS',
  'PLATELETS_AND_PLASMA',
  'MICROBES',
  'PLEOMORPHIC_FROWTH_FORMS',
  'DRY_BLOOD',
];

const numbers = [39, 19, 13, 6, 25, 9];

const WebcamCapture = ({
  visitData,
  onDataReady,
  item,
  fileName,
  anomaly,
  ...props
}) => {
  const [anomalies, setAnomalies] = useState([]);
  const [selectedAnomaly, setSelectedAnomaly] = useState(null);
  const webcamRef = useRef(null);

  const [screenshotCaptured, setScreenshotCaptured] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [anomalyCollection, setAnomalyCollection] = useState([]);
  const [base64Image, setBase64Image] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [gradeValues, setGradeValues] = useState({});
  const [layerValues, setLayerValues] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [screenshotCollection, setScreenshotCollection] = useState([]);
  const [isMaximized, setIsMaximized] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDataReady = () => {
    const data = {};
    if (onDataReady && typeof onDataReady === "function") {
      onDataReady(data);
    }
  };

  // Function to cycle through zoom levels
  const toggleWebcamSize = (level) => {
    setZoomLevel(level);
  };

  // Adjust webcam style based on zoom level
  const webcamStyle = {
    position: zoomLevel > 0 ? "fixed" : "relative",
    display: "inline-block",
    top: zoomLevel > 0 ? "50%" : "auto",
    left: zoomLevel > 0 ? "50%" : "auto",
    transform: zoomLevel > 0 ? "translate(-50%,-50%)" : "none",
    width:
      zoomLevel === 1
        ? "100vw"
        : zoomLevel === 2
        ? "170vw"
        : zoomLevel === 3
        ? "200vw"
        : zoomLevel === 4
        ? "300vw"
        : zoomLevel === 5
        ? "400vw"
        : "100%",
    height:
      zoomLevel === 1
        ? "100vh"
        : zoomLevel === 2
        ? "170vh"
        : zoomLevel === 3
        ? "200vh"
        : zoomLevel === 4
        ? "300vh"
        : zoomLevel === 5
        ? "400vh"
        : "auto",
    zIndex: zoomLevel === 0 ? 1000 : "auto",
  };

  const getColumnStyle = (zoomLevel, componentType) => {
    if (zoomLevel > 0) {
      if (componentType === "liveCamera") {
        return { zIndex: 500 };
      }
      if (componentType === "columnCollection") {
        return {
          zIndex: 1001, // Adjust the z-index as needed
          position: "fixed",
          left: "30px",
          top: "100px",
        };
      }
      if (componentType === "buttonRow") {
        return {
          zIndex: 1001, // Adjust the z-index as needed
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          top: "30px",
        };
      }
      if (componentType === "column300") {
        return {
          zIndex: 1001, // Adjust the z-index as needed
          backgroundColor: "rgba(255,255,255,0.35",
        };
      }
      return { zIndex: 1001 };
    } else {
      return { zIndex: "auto", position: "relative" };
    }
  };

  const token = useSelector(selectBearerToken);

  const [anomalyData, setAnomalyData] = useState({
    anomalyName: "",
    grade: 0,
    layers: [],
    imageFileName: "",
    visitId: 0,
  });

  const postSnapshotData = async (snapshotData) => {
    try {
      await axios.post(`${apiBaseUrl}/cmn/v1/snapshots`,
        snapshotData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      if (error.response) {
        console.error(
          "Error posting snapshot data:",
          error.response.data.message
        );
      } else if (error.request) {
        console.error("No response received from server. Please try again.");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const captureScreenshot = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  const handleSaveScreenshot = async () => {
    setScreenshotCaptured(false);

    console.log("Selected Option:", selectedOption);
    console.log("Selected Grade:", selectedGrade);
    console.log("Selected Layers:", selectedLayers);
    console.log("Visit ID:", visitData.id);

    const convertedFileName = await postScreenshot(capturedImage);
    if (!convertedFileName) {
      console.error("Converted file name is undefined. Aborting...");
      return;
    }

    // Ensure all necessary data is available and valid
    if (!selectedOption || !selectedGrade || !visitData.id) {
      console.error("Required data for snapshot is missing. Aborting...");
      return;
    }

    // Prepare layers data - ensure it's an array of integers or an empty array
    const preparedLayers =
      selectedLayers.length > 0
        ? selectedLayers.map((layer) => parseInt(layer, 10))
        : [];

    const screenshotData = {
      anomalyName: selectedOption,
      grade: selectedGrade,
      layers: preparedLayers, // Use the prepared layers data
      imageFileName: convertedFileName,
      visitId: visitData.id,
    };

    await postSnapshotData(screenshotData);

    setIsDataSaved(true);

    if (props.onCapture && typeof props.onCapture === "function") {
      props.onCapture(screenshotData);
    }

    handleNewAnomalySave();
  };

  const postScreenshot = async (base64Screenshot) => {
    try {
      const formData = new FormData();
      const byteString = atob(base64Screenshot.split(",")[1]);
      const mimeString = base64Screenshot
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uintArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uintArray], { type: mimeString });
      formData.append("file", blob, "screenshot.jpg");

      const response = await axios.post(`${apiBaseUrl}/cmn/v1/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Server response:", response.data);

      return response.data.fileName;
    } catch (error) {
      if (error.response) {
        console.error("Error posting screenshot:", error.response.data.message);
      } else if (error.request) {
        console.error("No response received from server. Please try again.");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  // Fetch anomalies data
  useEffect(() => {
    const getAnomalies = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/cmn/v1/anomalies`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        // Ensure that response.data is an array before sorting and grouping
        if (Array.isArray(response.data)) {
          // Sort the anomalies alphabetically by name
          const sortedAnomalies = response.data.sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          // Group the sorted anomalies by type
          const groupedAnomalies = sortedAnomalies.reduce((acc, anomaly) => {
            (acc[anomaly.type] = acc[anomaly.type] || []).push(anomaly);
            return acc;
          }, {});

          setAnomalies(groupedAnomalies);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } catch (error) {
        if (error.response) {
          console.error('Error getting anomaly data:', error.response.data.message);
        } else if (error.request) {
          console.error('No response received from server. Please try again.');
        } else {
          console.error('Error:', error.message);
        }
      }
    };

    getAnomalies();
  }, [apiBaseUrl, token]); 
  

  const getSnapshots = async () => {
    try {
      const visitId = visitData.id;
      const response = await axios.get(`${apiBaseUrl}/cmn/v1/visits/${visitId}/snapshots`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setScreenshotCollection(response.data);
    } catch (error) {
      if (error.response) {
        console.error(
          "Error fetching screenshots:",
          error.response.data.message
        );
      } else if (error.request) {
        console.error("No response received from server. Please try again.");
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    getSnapshots();
  }, []);

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  const handleLayerChange = (event) => {
    const layer = event.target.name;
    setSelectedLayers((prev) => {
      if (event.target.checked) {
        return [...prev, layer];
      } else {
        return prev.filter((l) => l !== layer);
      }
    });
  };

  const handleListItemClick = (anomaly) => {
    // Set the new anomaly as the selected anomaly
    setSelectedAnomaly(anomaly);

    // Set the selected option
    setSelectedOption(anomaly.name); // or the appropriate property from the anomaly object

    // Reset the states for grade and layers
    setSelectedGrade(null);
    setSelectedLayers([]);

    // Reset the saved data flag
    setIsDataSaved(false);

    // Reset the captured image if needed
    setCapturedImage(null);
  };

  const classes = useStyles(); // Call this inside your component

  const renderGradeSection = () => (
    <FormControl component="fieldset">
      <RadioGroup row value={selectedGrade} onChange={handleGradeChange}>
        {[1, 2, 3, 4, 5].map((number) => (
          <FormControlLabel
            key={number}
            value={number.toString()}
            control={<Radio />}
            label={`${number}`}
            className={classes.formControlLabel}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  const renderLayerSection = () => (
    <FormControl component="fieldset">
      <Row>
        {[...Array(8)].map((_, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedLayers.includes((index + 1).toString())}
                onChange={handleLayerChange}
                name={(index + 1).toString()}
              />
            }
            label={`${index + 1}`}
            className={classes.formControlLabel} // Apply custom styles
          />
        ))}
      </Row>
    </FormControl>
  );

  // Define the new components for displaying saved values
  const SavedGrade = ({ grade }) => (
    <div className="saved-grade">
      {grade ? `${grade}` : "Grade not selected"}
    </div>
  );

  const SavedLayer = ({ layers }) => (
    <div className="saved-layers">
      {layers.length > 0 ? `${layers.join(" ")}` : ""}
    </div>
  );

  const addToAnomalyCollection = (newAnomalyData) => {
    setAnomalyCollection((prevCollection) => [
      ...prevCollection,
      newAnomalyData,
    ]);
  };

  const handleNewAnomalySave = () => {
    if (typeof anomalies !== 'object' || !anomalies.DRY_BLOOD) {
      console.error('Anomalies data structure is unexpected:', anomalies);
      return;
    }
  
    const anomalyType = 'DRY_BLOOD';
    const anomalyArray = anomalies[anomalyType];
  
    if (!Array.isArray(anomalyArray)) {
      console.error(`Anomalies.${anomalyType} is not an array:`, anomalyArray);
      return;
    }
  
    const newAnomalyData = {
      name: selectedAnomaly.name,
      grade: selectedGrade,
      layers: selectedLayers,
      image: capturedImage,
      gradeExists: selectedAnomaly.gradeExists,
      layerExists: selectedAnomaly.layerExists,
    };
  
    addToAnomalyCollection(newAnomalyData);
  
    const updatedAnomalies = anomalyArray.filter(
      (anomaly) => anomaly.id !== selectedAnomaly.id
    );
  
    setAnomalies({ ...anomalies, [anomalyType]: updatedAnomalies });
  
    resetAnomalyInput();
  };


  const resetAnomalyInput = () => {
    setCapturedImage(null);
    setSelectedGrade(null);
    setSelectedLayers([]);
  };

  useEffect(() => {
    const loadImage = async () => {
      const fetchedImage = await fetchImageAsBase64(fileName, token);
      setBase64Image(fetchedImage);
    };

    if (fileName) {
      loadImage();
    }
  }, [fileName, token]);
  
  useEffect(() => {
    const anomaliesArray = Object.entries(anomalies).flatMap(([type, anomalyList]) =>
      anomalyList.map(anomaly => ({ type, ...anomaly }))
    );
  
    const updatedAnomaliesArray = anomaliesArray.filter(
      (anomaly) =>
        !screenshotCollection.some(
          (savedAnomaly) => savedAnomaly.anomalyName === anomaly.name
        )
    );
  
    const updatedAnomalies = updatedAnomaliesArray.reduce((acc, { type, ...anomaly }) => {
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(anomaly);
      return acc;
    }, {});
  
    if (Object.keys(updatedAnomalies).length !== Object.keys(anomalies).length) {
      setAnomalies(updatedAnomalies);
    }
  }, [screenshotCollection]);

  return (
    <WebcamCaptureContext.Provider value={{ gradeValues, layerValues }}>
      <Wrapper>
        <ZoomBackground className="zoom-background" zoomLevel={zoomLevel} />
        <ColumnCollection style={getColumnStyle(zoomLevel, "columnCollection")}>
        <ul className="if-col z-i-100">
          {screenshotCollection.map((anomaly, index) => (
            <li className="if-col" key={anomaly.id}>
              <RowAnomaly>
                <Heading>{anomaly.anomalyName}</Heading>
                <Unfold>
                <RowAnomalyPart>
                    {anomaly.grade && <SavedGrade grade={anomaly.grade} />}
                    {anomaly.layers && <SavedLayer layers={anomaly.layers} />}
                  </RowAnomalyPart>
                  <CapturedImage>
                    <ImageComponent
                      imageFileName={anomaly.imageFileName}
                      onImageLoad={() => setIsImageLoaded(true)}
                    />
                    {!isImageLoaded && (
                      <img
                        src={`${apiBaseUrl}/cmn/v1/files/${anomaly.imageFileName}`}
                        alt="Captured"
                        onLoad={() => setIsImageLoaded(true)}
                        style={{ display: "none" }}
                      />
                    )}
                  </CapturedImage>
                </Unfold>
              
              </RowAnomaly>
            </li>
          ))}
      
          {anomalyCollection.map((anomalyData, index) => (
            <li key={index} className="if-col">
              <Column>
                <RowAnomaly>
                  <Heading>{anomalyData.name}</Heading>
                  <RowAnomalyPart>
                    {anomalyData.gradeExists && (
                      <SavedGrade grade={anomalyData.grade} />
                    )}
                    {anomalyData.layerExists && (
                      <SavedLayer layers={anomalyData.layers} />
                    )}
                  </RowAnomalyPart>
                  <CapturedImage>
                    {anomalyData.image && (
                      <img
                        src={anomalyData.image}
                        alt="Captured"
                        onLoad={() => setIsImageLoaded(true)}
                      />
                    )}
                  </CapturedImage>
                </RowAnomaly>
              </Column>
            </li>
          ))}
      
          {selectedAnomaly && !isDataSaved && (
            <li className="if-col">
              <Column>
                <RowAnomaly>
                  <Heading>{selectedAnomaly.name}</Heading>
                  <CapturedImage>
                    {capturedImage && (
                      <img src={capturedImage} alt="Captured Screenshot" />
                    )}
                  </CapturedImage>
                </RowAnomaly>
                {selectedAnomaly.gradeExists && (
                  <RowStart>
                    <Details>Grade:</Details>
                    {renderGradeSection()}
                  </RowStart>
                )}
                {selectedAnomaly.layerExists && (
                  <RowStart>
                    <Details>Layer:</Details>
                    {renderLayerSection()}
                  </RowStart>
                )}
              </Column>
            </li>
          )}
        </ul>
        <WhitePaper />
        </ColumnCollection>
      

        <ColumnLiveCameraWrapper>
          <ColumnLiveCameraControls
            style={getColumnStyle(zoomLevel, "buttonRow")}
          >
            <RowCenter>
              <Button
                className="margin-lr-5"
                variant="contained"
                color="primary"
                onClick={captureScreenshot}
                sx={{
                  marginX: 2,
                  ...(isMaximized
                    ? {
                        position: "fixed",
                        display: "inline-block",
                        top: "30px",
                        right: "320px",
                        zIndex: 2000,
                      }
                    : {}),
                }}
              >
                Screenshot {screenshotCaptured && "✓"}
              </Button>

              <Button
                className="margin-lr-5"
                onClick={handleSaveScreenshot}
                variant="contained"
                color="primary"
                sx={{
                  marginX: 2,
                  ...(isMaximized
                    ? {
                        position: "fixed",
                        display: "inline-block",
                        top: "30px",
                        right: "220px",
                        zIndex: 2000,
                      }
                    : {}),
                }}
              >
                Save
              </Button>

              <Button
                className={`margin-lr-5 max-w-12 min-w-12 ${
                  zoomLevel === 1 ? "button-active" : ""
                }`}
                onClick={() => toggleWebcamSize(1)}
                color="secondary"
                variant="contained"
              >
                +1
              </Button>
              <Button
                className={`margin-lr-5 max-w-12 min-w-12 ${
                  zoomLevel === 2 ? "button-active" : ""
                }`}
                onClick={() => toggleWebcamSize(2)}
                color="secondary"
                variant="contained"
              >
                +2
              </Button>
              <Button
                className={`margin-lr-5 max-w-12 min-w-12 ${
                  zoomLevel === 3 ? "button-active" : ""
                }`}
                onClick={() => toggleWebcamSize(3)}
                color="secondary"
                variant="contained"
              >
                +3
              </Button>

              <Button
                className={`margin-lr-5 max-w-12 min-w-12 ${
                  zoomLevel === 4 ? "button-active" : ""
                }`}
                onClick={() => toggleWebcamSize(4)}
                color="secondary"
                variant="contained"
              >
                +4
              </Button>

              <Button
                className={`margin-lr-5 max-w-12 min-w-12 ${
                  zoomLevel === 5 ? "button-active" : ""
                }`}
                onClick={() => toggleWebcamSize(5)}
                color="secondary"
                variant="contained"
              >
                +5
              </Button>

              <Button
                className="margin-lr-5"
                onClick={() => setZoomLevel(0)}
                variant="contained"
              >
                Minimize
              </Button>

            </RowCenter>
          </ColumnLiveCameraControls>
          <ColumnLiveCamera style={getColumnStyle(zoomLevel, "liveCamera")}>
            <Column>
              <Shadow isMaximized={isMaximized}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  style={webcamStyle}
                />
              </Shadow>
            </Column>
          </ColumnLiveCamera>
        </ColumnLiveCameraWrapper>

        <Column300
          className="list-items"
          style={getColumnStyle(zoomLevel, "column300")}
        >
         
        {titles.map((type, index) => (
          anomalies[type] && (
            <Accordion key={type} expanded={expanded === type} onChange={handleChange(type)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${type}-content`}
                id={`${type}-header`}
              >
                <Typography>
                  {type.replace(/_/g, ' ')}
                  {titles.includes(type) && (
                    <AccordionNumber>
                      <p>{numbers[titles.indexOf(type)]}</p>
                    </AccordionNumber>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {anomalies[type].map(anomaly => (
                    <ListItem
                      button
                      key={anomaly.id}
                      onClick={() => handleListItemClick(anomaly)}
                      selected={selectedAnomaly && selectedAnomaly.id === anomaly.id}
                    >
                      <ListItemText className="font-s-14" primary={anomaly.name} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          )
        ))}
      

        </Column300>
      </Wrapper>
    </WebcamCaptureContext.Provider>
  );
};

export default WebcamCapture;
