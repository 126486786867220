import React, { useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Button, Box, Alert, MenuItem } from "@mui/material";
import { CountryDropdown } from "react-country-region-selector";
import GoBackButton from "../../../Components/GoBackBtn/GoBackBtn";
import { addCompany, updateCompany } from "../../../fetchers/companies";
import { selectBearerToken } from "../../../globalSlice";
import MaterialField from "../../../Components/Formik/MaterialField";
import { FormMode } from "../../../utilities";
import styled from "@emotion/styled";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Column = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

function CompanyDetailsForm({ mode = FormMode.ADD }) {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector(selectBearerToken);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const existingCompany = location.state?.existingCompany || null;

  // Validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Required field"),
    addressLine1: Yup.string().required("Required field"),
    city: Yup.string().required("Required field"),
    province: Yup.string().required("Required field"),
    postalCode: Yup.string()
      .required("Required field")
      .length(4, "Invalid postal code. Should be 4 digits long.")
      .test("Numbers Only", "Postal code can only contain numbers", (value) =>
        /^\d+$/.test(value)
      ),
    country: Yup.string().required("Required field"),
  });

  const formik = useFormik({
    initialValues: {
      name: existingCompany ? existingCompany.name : "",
      addressLine1: existingCompany ? existingCompany.address.addressLine1 : "",
      addressLine2: existingCompany ? existingCompany.address.addressLine2 : "",
      city: existingCompany ? existingCompany.address.city : "",
      province: existingCompany ? existingCompany.address.province : "",
      postalCode: existingCompany ? existingCompany.address.postalCode : "",
      country: existingCompany ? existingCompany.country : "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => submitForm(values),
  });

  const submitForm = (values) => {
    setErrorMsg(null);

    const jsonForm = {
      name: values.name,
      address: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        province: values.province,
        postalCode: values.postalCode,
      },
      country: values.country,
      status: existingCompany ? existingCompany.status : "ACTIVE",
    };

    if (mode === FormMode.ADD) {
      addCompany(token, jsonForm)
        .then(() => {
          setShowSuccess(true);
          navigate("/companies", { replace: true });
        })
        .catch((error) => {
          setErrorMsg(error.message);
        });
    } else if (mode === FormMode.EDIT && existingCompany) {
      updateCompany(token, companyId, jsonForm)
        .then(() => {
          setShowSuccess(true);
          navigate("/companies", { replace: true });
        })
        .catch((error) => {
          setErrorMsg(error.message);
        });
    }
  };

  return (
    <Root>
      <form onSubmit={formik.handleSubmit}>
        <GoBackButton sx={{ marginX: 3, marginY: 2 }} />
        <Wrapper>
          <MidContainer>
            <Column>
              <MaterialField
                formik={formik}
                name="name"
                label="*Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <MaterialField
                formik={formik}
                name="addressLine1"
                label="*Address Line 1"
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.addressLine1 && Boolean(formik.errors.addressLine1)}
                helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
              />

              <MaterialField
                formik={formik}
                name="addressLine2"
                label="Address Line 2"
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.addressLine2 && Boolean(formik.errors.addressLine2)}
                helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
              />

              <MaterialField
                formik={formik}
                name="city"
                label="*City"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />

              <MaterialField
                formik={formik}
                name="province"
                label="*Province / State"
                value={formik.values.province}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.province && Boolean(formik.errors.province)}
                helperText={formik.touched.province && formik.errors.province}
              />

              <MaterialField
                formik={formik}
                name="postalCode"
                label="*Postal / Zip Code"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                helperText={formik.touched.postalCode && formik.errors.postalCode}
              />

              {/* Integrate CountryDropdown with Formik */}
              <CountryDropdown
                value={formik.values.country}
                onChange={(val) => formik.setFieldValue('country', val)}
                onBlur={formik.handleBlur}
                defaultOptionLabel="Country"
                classes="country-dropdown"
              />
              {formik.touched.country && formik.errors.country && (
                <div style={{ color: "red", marginTop: "8px" }}>
                  {formik.errors.country}
                </div>
              )}
            </Column>

            <Column>
              {showSuccess && (
                <Alert severity="success">Company saved successfully!</Alert>
              )}
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

              <Box sx={{ display: "flex", justifyContent: "flex-end", my: 3 }}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </Box>
            </Column>
          </MidContainer>
        </Wrapper>
      </form>
    </Root>
  );
}

export default CompanyDetailsForm;
