import React from 'react';
import styled from "@emotion/styled";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px 25px;;
  border-radius: 8px;
  max-width: 400px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ModalHeading = styled.div`
  position: relative;
  z-index: 100;
  color: rgba(16, 24, 40, 1);
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const ModalBody = styled.div`
  position: relative;
  z-index: 100;
  color: rgba(71, 84, 103, 1);
  font-size: 14px;
  margin-bottom: 20px;

  .strong {
    font-weight: 700;
  }
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  background-color: #252552;
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
`;

const ModalRow = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
`;

const ModalColumn = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ModalButtonCancel = styled.button`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 45px;
  border-radius: 8px;
  cursor: pointer;
  color: #344054;
  background-color: white;
  border: 1px solid #D0D5DD;
  width: 100%;
  max-width: 170px;
`;

const ModalIconCancel = styled.button`
  cursor: pointer;
  background-color: white;
  width: 25px;
  height: 25px;
  border: none;
`;

const ModalButtonDelete = styled.button`
  font-size: 14px;
  font-weight: 600;
  padding: 10px 45px;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  background-color: #D92D20;
  border: 1px solid #D92D20;
  width: 100%;
  max-width: 170px;
`;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FEE4E2;

  :before {
    content: "";
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 1px solid #f4f4f4;
  }

  :after {
    content: "";
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 144px;
    height: 144px;
    border-radius: 50%;
    border: 1px solid #f4f4f4;
  }
`;

const CornerClose = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  transform: rotate(45deg);

  .line-horizontal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 2px;
    background-color: #98A2B3;
  }

  .line-vertical {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2px;
    height: 18px;
    background-color: #98A2B3;
  }
`;

const ConfirmationModal = ({ isOpen, title, body, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalRow>
          <IconWrapper>
            <DeleteOutlineIcon sx={{ color: 'red' }}/>
          </IconWrapper>
          <CornerClose>
          <ModalIconCancel onClick={onCancel}>
            <span className="line-horizontal"> </span>
            <span className="line-vertical"> </span>
          </ModalIconCancel>   
          </CornerClose>
        </ModalRow>
        <ModalColumn>
          <ModalHeading>{title}</ModalHeading>
          <ModalBody>{body}</ModalBody>
        </ModalColumn>
        <ModalRow>
          <ModalButtonCancel onClick={onCancel}>Cancel</ModalButtonCancel>
          <ModalButtonDelete onClick={onConfirm}>Delete</ModalButtonDelete>
        </ModalRow>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
