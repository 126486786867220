import React from 'react';
import BloodCamera from './BloodCamera/BloodCamera';
import DigitalPathologyAnalysisHeader from './DigitalPathologyAnalysisHeader/DigitalPathologyAnalysisHeader';
import Divider from '@mui/material/Divider';
import Login from './Login/Login';
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const Heading = styled.h2`
  position: relative;
  display: inline-flex;
  font-size: 28px;
  font-weight: 400;
  margin: 20px 0;
  color: #333366;
`;

const SubHeading = styled.h2`
  position: relative;
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  margin: 20px 0 30px;
  background-color: #333366;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
`;

function PatternLibrary() {
  return (
    <Wrapper>
      <Heading>Pattern Library</Heading>
      <SubHeading>Login Screen</SubHeading>
      <Login/>

      <SubHeading>Digital Pathology Analysis</SubHeading>
      <Divider sx={{ marginY: 4 }} />
      <DigitalPathologyAnalysisHeader />
      <BloodCamera />
      
    </Wrapper>
  )
}

export default PatternLibrary;