import React from "react";
import Typography from '@mui/material/Typography';
import styled from "@emotion/styled";
import PracticeGrid from "../../Containers/PracticeGrid/PracticeGrid";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 10px;
`;

const SubHeading = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
  color: #000000;
  margin-bottom: 20px;
`;

function CompanyPracticeDetail({ practiceDetails }) {
  const hasData = practiceDetails.content.length >= 0;
  return (
    <>
      <SubHeading>
        Practices
      </SubHeading>
       
      <Wrapper>
      { hasData ? (
        <PracticeGrid data={practiceDetails}/>
      ) : (
        <Typography variant="h4">No Practices to display</Typography>
      )}
      </Wrapper>
       
    </>
  );
}

export default CompanyPracticeDetail;
