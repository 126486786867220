import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import { useQueries } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { deletePatient } from "../../fetchers/patients";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { selectBearerToken } from "../../globalSlice";
import PatientDetail from "../../Components/PatientDetails/PatientDetail";
import { getPatientDetailsById } from "../../fetchers/patientDetails";
import { visitByPatientDetailsId } from "../../fetchers/visits";
import PatientVisits from "../../Components/PatientDetails/PatientVisits";
import ConfirmationModal from "../../Components/ConfirmationModal/ConfirmationModal"; // Updated path

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PageHeader = styled.h4`
  padding: 15px 20px;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #333366;
  background-color: #ffffff;
  border-bottom: 2px solid #333366;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 20px;
  padding: 0 25px;
`;

function PatientDetailsPage({ isGoBackable = true }) {
  const queryClient = useQueryClient();
  const { patientDetailsId } = useParams();
  const token = useSelector(selectBearerToken);
  const navigate = useNavigate();

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleDialogOpen = () => setOpenConfirmationDialog(true);
  const handleDialogClose = () => setOpenConfirmationDialog(false);

  const handleDeleteConfirm = () => {
    deletePatient(token, patientDetailsId)
      .then(() => {
        if (isMounted.current) {
          navigate("/patientManagement", { replace: true });
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          console.error("Failed to delete patient:", error);
        }
      });
  };

  const results = useQueries({
    queries: [
      {
        queryKey: ["patientDetailsById", patientDetailsId],
        queryFn: () => getPatientDetailsById(token, patientDetailsId),
      },
      {
        queryKey: ["visitsByPatientDetailsId", patientDetailsId],
        queryFn: () => visitByPatientDetailsId(token, patientDetailsId),
      },
    ],
  });

  const refetchVisits = () => {
    queryClient.invalidateQueries(["visitsByPatientDetailsId", patientDetailsId]);
  };

  const isLoading = results.some(query => query.isLoading);
  const isError = results.some(query => query.isError);

  if (isLoading) return <CircularProgress />;
  if (isError) return <Alert severity="error">Error loading patient details or visits.</Alert>;

  const patientDetails = results[0]?.data;
  const visitsData = results[1]?.data;

  const handleEditPatient = () => {
    navigate("/patientManagement/add", { state: { mode: "EDIT", existingPatient: patientDetails } });
  };

  return (
    <Root>
      <PageHeader>Client Details</PageHeader>
      {isGoBackable && <GoBackButton />}
      <Box sx={{ width: "100%", marginY: 2, p: 1 }}>
        <PatientDetail patientDetails={patientDetails} />
      </Box>
      <Row>
        <Button className="margin-r-10" variant="contained" color="secondary" onClick={handleEditPatient}>Edit Client</Button>
        <Button variant="contained" color="primary" onClick={handleDialogOpen}>Remove Client</Button>
      </Row>

      <Box sx={{ width: "100%", marginTop: 2 }}>
        {patientDetails && (
          <PatientVisits
            data={visitsData}
            name={patientDetails.name}
            patientId={patientDetailsId}
            refreshVisits={refetchVisits}
          />
        )}
      </Box>

      <ConfirmationModal
        isOpen={openConfirmationDialog}
        title="Confirm Delete Client"
        body="Are you sure you want to remove this client? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={handleDialogClose}
      />
    </Root>
  );
}

export default PatientDetailsPage;
