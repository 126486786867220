import { fakeLogOut } from '../globalSlice';
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import BusinessIcon from '@mui/icons-material/Business';
import { MonitorHeart } from '@mui/icons-material';

export const AccessGroups = { 
  ADMIN: 'ADMIN',
  BOUSER: 'BACK_OFFICE_USER',
  CADMIN: 'COMPANY_ADMIN',
  PRAC_USER: 'PRACTICE_USER' 
};

export const { 
  ADMIN, 
  BOUSER, 
  CADMIN, 
  PRAC_USER 
} = AccessGroups;

export const ALL_GROUPS = [
  ADMIN, 
  BOUSER, 
  CADMIN, 
  PRAC_USER
];

const headerPages = [
  {
    groups: [ADMIN, PRAC_USER],
    value: {
      name: 'Clients',
      icon: <SensorOccupiedIcon style={{ fill: "black", fontSize: 28 }} />,
      link: '/patientManagement',
    },
  },
  {
    groups: [ADMIN],
    value: {
      name: 'Visits',
      icon: <MonitorHeart style={{ fill: "black", fontSize: 28 }} />,
      link: '/visits',
    },
  },
  {
    groups: [ADMIN],
    value: {
      name: 'Companies',
      icon: <BusinessIcon style={{ fill: "black", fontSize: 28 }} />,
      link: '/companies',
    },
  },
];

const headerUserLinks = [
  {
    groups: [PRAC_USER],
    value: {
      name: 'Profile',
      link: '/profile',
    },
  },
  {
    groups: [CADMIN],
    value: {
      name: 'Company',
      link: '/company',
    },
  },
  {
    groups: [ADMIN, PRAC_USER],
    value: {
      name: 'Logout',
      dispatch: fakeLogOut,
    },
  }
];
const quickAccessPages = [
  {
    groups: [ADMIN],
    value: {
      name: 'Visits',
      icon: <SensorOccupiedIcon style={{ fill: "black" }} />,
      link: '/visits',
    },
  },
  {
    groups: [ADMIN], //TODO: double check rights
    value: {
      name: 'Companies',
      icon: <BusinessIcon style={{ fill: "black" }} />,
      link: '/companies',
    },
  },
  {
    groups: [ADMIN, PRAC_USER],
    value: {
      name: 'Clients',
      icon: <SensorOccupiedIcon />,
      link: '/patients',
    },
  },
  {
    groups: [ADMIN, BOUSER, CADMIN, PRAC_USER],
    value: {
      name: 'Anomalies',
      icon: <SensorOccupiedIcon />,
      link: '/anomalies',
    },
  },
  {
    groups: [ADMIN, BOUSER],
    value: {
      name: 'Reports',
      icon: <SensorOccupiedIcon />,
      link: '/reports',
    },
  },
];

function filterForAccessGroup(filterGroup, filterArr) {
  return filterArr.filter(({ groups }) => groups.includes(filterGroup)).map(({ value }) => value)
}

function createAccessGroupsObject(settings) {
  const accessGroupObj = {};
  Object.values(AccessGroups).forEach((accessGroup) => {
    accessGroupObj[accessGroup] = filterForAccessGroup(accessGroup, settings);
  });
  return accessGroupObj;
}

export const headerAccessSettings = {
  pages: createAccessGroupsObject(headerPages),
  userLinks: createAccessGroupsObject(headerUserLinks),
}

export const quickAccessLinks = createAccessGroupsObject(quickAccessPages);