import React from "react";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";

function PatientGrid({ data, isAdmin }) {
  const rows = data?.map((patient) => ({
    id: patient.id,
    firstName: patient.name || "No Name",
    lastName: patient.surname || "No Surname",
    gender: patient.gender || "Unknown",
    company: patient.company?.name || "No Company",
    visits: patient.id,
    createdDate: patient.createdDate || "N/A",
    dateOfBirth: patient.dateOfBirth || "N/A",
    email: patient.contactDetails?.email || "No Email",
    cellphoneNumber: patient.contactDetails?.cellphoneNumber || "No Cellphone",
    medicalProvider: patient.medicalDetails?.provider || "No Provider",
  })) || [];

  const sortedRows = rows.sort((a, b) => b.id - a.id);

  const columns = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    {
      field: "visits",
      headerName: "Client Details",
      width: 140,
      renderCell: (params) => (
        <Link to={`/patient/${params.value}`}>View Details</Link>
      ),
    },
    { field: "company", headerName: "Company", width: 200 },
    { field: "gender", headerName: "Gender", width: 120 },
    { field: "createdDate", headerName: "Date Added", width: 180 },
    { field: "dateOfBirth", headerName: "Date of Birth", width: 180 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "cellphoneNumber", headerName: "Cellphone", width: 180 },
    ...(isAdmin
      ? [{ field: "medicalProvider", headerName: "Medical Provider", width: 200 }]
      : []),
    
  ];

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={sortedRows}
        columns={columns}
        pageSize={12}
        rowsPerPageOptions={[12]}
      />
    </Box>
  );
}

export default PatientGrid;
