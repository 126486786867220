import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectBearerToken } from '../../globalSlice';
import { apiBaseUrl } from "../../utilities";

function ImageComponent({ imageFileName, onImageLoad }) {
  const [base64Image, setBase64Image] = useState(null);
  const token = useSelector(selectBearerToken);

  useEffect(() => {
    const fetchImageAsBase64 = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/opn/v1/files?fileName=${imageFileName}`, {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token}` },
        });

        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
          onImageLoad(reader.result);
        };
        reader.readAsDataURL(response.data);
      } catch (error) {
        console.error("Error fetching image:", error);
        onImageLoad(null);
      }
    };

    if (imageFileName) {
      fetchImageAsBase64();
    }
  }, [imageFileName, token, onImageLoad]);

  return (
    <div>
      {base64Image ? <img src={base64Image} alt="Anomaly" /> : <div className="loader"> </div>}
    </div>
  );
}

export default ImageComponent;
