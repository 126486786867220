import axios from "axios";

import { apiBaseUrl } from "../utilities";

export const uploadFile = (token, data) =>
  axios.post(`${apiBaseUrl}/cmn/v1/files`, data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
