import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { getMyPracticeDetails } from "../../api/practiceUserApi";
import { selectUserGroup, selectBearerToken } from "../../globalSlice";
import { getPracticeUsersByPracticeId, deletePracticeUser } from "../../api/practiceUserApi";
import ConfirmationModal from "../../Components/ConfirmationModal/ConfirmationModal";
import styled from "@emotion/styled";

const DeleteButton = styled.button`
  font-size: 15px;
  padding: 10px 15px;
  border-radius: 8px;
  color: #000000;
  font-weight: 600;
  background-color: #ffffff;
  border: 2px solid #9393CB;
  cursor: pointer;
  margin: 0;
`;

function PracticeUsersGrid() {
  const { practiceId } = useParams();
  const token = useSelector(selectBearerToken);
  const userGroup = useSelector(selectUserGroup);
  const [companyId, setCompanyId] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [practiceUsers, setPracticeUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    // Check if the user is a Practice User
    if (userGroup === "PRACTICE_USER") {
      getMyPracticeDetails(token)
        .then((data) => {
          setCompanyId(data.companyId);
        })
        .catch((error) => {
          console.error("Failed to fetch practice details:", error);
          setErrorMsg("Failed to load practice details.");
        });
    }
  }, [token, userGroup]);
  

  useEffect(() => {
    const fetchPracticeUsers = async () => {
      try {
        const response = await getPracticeUsersByPracticeId(token, practiceId);
        setPracticeUsers(response);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPracticeUsers();
  }, [practiceId, token]);

  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedUserId(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deletePracticeUser(token, practiceId, selectedUserId);
      setPracticeUsers((prevUsers) => prevUsers.filter(user => user.id !== selectedUserId));
      handleCloseDeleteModal();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  

  const columns = [
    { field: "name", headerName: "Name", width: 230 },
    { field: "username", headerName: "Username", width: 230 },
    { field: "email", headerName: "E-mail", width: 280 },
    { field: "group", headerName: "Group", width: 230 },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <>
        {userGroup !== "PRACTICE_USER" && (
          <DeleteButton onClick={() => handleDeleteClick(params.row.id)}>
            Delete
          </DeleteButton>
        )}
         
        </>
      ),
    },
  ];

  const rows = practiceUsers.map((user) => ({
    id: user.id,
    name: user.name,
    username: user.username,
    email: user.email,
    group: user.group,
  }));

  return (
    <>
      <Box sx={{ height: 400, width: "100%", backgroundColor: "common.white" }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
      </Box>

      <ConfirmationModal
        isOpen={showDeleteModal}
        title="Delete Practice User"
        body="Are you sure you want to delete this user? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={handleCloseDeleteModal}
      />
    </>
  );
}

export default PracticeUsersGrid;
