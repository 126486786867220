import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createPractice, updatePractice, getPracticeDetailsById } from '../../api/practiceApi';
import { selectBearerToken } from '../../globalSlice';
import PracticeForm from '../PracticeForm/PracticeForm';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function PracticeDetailsForm({ mode }) {
  const { companyId, practiceId } = useParams();
  const token = useSelector(selectBearerToken);
  const navigate = useNavigate();
  
  const [practiceData, setPracticeData] = useState({
    name: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      postalCode: '',
    },
    status: 'ACTIVE',
    companyId: companyId || null,
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (practiceId && mode === 'edit') {
      const fetchPracticeDetails = async () => {
        setLoading(true);
        try {
          const fetchedPractice = await getPracticeDetailsById(token, practiceId);
          setPracticeData(fetchedPractice);
        } catch (error) {
          setError('Error fetching practice details');
        } finally {
          setLoading(false);
        }
      };
      fetchPracticeDetails();
    }
  }, [practiceId, token, mode]);

  const handleSubmit = async () => {
    try {
      if (mode === 'edit' && practiceId) {
        await updatePractice(token, practiceId, practiceData);
        console.log('Practice updated successfully');
        navigate(`/practices/${practiceId}`);
      } else {
        await createPractice(token, practiceData);
        console.log('Practice created successfully');
        navigate(`/companies/${companyId}`);
      }
    } catch (error) {
      setError(`Error while ${mode === 'edit' ? 'updating' : 'creating'} practice`);
      console.error(error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <PracticeForm
      practiceData={practiceData}
      setPracticeData={setPracticeData}
      handleSubmit={handleSubmit}
    />
  );
}

export default PracticeDetailsForm;
