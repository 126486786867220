import React from 'react';
import styled from "@emotion/styled";
import slide from "./slide.png";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const Header = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 20px 30px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dddddd;
  border-radius: 14px 14px 0 0;
  background-color: #ffffff;
`;

const RowBlock = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
`;

const Heading = styled.h2`
  position: relative;
  display: inline-flex;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const ButtonBorder = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #333366;
  border-radius: 6px;
  font-weight: 500;
  margin-left: 15px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
`;

const ButtonSolidMaximize = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: #333366;
  margin-left: 15px;

  &:before {
    position: absolute;
    top: 43%;
    left: 43%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '⟷';
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
  }
`;

const ButtonSolidPlus = styled.div`
  position: absolute;
  z-index: 1200;
  top: 10px;
  right: 10px;
  display: inline-flex;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: #333366;
  margin-left: 15px;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '+';
    color: #ffffff;
    font-weight: 400;
    font-size: 28px;
  }
`;

const ButtonCamera = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: #333366;
  margin-left: 15px;

  &:before {
    position: absolute;
    top: 20%;
    left: 42.5%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '⊛';
    color: #ffffff;
    font-weight: 500;
    font-size: 38px;
    width: 40px;
    height: 40px;
  }
`;

const Content = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  padding: 0;
  border: 1px solid #dddddd;
  border-radius: 0 0 14px 14px;
  background-color: white;
  height: 100%;
`;

const RangeSlider = styled.div`
    position: relative;
    min-height: 346px;
    width: 60px;
    display: inline-flex;
    align-items: center;

    input {
      position: absolute;
      z-index: 1200;
      width: calc(100% + 250px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }
`;

const ScrollSlider = styled.div`
    position: relative;
    min-height: 346px;
    width: 60px;
    display: inline-flex;
    align-items: center;
    background-color: #EAECF0;
    border-radius: 0 0 14px 0;

    input {

    }
`;

const Slide = styled.div`
  position: relative;
  z-index: 1000;
  display: inline-flex;
  width: 100%;
  align-items: center;
  max-height: 346px;
  overflow: hidden;

  img {
    position: relative;
    display: inline-flex;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
  }
`;

const ButtonRow = styled.div`
    position: relative;
    z-index: 1200;
    bottom: 0;
    left: -50%;
    transform: translate(-50%, 300%);
    min-width: 310px;
    height: 44px;
    background-color: #F2F4F7;
    border-radius: 25px;
    display: inline-flex;
    align-items: center;
`;

const Block = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 44px;
    width: 44px;
`;

const SlideButtonContainer = styled.div`
    position: relative;
    height: 44px;
    width: 44px;
    display: inline-flex;
    align-items: center;
`;

const SlideButton = styled.div`
    position: absolute;
    z-index: 1200;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 1px solid #333366;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    
    span {
      width: 100%;
      font-size: 9px;
      text-align: center;
      font-weight: 600;
    }
`;

function BloodCamera() {
  return (
    <Wrapper>
      <Header>

      <RowBlock>
        <Heading>
          Adrenal Stress - Week 4 Testing
        </Heading>
      </RowBlock>

      <RowBlock>

        <ButtonBorder>
          Edit Slide
        </ButtonBorder>

        <ButtonBorder>
          Save
        </ButtonBorder>

        <ButtonSolidMaximize />
        
      </RowBlock>

      </Header>
      <Content>
        <RangeSlider>
        <input type="range" id="volume" name="volume" min="0" max="16" />
        </RangeSlider>
        <Slide>
          <img src={slide} alt="sample" />
          <ButtonSolidPlus/>
          <ButtonRow>
          <Block>
              <SlideButtonContainer>
                <SlideButton>
                <span>
                1X
                </span>
                  
                </SlideButton>
              </SlideButtonContainer>
              </Block>
            
            <Block>
            <SlideButtonContainer>
            <SlideButton>
            <span>
            2X
            </span>
            </SlideButton>
          </SlideButtonContainer>
            </Block>
            <Block>
            <SlideButtonContainer>
            <SlideButton>
            <span>
            4X
            </span>
            </SlideButton>
          </SlideButtonContainer>
            </Block>
            <Block>
            <SlideButtonContainer>
            <SlideButton className="slide-button-active">
            <span>
            10X
            </span>
            </SlideButton>
          </SlideButtonContainer>
            </Block>
            <Block>
            <SlideButtonContainer>
            <SlideButton>
            <span>
            40X
            </span>
            </SlideButton>
          </SlideButtonContainer>
            </Block>
            <Block>
            <SlideButtonContainer>
            <SlideButton>
            <span>
            100X
            </span>
            </SlideButton>
          </SlideButtonContainer>
            </Block>
            <Block>
            <SlideButtonContainer>
            <SlideButton>
            <span>
            400X
            </span>
            </SlideButton>
          </SlideButtonContainer>
            </Block>

            <Block>
            <SlideButtonContainer>
              <ButtonCamera>
              
              </ButtonCamera>
            </SlideButtonContainer>
            </Block>

          </ButtonRow>
        </Slide>
        <ScrollSlider>
        
        </ScrollSlider>
        </Content>

    </Wrapper>
  )
}

export default BloodCamera;