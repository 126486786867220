import React, { useState, useEffect, useCallback, useRef } from "react";

import Webcam from "react-webcam";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Tooltip,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

function SnapshotCamera({ isReadyForSnapshots, handleCapture }) {
  const [deviceId, setDeviceId] = useState("");
  const [isDeviceReady, setIsDeviceReady] = useState(false);
  const [devices, setDevices] = useState([]);
  const [isMoreVisible, setIsMoreVisible] = useState(false);

  const webcamRef = useRef(null);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const videoDevices = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      setDevices(videoDevices);
      if (videoDevices.length) setDeviceId(videoDevices[0].deviceId);
    },
    [setDevices]
  );

  const updateDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) handleCapture(imageSrc);
  }, [webcamRef, handleCapture]);

  const handleSetDevice = (e) => {
    setDeviceId(e.target.value);
    setIsMoreVisible(false);
  };

  const handleToggleIsMoreVisible = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  useEffect(async () => {
    updateDevices();
    navigator.mediaDevices.ondevicechange = () => {
      updateDevices();
    };
  }, [handleDevices]);

  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Box sx={{ position: "relative" }}>
        <Webcam
          key={`webcam_${deviceId}`}
          height="100%"
          audio={false}
          videoConstraints={{ deviceId }}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          onUserMedia={() => {
            setIsDeviceReady(true);
          }}
        ></Webcam>
        <Stack
          sx={{ position: "absolute", top: 16, right: 16 }}
          spacing={1}
          alignItems="center"
        >
          <Tooltip title={isMoreVisible ? 'Show Less' : 'Show More'}>
            <Fab
              size="small"
              color="grey"
              aria-label={isMoreVisible ? 'Show Less' : 'Show More'}
              onClick={handleToggleIsMoreVisible}
            >
              <MoreHorizIcon />
            </Fab>
          </Tooltip>
          <Tooltip title="Take Snapshot">
            {/* Need to add div wrapper because tooltip cannot use the FAB when it's in a disabled state. Throws error */}
            <div>
              <Fab
                color="primary"
                aria-label="Take Snapshot"
                onClick={capture}
                disabled={!isReadyForSnapshots || !isDeviceReady}
                sx={{
                  transition: "transform ease-in-out 0.1s",
                  transform: "scale(1)",
                  ":active": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <PhotoCameraIcon />
              </Fab>
            </div>
          </Tooltip>
        </Stack>
      </Box>
      <Collapse orientation="horizontal" in={isMoreVisible}>
        <Stack direction="column" height="100%">
          <FormControl sx={{ m: 1 }}>
            <InputLabel id="camera-select-label">Camera</InputLabel>
            <Select
              labelId="camera-select-label"
              id="camera-select"
              value={deviceId}
              label="Camera"
              onChange={handleSetDevice}
            >
              {devices.map(({ deviceId, label, groupId }) => (
                <MenuItem key={`device_item_${deviceId}`} value={deviceId}>
                  {label ?? groupId}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Camera to use for snapshot</FormHelperText>
          </FormControl>
        </Stack>
      </Collapse>
    </Box>
  );
}

export default SnapshotCamera;
