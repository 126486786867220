import React from "react";
import { TextField } from "@mui/material";

function MaterialTextarea({ formik, name, label, children, ...props }) {
  return (
    <TextField
      multiline
      rows={5}
      fullWidth
      margin="normal"
      id={name}
      name={name}
      label={label}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...props}
    >
      {children}
    </TextField>
  );
}

export default MaterialTextarea;
