import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import VisitCard from "./VisitCard";

import { selectBearerToken } from "../../globalSlice";
import { visitByPracticeId } from "../../fetchers/visits";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";


function VisitSelectionByPracticeId({ linkBase }) {
  const { practiceId } = useParams();

  const token = useSelector(selectBearerToken);

  const { data, isLoading, isError } = useQuery({
    queryKey: ["visitsByPracticeId", practiceId],
    queryFn: () => visitByPracticeId(token, practiceId),
    retry: false,
  });

  const fullHeightWrapper = useCallback((element) => (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      height="calc(100vh - 80px)"
    >
      {element}
      <GoBackButton />
    </Stack>
  ));

  if (isLoading) {
    return fullHeightWrapper(<CircularProgress />);
  }

  if (isError) {
    return fullHeightWrapper(
      <Alert severity="error">An error occurred.</Alert>
    );
  }

  if (data?.length === 0) {
    return fullHeightWrapper(
      <Alert severity="warning">There are no visits for this patient</Alert>
    );
  }

  return (
    <>
      <GoBackButton sx={{ marginX: 3, marginY: 2 }} />
      <Typography textAlign="center" variant="h3">
        Visits
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Chip
          color="info"
          label={
            <Typography
              textAlign="center"
              fontSize={20}
              color="common.white"
            >
              {data?.content.length}
            </Typography>
          }
        />
      </Box>

      <Grid container spacing={2} marginX={2}>
        {data?.content.map((visit) => (
          <Grid key={`visit_card_${visit.id}`} xs={6} md={4} lg={3}>
            <VisitCard {...visit} linkBase={linkBase} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default VisitSelectionByPracticeId;
