import React from 'react';
import { TextField, Button, Box } from '@mui/material';
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const MidWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 768px;
  padding: 0 20px;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;

function PracticeForm({ practiceData, setPracticeData, handleSubmit }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPracticeData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setPracticeData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [name]: value,
      },
    }));
  };

  return (
    <Wrapper>
    <GoBackButton />
      <MidWrapper>
      <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
      <TextField
        name="name"
        label="Practice Name"
        value={practiceData.name}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        name="addressLine1"
        label="Address Line 1"
        value={practiceData.address.addressLine1}
        onChange={handleAddressChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        name="addressLine2"
        label="Address Line 2"
        value={practiceData.address.addressLine2}
        onChange={handleAddressChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="city"
        label="City"
        value={practiceData.address.city}
        onChange={handleAddressChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        name="province"
        label="Province"
        value={practiceData.address.province}
        onChange={handleAddressChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        name="postalCode"
        label="Postal Code"
        value={practiceData.address.postalCode}
        onChange={handleAddressChange}
        required
        fullWidth
        margin="normal"
      />

      <Row>
        <Button variant="contained" color="primary" type="submit">
        Save Practice
        </Button>
      </Row>
    </Box>
    </MidWrapper>
    </Wrapper>
  );
}

export default PracticeForm;
