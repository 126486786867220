import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import AddVisitForm from "./AddVisitForm";
import { selectBearerToken } from "../../globalSlice";
import PatientDetails from "../../Components/PatientDetails/PatientDetails";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { getPatientDetailsById } from "../../fetchers/patientDetails";

function AddVisitPage() {
  const { patientDetailsId } = useParams();
  const token = useSelector(selectBearerToken);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["patientDetailsById", patientDetailsId],
    queryFn: () => getPatientDetailsById(token, patientDetailsId),
  });

  return (
    <Box>
      <GoBackButton sx={{ m: 2 }} />
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        {isLoading && <CircularProgress />}
        {isError && <div>Error: {error.message}</div>}
        {data && (
          <Box
            sx={{
              width: { xs: "100%", sm: "75%", md: "50%", lg: "33%", xl: "25%" },
            }}
          >
            <PatientDetails patientDetails={data} />
          </Box>
        )}
      </Box>
      <Box sx={{ p: 2 }}>
        <AddVisitForm patientDetailsId={patientDetailsId} autoSubmit={true} />
      </Box>
    </Box>
  );
}

export default AddVisitPage;
