import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useParams } from 'react-router-dom';
import { selectBearerToken } from "../../globalSlice";
import { DataGrid } from "@mui/x-data-grid";
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel } from "victory";
import GenerateExpandedReport from "../GenerateExpandedReport/GenerateExpandedReport";
import GenerateConciseReport from "../GenerateConciseReport/GenerateConciseReport";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { apiBaseUrl } from "../../utilities";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
  font-size: 11px;
  font-weight: 600;
  padding: 0 20px;
`;

const ChartWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
`;

const GraphWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 20px 0;
  padding: 30px 0;
  background-color: #ffffff;
`;

const HiddenWrapper = styled.div`
  position: relative;
  visibility: hidden;
  height: 1px;
  width: 100%;
`;

const Heading = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
`;

// converted layout

const Row = styled.div`
  &:nth-of-type(even) {
    background-color: #ffffff;
  }
  position: relative;
  display: inline-flex;
  width: 100%;
  background-color: #F2F4F7;
  border-top: 1px solid #D0D5DD;
`;

const StartColumnHeader = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #F2F4F7 !important;
  padding: 20px 10px;
`;

const StartColumn = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 5px;
`;

const StartColumnChip = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #334054;
  padding: 3px;
  border-radius: 20px;
  margin: 2px;
`;

const StartColumnChipMedicalConsiderations = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #334054;
  padding: 3px;
  border-radius: 20px;
  margin: 2px;
`;

const StartColumnChipType = styled.div`
  position: relative;
  display: inline-flex;
  background-color: #ffffff;
  padding: 0 10px;
  border-radius: 20px;
`;

const StartColumnChipTypeLabel = styled.div`
  position: relative;
  display: inline-flex;
  padding: 0 10px;
  color: #ffffff;
`;

const StartColumnChipDetails = styled.div`
  position: relative;
  display: inline-flex;
  background-color: #ffffff;
  padding: 0 10px;
  border-radius: 20px;
`;

const StartColumnChipDetailsLabel = styled.div`
  position: relative;
  display: inline-flex;
  padding: 0 10px;
  color: #ffffff;
`;

const YesColumnHeader = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #F2F4F7 !important;
  padding: 20px 10px;
`;

const YesColumn = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 5px;
`;

const YesColumnChip = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D0D5DD;
  padding: 3px;
  border-radius: 20px;
  margin: 2px;
  max-width: 300px;
  max-height: 26px;
`;

const YesColumnBlock = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

const YesColumnChipLabel = styled.div`
  position: relative;
  display: inline-flex;
  padding: 0 10px;
`;

const YesColumnChipGrade = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: #ffffff !important;
  color: #5EA6F4 !important;
  padding: 1px 8px;
  margin: 0;
  border-radius: 20px;
`;

const YesColumnChipLayer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: #2E90FA;
  color: #ffffff;
  padding: 1px 8px;
  margin-left: 4px;
  border-radius: 20px;
`;

const EndColumnHeader = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F2F4F7 !important;
  padding: 20px 10px;
  max-width: 165px;
`;

const EndColumn = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  max-width: 165px;
`;

const EndColumnChip = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
`;

const EndColumnChipInner = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  background-color: #379D07;
  color: #ffffff;
  padding: 3px 12px;
  border-radius: 18px;
  font-size: 20px;
`;

// Header

const Header = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
`;

const HeaderBlock = styled.div`
  position: relative;
  display: inline-flex;
  margin: 5px 0 0 0;
  width: 100%;
`;

const HeaderBlockEnd = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  margin: 5px 0 0 5px;
  width: 100%;
`;


// A Material data grid is generated
// An anomaly chart is then generated based on the data grid
// Two bar graphs are generated based on the data grid
// The data grid is hidden
// The anomaly chart remains visible

export default function VisualizationPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [anomaliesData, setAnomaliesData] = useState([]);
  const token = useSelector(selectBearerToken);
  const { visitId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const patientDetailsId = searchParams.get('patientDetailsId');
  const date = searchParams.get('date');

  const urlParams = new URLSearchParams(location.search);
  const displayId = urlParams.get("displayId");

  useEffect(() => {
    const getSnapshots = async () => {
      if (!visitId) {
        console.error("Visit ID is not provided");
        return;
      }
  
      try {
        const response = await axios.get(`${apiBaseUrl}/cmn/v1/visits/${visitId}/snapshots`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
        transformAndSetData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    getSnapshots();
  }, [visitId, token]);
  
  const sortRowsByScore = (rows) => {
    return rows.map(row => {
      // Calculate the combined score
      const combinedScore = row.implications + row.medicalConsiderations;
      return { ...row, score: combinedScore };
    }).sort((a, b) => b.score - a.score); // Sort in descending order
  };
  
  const transformAndSetData = (apiData) => {
    const idMap = new Map();
    const transformedData = apiData.map((snapshot, index) => {
      // Check for duplicate anomalyId
      if (idMap.has(snapshot.anomalyId)) {
        console.warn(`Duplicate anomalyId found: ${snapshot.anomalyId}`);
        // Append index to make id unique as a temporary fix
        return {
          ...snapshot,
          id: `${snapshot.anomalyId}-${index}`,
          name: snapshot.anomalyName,
          grade: snapshot.grade,
          layers: snapshot.layers,
          implications: snapshot.anomaly.implications,
          medicalConsiderations: snapshot.anomaly.medicalConsiderations,
        };
      }
      idMap.set(snapshot.anomalyId, true);
  
      return {
        id: snapshot.anomalyId,
        name: snapshot.anomalyName,
        grade: snapshot.grade,
        layers: snapshot.layers,
        implications: snapshot.anomaly.implications,
        medicalConsiderations: snapshot.anomaly.medicalConsiderations,
      };
    });
  
    // Sort the transformed data by combined score
    const sortedData = sortRowsByScore(transformedData);
  
    setAnomaliesData(sortedData);
  };
  
  // Function to prepare columns dynamically based on anomalies
  const prepareColumns = () => {
    const columns = [
      {
        field: "type",
        headerName: "Type",
        width: 200,
        cellClassName: "customCell",
      },
      {
        field: "detail",
        headerName: "Detail",
        width: 200,
        cellClassName: "customCell",
      },
    ];

    anomaliesData.forEach((anomaly) => {
      let headerName = anomaly.name;
    
      if (anomaly.layers && anomaly.layers.length > 0) {
        const layersString = `(L${anomaly.layers.join(",")})`;
        headerName += ` ${layersString}`;
      }

      columns.push({
        field: anomaly.name,
        headerName: headerName,
        width: 240,
        cellClassName: (params) => {
          let classNames = "customCell";
          if (params.value === "Yes") {
            classNames += " yesCell";
          } else if (params.value === "No") {
            classNames += " noCell";
          }

          return classNames;
        },
      });
    });

    // Add SCORE column
    columns.push({
      field: "score",
      headerName: "SCORE",
      width: 100,
      cellClassName: "customCell",
    });

    return columns;
  };

  const prepareRowData = () => {
    const rowData = [];
    const uniqueImplications = new Set();
    const uniqueMedicalConsiderations = new Set();
  
    // Collect unique implications and medical considerations
    anomaliesData.forEach((anomaly) => {
      anomaly.implications.forEach((implication) =>
        uniqueImplications.add(implication)
      );
      anomaly.medicalConsiderations.forEach((consideration) =>
        uniqueMedicalConsiderations.add(consideration)
      );
    });
  
    // Create a row for grades
    const gradesRow = { id: "grades", type: "Grade" };
    anomaliesData.forEach((anomaly) => {
      gradesRow[anomaly.name] = anomaly.grade;
    });
    rowData.push(gradesRow);
  
    // Calculate layer score adjustment
    const layerScoreAdjustment = (anomaly) => {
      return anomaly.layers && anomaly.layers.length > 0
        ? anomaly.grade * 1.25
        : anomaly.grade;
    };
  
    // Create two row types
    // implications and medical considerations
  
    uniqueImplications.forEach((implication) => {
      const row = {
        id: `imp-${implication}`,
        type: "Implication",
        detail: implication,
      };
      let score = 0;
      anomaliesData.forEach((anomaly) => {
        if (anomaly.implications.includes(implication)) {
          row[anomaly.name] = "Yes";
          score += layerScoreAdjustment(anomaly);
        } else {
          row[anomaly.name] = "No";
        }
      });
      row["score"] = score;
      rowData.push(row);
    });
  
    uniqueMedicalConsiderations.forEach((consideration) => {
      const row = {
        id: `med-${consideration}`,
        type: "Medical Consideration",
        detail: consideration,
      };
      let score = 0;
      anomaliesData.forEach((anomaly) => {
        if (anomaly.medicalConsiderations.includes(consideration)) {
          row[anomaly.name] = "Yes";
          score += layerScoreAdjustment(anomaly);
        } else {
          row[anomaly.name] = "No";
        }
      });
      row["score"] = score;
      rowData.push(row);
    });
  
    // Sort rowData by score in descending order (highest score at the top)
    rowData.sort((a, b) => b.score - a.score);
    return rowData;
  };
  
  const calculateScores = (type) => {
    const uniqueItems = new Set();
    const scoresMap = new Map();

    const isImplication = type === "implications";

    anomaliesData.forEach((anomaly) => {
      const items = isImplication
        ? anomaly.implications
        : anomaly.medicalConsiderations;
      const layerScore =
        anomaly.layers && anomaly.layers.length > 0
          ? anomaly.grade * 1.25
          : anomaly.grade;

      items.forEach((item) => {
        uniqueItems.add(item);
        const currentScore = scoresMap.get(item) || 0;
        const addedScore = !isNaN(layerScore) ? layerScore : 0;
        scoresMap.set(item, currentScore + addedScore);
      });
    });

    return Array.from(uniqueItems).map((item) => ({
      label: item,
      score: isFinite(scoresMap.get(item)) ? scoresMap.get(item) : 0, // Check for finite score
    }));
  };

  const columns = prepareColumns();
  const rowData = prepareRowData();

  // Graphs

  const ImplicationsGraph = () => {
    const validateGraphData = (data) => {
      return data.map((d) => ({
        ...d,
        y: isFinite(d.score) ? d.score : 0,
      }));
    };
  
    // Calculate Scores and validate the data
    let implicationsGraphData = calculateScores("implications");
    implicationsGraphData = validateGraphData(implicationsGraphData).sort(
      (a, b) => b.score - a.score  // Sort in descending order
    );
  
    return (
      <VictoryChart
        padding={{ top: 10, bottom: 20, left: 20, right: 20 }}
        domainPadding={{ y: [10, 10] }}
      >
  
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "black" },
            tickLabels: {
              fontSize: 8,
              padding: 10,
              angle: 0,
              verticalAnchor: "middle",
              textAnchor: "end",
            },
          }}
          domain={[0, 20]}
        />
       
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
          }}
        />
  
        <VictoryBar
          horizontal
          data={implicationsGraphData}
          style={{
            data: { fill: "#e53935" },
            labels: {
              fontSize: 9,
              fill: "black",
              angle: 0,
              textAnchor: "start",
            },
          }}
          labels={({ datum }) => `${datum.score}`}
          labelComponent={<VictoryLabel dx={5} />}
        />
        <VictoryLabel
          text="0"
          x={20} 
          y={295}
          style={{
            fontSize: 8,
            fill: "black",
          }}
        />
      </VictoryChart>
    );
  };

  const MedicalConsiderationsGraph = () => {
    // Validate and prepare the graph data
    const validateGraphData = (data) => {
      return data.map((d) => ({
        ...d,
        y: isFinite(d.score) ? d.score : 0,
      }));
    };

    // Calculate the scores and validate the data
    let medicalConsiderationsGraphData = calculateScores(
      "medicalConsiderations"
    );
    medicalConsiderationsGraphData = validateGraphData(
      medicalConsiderationsGraphData
    ).sort((a, b) => b.score - a.score); // Sort in descending order

    return (
      <VictoryChart
        padding={{ top: 10, bottom: 20, left: 20, right: 20 }}
        domainPadding={{ y: [10, 10] }}
      >
        {/* X axis (now horizontal) */}
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "black" },
            tickLabels: {
              fontSize: 8,
              padding: 10,
              angle: 0,
              verticalAnchor: "middle",
              textAnchor: "end",
            },
          }}
          domain={[0, 20]}
        />
        {/* Y axis (now vertical) */}
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "transparent" },
          }}
        />

        {/* Configure the bars */}
        <VictoryBar
          horizontal
          data={medicalConsiderationsGraphData}
          style={{
            data: { fill: "#e53935" },
            labels: {
              fontSize: 9,
              fill: "black",
              angle: 0,
              textAnchor: "start",
            },
          }}
          labels={({ datum }) => `${datum.score}`}
          labelComponent={<VictoryLabel dx={5} />}
        />
        <VictoryLabel
          text="0"
          x={20} 
          y={295}
          style={{
            fontSize: 8,
            fill: "black",
          }}
        />
      </VictoryChart>
    );
  };


  // The Material data grid is hidden
  // The custom chart is generated out of the Material data grid

  const uniqueImplications = new Set();
  const uniqueMedicalConsiderations = new Set();

  anomaliesData.forEach((anomaly) => {
    anomaly.implications.forEach((implication) =>
      uniqueImplications.add(implication)
    );
    anomaly.medicalConsiderations.forEach((consideration) =>
      uniqueMedicalConsiderations.add(consideration)
    );
  });

  // Calculate scores for implications
  const implicationScores = calculateScores("implications");
  const medicalConsiderationScores = calculateScores("medicalConsiderations");

  // Merge implication and medical consideration scores
  const combinedScores = [...implicationScores, ...medicalConsiderationScores];
  const scoreMap = new Map(
    combinedScores.map((item) => [item.label, item.score])
  );

  return (
    <Wrapper>
    <GoBackButton sx={{ m: 2 }} />
      <Header>
        
        <HeaderBlock>
        <Heading> Download Report </Heading>
        </HeaderBlock>
        <HeaderBlockEnd>
          {isLoading ? (
            <div>Loading anomalies...</div>
          ) : (

              <GenerateConciseReport
                date={date}
                anomalies={anomaliesData}
                patientDetailsId={patientDetailsId}
                visitId={visitId}
                color="secondary"
                variant="contained"
                displayId={displayId}
              />
              
          )}

          {isLoading ? (
            <div>Loading anomalies...</div>
          ) : (

            <GenerateExpandedReport
              date={date}
              anomalies={anomaliesData}
              patientDetailsId={patientDetailsId}
              visitId={visitId}
              color="primary"
              variant="contained"
              displayId={displayId}
            />
          )}
        </HeaderBlockEnd>

      </Header> 

      <ChartWrapper id="blood-chart">
      <Row>
        <StartColumnHeader>Type | Detail</StartColumnHeader>
        <YesColumnHeader>Blood anomaly (Yes)</YesColumnHeader>
        <EndColumnHeader>Score</EndColumnHeader>
      </Row>
    
      {[...uniqueImplications]
        .sort((a, b) => (scoreMap.get(b) || 0) - (scoreMap.get(a) || 0)) // Sorting by score in descending order
        .map((implication, index) => (
          <Row key={`imp-${index}`}>
            <StartColumn>
              <StartColumnChip>
                <StartColumnChipType>Type</StartColumnChipType>
                <StartColumnChipTypeLabel>Implications</StartColumnChipTypeLabel>
              </StartColumnChip>
              <StartColumnChip>
                <StartColumnChipDetails>Details</StartColumnChipDetails>
                <StartColumnChipDetailsLabel>{implication}</StartColumnChipDetailsLabel>
              </StartColumnChip>
            </StartColumn>
            <YesColumn>
              {anomaliesData
                .filter((anomaly) => anomaly.implications.includes(implication))
                .map((anomaly, ai) => {
                  const layersString = anomaly.layers && anomaly.layers.length > 0
                    ? `(L ${anomaly.layers.join(",")})`
                    : "";
                  return (
                    <YesColumnChip key={`yes-${ai}`}>
                      <YesColumnChipLabel>{anomaly.name}</YesColumnChipLabel>
                      <YesColumnBlock>
                        {anomaly.grade && (
                          <YesColumnChipGrade>G {anomaly.grade}</YesColumnChipGrade>
                        )}
                        {anomaly.layers && anomaly.layers.length > 0 && (
                          <YesColumnChipLayer>
                            {`L ${anomaly.layers.join(",")}`}
                          </YesColumnChipLayer>
                        )}
                      </YesColumnBlock>
                    </YesColumnChip>
                  );
                })}
            </YesColumn>
            <EndColumn>
              <EndColumnChipInner>{scoreMap.get(implication)}</EndColumnChipInner>
            </EndColumn>
          </Row>
        ))}
    
      {[...uniqueMedicalConsiderations]
        .sort((a, b) => (scoreMap.get(b) || 0) - (scoreMap.get(a) || 0)) // Sorting by score in descending order
        .map((consideration, index) => (
          <Row key={`med-${index}`}>
            <StartColumn>
              <StartColumnChipMedicalConsiderations>
                <StartColumnChipType>Type</StartColumnChipType>
                <StartColumnChipTypeLabel>Medical Consideration</StartColumnChipTypeLabel>
              </StartColumnChipMedicalConsiderations>
              <StartColumnChipMedicalConsiderations>
                <StartColumnChipDetails>Details</StartColumnChipDetails>
                <StartColumnChipDetailsLabel>{consideration}</StartColumnChipDetailsLabel>
              </StartColumnChipMedicalConsiderations>
            </StartColumn>
            <YesColumn>
              {anomaliesData
                .filter(
                  (anomaly) =>
                    anomaly.medicalConsiderations &&
                    anomaly.medicalConsiderations.includes(consideration)
                )
                .map((anomaly, ai) => {
                  const layersString = anomaly.layers && anomaly.layers.length > 0
                    ? `L ${anomaly.layers.join(",")}`
                    : "";
                  return (
                    <YesColumnChip key={`yes-med-${ai}`}>
                      <YesColumnChipLabel>{anomaly.name}</YesColumnChipLabel>
                      <YesColumnBlock>
                        {anomaly.grade && (
                          <YesColumnChipGrade>G {anomaly.grade}</YesColumnChipGrade>
                        )}
                        {anomaly.layers && anomaly.layers.length > 0 && (
                          <YesColumnChipLayer>{layersString}</YesColumnChipLayer>
                        )}
                      </YesColumnBlock>
                    </YesColumnChip>
                  );
                })}
            </YesColumn>
            <EndColumn>
              <EndColumnChipInner>{scoreMap.get(consideration) || "N/A"}</EndColumnChipInner>
            </EndColumn>
          </Row>
        ))}
    </ChartWrapper>
    

      <HiddenWrapper>
        <DataGrid
          rows={rowData}
          columns={columns}
          autoHeight
          disableColumnMenu
          hideFooter
        />
      </HiddenWrapper>

      <Header>
        <HeaderBlock>
          <Heading> Implications </Heading>
        </HeaderBlock>
      </Header>
      <GraphWrapper id="graph-1">
        <ImplicationsGraph />
      </GraphWrapper>

      <Header>
        <HeaderBlock>
          <Heading> Medical Considerations </Heading>
        </HeaderBlock>
      </Header>
      <GraphWrapper id="graph-2">
        <MedicalConsiderationsGraph />
      </GraphWrapper>

    </Wrapper>
  );
}
