
import React from "react";

import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import SelectionCard from "../../Components/SelectionCard/SelectionCard";

function PracticeCard({ practice }) {
  return (
    <SelectionCard to={`${practice.id}`}>
      <CardContent>
        <Typography gutterBottom variant="h5" color="primary.main">
          {practice.name} ({practice.tenantId})
        </Typography>
        <Divider>
          <Chip
            label={
              <Typography>
                ID: <b>{practice.id}</b>
              </Typography>
            }
          />
        </Divider>
        <Typography sx={{ color: "grey.600" }}>
          <Typography component="span" fontWeight={600} color="grey.A700">
            Address:
          </Typography>{" "}
          {practice.address.addressLine1 ?? "-"}
        </Typography>
        <Typography sx={{ color: "grey.500" }} noWrap>
          {practice.address.city ?? "-"},{` `}
          {practice.address.province ?? "-"},{` `}
          {practice.address.postalCode ?? "-"}
        </Typography>
        <Typography sx={{ color: "grey.600" }}>
          <Typography component="span" fontWeight={600} color="grey.A700">
            Country:
          </Typography>{" "}
          {practice.country ?? "-"}
        </Typography>

      </CardContent>
    </SelectionCard>
  );
}

export default PracticeCard;
