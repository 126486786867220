import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import styled from "@emotion/styled";
import * as Yup from "yup";
import axios from "axios";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MaterialTextarea from "../../Components/Formik/MaterialTextarea";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WebcamCapture from "../../Components/WebcamCapture";
import { useSelector } from "react-redux";
import { selectBearerToken } from "../../globalSlice";
import { apiBaseUrl } from "../../utilities";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 80px;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const RowStart = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const Column = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function EditVisitForm({ visitData, patientDetailsId }) {
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get('tab');

  const [activeTab, setActiveTab] = useState(tabFromUrl === 'camera' && visitData.edit ? 1 : 0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const tab = queryParams.get('tab');
    setActiveTab(tab === 'camera' && visitData.edit ? 1 : 0);
  }, [location, visitData.edit]);

  const token = useSelector(selectBearerToken);

  const [capturedScreenshots, setCapturedScreenshots] = useState([]);
  const handleCapture = (screenshotData) => {
    setCapturedScreenshots((prev) => [...prev, screenshotData]);
  };

  const initialValues = {
    summary: visitData?.summary || "",
    recommendations: visitData?.recommendations || "",
    implications: visitData?.implications || "",
    medicalConsiderations: visitData?.medicalConsiderations || "",
    patientDetailsId: visitData?.patientDetailsId || "",
  };

  const validationSchema = Yup.object({
    summary: Yup.string().required("Required."),
    recommendations: Yup.string().required("Required."),
    implications: Yup.string().required("Required."),
    medicalConsiderations: Yup.string().required("Required."),
  });

  const triggerSuccessMsg = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  };

  const submitForm = (values, { resetForm }) => {
    setSubmitting(true);

    const formattedDate = format(new Date(), 'yyyy-MM-dd');

    const jsonForm = {
      date: formattedDate,
      summary: values.summary,
      recommendations: values.recommendations,
      implications: values.implications,
      medicalConsiderations: values.medicalConsiderations,
      patientDetailsId: values.patientDetailsId,
    };

    axios
      .patch(`${apiBaseUrl}/cmn/v1/visits/${visitData.id}`, jsonForm, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        triggerSuccessMsg();
        // Re-fetch the updated visit data
        return axios.get(`${apiBaseUrl}/cmn/v1/visits/${visitData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      })
      .then((response) => {
        resetForm({ values: response.data }); // Update form values with the latest data
      })
      .catch((error) => {
        try {
          setErrorMsg(error.response.data.message);
        } catch (e) {
          setErrorMsg(error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
  });

  useEffect(() => {
    const tab = queryParams.get('tab');
    setActiveTab(tab === 'camera' ? 1 : 0);
  }, [location]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      className="m-t-25"
    >
      <Tabs value={activeTab} onChange={handleChange} centered>
        <Tab className="dark-hover" label="Details" />
        {visitData.edit && <Tab label="Camera" />}
      </Tabs>
      <Wrapper>
        <TabPanel className="if-col" value={activeTab} index={0}>
          <Column>
            <Stack alignItems="center" className="if-col">
              <Accordion className="if-col">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Summary</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MaterialTextarea
                    formik={formik}
                    className="document-creation"
                    name="summary"
                    label="*Summary"
                    variant="outlined"
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion className="if-col">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <Typography>Recommendations</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MaterialTextarea
                    formik={formik}
                    className="document-creation"
                    name="recommendations"
                    label="*Recommendations"
                    variant="outlined"
                  />
                </AccordionDetails>
              </Accordion>
            </Stack>
            <Stack marginY={2} width="100%" alignItems="center">
              {showSuccess && (
                <Alert severity="success">Visit successfully updated.</Alert>
              )}
              {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            </Stack>
            <Stack width="100%" alignItems="flex-end">
              <Button
                sx={{ marginTop: 2 }}
                type="submit"
                variant="contained"
                disabled={submitting}
              >
                Update
              </Button>
            </Stack>
          </Column>
        </TabPanel>

        <TabPanel className="if-col" value={activeTab} index={1}>
          <Row>
            <WebcamCapture onCapture={handleCapture} visitData={visitData} />
          </Row>
        </TabPanel>
      </Wrapper>
    </Box>
  );
}
