import React, { useState } from "react";
import AddVisitForm from "../../Containers/AddVisitPage/AddVisitForm";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import VisitCard from "../../Containers/VisitSelection/VisitCard";
import styled from "@emotion/styled";
import { useNavigate } from 'react-router-dom';

const Root = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const Modal = styled.div`
  position: fixed;
  z-index: 5000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 1px;
  background-color: #ffffff;
  display: inline-flex;
  opacity: 0;
  flex-direction: column;
`;

const PageSubHeader = styled.h4`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 15px 20px;
  margin: 0;
  font-size: 20px;
  z-index: 1000;
  font-weight: 600;
  color: #333366;
  background-color: #ffffff;
  border-bottom: 2px solid  #333366;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0 25px;
  justify-content: flex-end;
`;

const CardWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 25px;
`;

const Card = styled.div`
  position: relative;
  flex: 1 1 340px;
  max-width: 340px;
  margin: 10px;
`;

function PatientVisits({ data, name, patientId, linkBase, refreshVisits }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

 
  const handleSubmissionComplete = (newVisitId) => {
    handleCloseModal();
    refreshVisits();
    navigate(`/visits/edit/${newVisitId}?tab=camera`);
  };

  return (
    <Root>
      <PageSubHeader>Visits</PageSubHeader>

      <Row>
       
        <Button
          onClick={handleOpenModal}
          variant="contained"
          color="primary"
        >
          Create New Visit
        </Button>
      </Row>

      <CardWrapper>
      {data?.map((visit, index) => (
        <Card item key={`visit_card_${visit.id}`}>
          <VisitCard
            {...visit}
            displayId={index + 1}
            linkToEdit={`/visits/edit/${visit.id}?tab=${visit.edit ? 'details' : 'camera'}`}
          />
        </Card>
      ))}
    </CardWrapper>
  
      {isModalOpen && (
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
       
        <AddVisitForm
          patientDetailsId={patientId}
          autoSubmit={true}
          onSubmissionComplete={handleSubmissionComplete}
        />
      
      </Modal>
      )}

    </Root>
  );
}

export default PatientVisits;
