import React, { useCallback } from "react";

import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import SelectionCard from "../../Components/SelectionCard/SelectionCard";

function CompanyCard({ company, onSelect, selected }) {
  const handleOnSelect = useCallback(() => onSelect(company), [company]);
  return (
    <SelectionCard onClick={handleOnSelect} selected={selected}>
      <CardContent>
        <Typography gutterBottom variant="h5" color="primary.main">
          {company.name} ({company.code})
        </Typography>
        <Divider>
          <Chip
            label={
              <Typography>
                ID: <b>{company.id}</b>
              </Typography>
            }
          />
        </Divider>
        <Typography sx={{ color: "grey.600" }}>
          <Typography component="span" fontWeight={600} color="grey.A700">
            Address:
          </Typography>{" "}
          {company.address.addressLine1 ?? "-"}
        </Typography>
        <Typography sx={{ color: "grey.500" }} noWrap>
          {company.address.city ?? "-"},{` `}
          {company.address.province ?? "-"},{` `}
          {company.address.postalCode ?? "-"}
        </Typography>
        <Typography sx={{ color: "grey.600" }}>
          <Typography component="span" fontWeight={600} color="grey.A700">
            Country:
          </Typography>{" "}
          {company.country ?? "-"}
        </Typography>
      </CardContent>
    </SelectionCard>
  );
}

export default CompanyCard;
