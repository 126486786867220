import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import styled from "@emotion/styled";
import axios from "axios";
import { selectBearerToken } from '../../globalSlice';
import { apiBaseUrl } from '../../utilities';
import UserHeader from '../../Containers/UserHeader/UserHeader';
import { useNavigate, useParams } from "react-router-dom";
import { FormMode } from '../../utilities';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 0 25px;
  z-index: 2000;
  background-color: rgb(51, 51, 102);
`;

const MenuWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  a span {
    font-weight: 600 !important;
  }
`;

const drawerWidth = 200;

function Header({ pages, userLinks }, props) {
  const [companyData, setCompanyData] = useState(null);
  const { companyId } = useParams();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userGroup, setUserGroup] = useState(null);
  const token = useSelector(selectBearerToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useAuth();
  const location = useLocation();

  const isAdmin = user?.group === 'ADMIN';
  const isCompanyOrPracticeRoute = location.pathname.startsWith('/companies') || location.pathname.startsWith('/practices');

  // Fetch user profile to determine group (role)
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/cmn/v1/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserGroup(response.data.group);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setLoading(false);
      }
    };

    if (token) {
      fetchUserProfile();
    }
  }, [token]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (companyId) {
      axios
        .get(`${apiBaseUrl}/admin/v1/companies/${companyId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setCompanyData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    }
  }, [companyId, token]);

  const toEditForm = (existingCompany) => {
    navigate('/companies/add', {
      state: { mode: FormMode.EDIT, existingCompany: existingCompany },
    });
  };

  const removeCompany = (companyId) => {
    axios
      .delete(`${apiBaseUrl}/admin/v1/companies/${companyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        navigate('/companies');
      })
      .catch((error) => {
        console.error("Error deleting company:", error);
      });
  };


  const drawer = (
    <div>
      <Toolbar />

      <MenuWrapper>
        <List>
          {pages.map((item, index) => {
            return (
              <ListItem component={Link} to={item.link} key={item.name}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.name} />
              </ListItem>
            );
          })}
        </List>
      </MenuWrapper>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <AppBar style={{ position: "relative", left: 0, top: 0, zIndex: 1 }}>
        <Wrapper>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: { xs: 1, md: 0 },
                order: { xs: 2, md: 1 },
                marginY: 1,
                display: "flex",
              }}
            >
              <Link to="/">
                <Box
                  component="img"
                  sx={{ cursor: "pointer" }}
                  src="https://www.neogenesissystems.com/wp-content/uploads/2020/09/logo-neogenesissystems_alt_3.png"
                ></Box>
              </Link>
            </Box>

            <Box
              sx={{
                alignContent: "flex",
                order: 3,
                position: "absolute",
                right: 0,
              }}
            >
             
              <Tooltip title="User Account">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                  color="inherit"
                >
                  <AccountCircleIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "50px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {userLinks.map((userLink) => (
                  <MenuItem
                    key={`nav_user_link_${userLink.link}`}
                    onClick={handleCloseUserMenu}
                  >
                    {userLink.dispatch ? (
                      <Typography
                        textAlign="center"
                        onClick={() => {
                          dispatch(userLink.dispatch());
                        }}
                      >
                        {userLink.name}
                      </Typography>
                    ) : (
                      <Link
                        to={userLink.link}
                        underline="none"
                        sx={{
                          color: "text.primary",
                          ":hover": { color: "inherit" },
                        }}
                      >
                        {userLink.name}
                      </Link>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>

            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Toolbar>
        </Wrapper>
      </AppBar>

      {/* Only display UserHeader if ADMIN */}
      {isAdmin && isCompanyOrPracticeRoute && (
        <UserHeader
          onEdit={toEditForm}
          onRemove={removeCompany}
          existingCompany={companyData}
        />
      )}
    </>
  );
}

export default Header;
