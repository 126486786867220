import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Form, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

import SnapshotCamera from "./SnapshotCamera";
import LayersSelect from "./LayersSelect";
import GradeSelect from "./GradeSelect";
import Divider from "@mui/material/Divider";

import RecentSnaps from "./RecentSnaps";
import AnomalySelection from "../AnomalySelection/AnomalySelection";
import { uploadFile } from "../../fetchers/files";
import { selectBearerToken } from "../../globalSlice";

function SnapshotPage() {
  const [recentSnaps, setRecentSnaps] = useState({});
  const [grade, setGrade] = useState(1);
  const [layers, setLayers] = React.useState(["1"]);
  const [anomaly, setAnomaly] = React.useState(null);
  const [isReadyForSnapshots, setIsReadyForSnapshots] = useState(false);
  const { visitId } = useParams();
  const token = useSelector(selectBearerToken);

  useEffect(() => {
    if (layers.length !== 0 && anomaly !== null) {
      setIsReadyForSnapshots(true);
    } else {
      setIsReadyForSnapshots(false);
    }
  }, [layers, anomaly]);

  const handleAddRecentSnap = (snapData) => {
    const id = uuidv4();
    const snap = {
      id,
      data: snapData,
      grade,
      layers,
      anomalyName: anomaly.name,
      fileName: null,
      isLoading: false,
    };
    const newSnapObj = {
      ...recentSnaps,
      ...{
        [id]: snap,
      },
    };
    setRecentSnaps(newSnapObj);
    handleUploadSnap(snap);
  };

  const handleUpdateSnap = useCallback(
    (snap, updateObj) => {
      console.log("handleUpdateSnap", recentSnaps);
      recentSnaps[snap.id] = { ...snap, ...updateObj };
      setRecentSnaps({ ...recentSnaps });
    },
    [recentSnaps]
  );

  const handleDeleteSnap = useCallback(
    (id) => {
      console.log("Delete", recentSnaps);
      delete recentSnaps[id];
      setRecentSnaps({ ...recentSnaps });
    },
    [recentSnaps]
  );

  const handleUploadSnap = (snap) => {
    handleUpdateSnap(snap, { isLoading: true });
    const formdata = new FormData();
    formdata.append("file", snap.data);
    uploadFile(token, formdata)
      .then((res) => {
        // TODO when API fixed
      })
      .catch((e) => {
        handleUpdateSnap(snap, {
          loading: false,
          error: "Couldn't upload snapshot",
        });
      });
  };

  if (isNaN(visitId))
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 80px)",
        }}
      >
        <Alert severity="error">Invalid visit id</Alert>
      </Box>
    );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: 3,
        }}
      >
        <Card
          elevation={3}
          sx={{ height: { md: "75vh" }, marginX: 2, flexGrow: 0 }}
        >
          <SnapshotCamera
            isReadyForSnapshots={isReadyForSnapshots}
            handleCapture={handleAddRecentSnap}
          />
        </Card>
        <Box sx={{ display: "flex", marginTop: 2 }}>
          <LayersSelect layers={layers} setLayers={setLayers} />
          <GradeSelect grade={grade} setGrade={setGrade} />
          <AnomalySelection anomaly={anomaly} setAnomaly={setAnomaly} />
        </Box>
        <Divider flexItem />
        <RecentSnaps
          snaps={recentSnaps}
          onUpload={(snap) => console.log(snap)}
          onDelete={handleDeleteSnap}
        />
      </Box>
    </>
  );
}

export default SnapshotPage;
