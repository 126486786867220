import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import CompanyGrid from "../CompanyGrid/CompanyGrid";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { selectBearerToken, selectUserGroup } from "../../globalSlice";
import { getCompaniesPaginated } from '../../fetchers/companies';
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
`;

const SubHeading = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
  color: #000000;
  margin: 10px 0;
`;

function CompanyPage({ title = 'Companies', subtitle = 'Please select a company:', baseLink, isGoBackable = true }) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);

  const token = useSelector(selectBearerToken);
  const group = useSelector(selectUserGroup);

  const { isLoading, isError, data, isPreviousData } =
    useQuery({
      queryKey: ["companies", page],
      queryFn: async () => getCompaniesPaginated(token, page, 12),
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    });

  useEffect(() => {
    if (!isPreviousData && data?.totalPages < page - 1) {
      queryClient.prefetchQuery({
        queryKey: ["companies", page + 1],
        queryFn: () => getCompaniesPaginated(token, page + 1, 2),
      });
    }
  }, [data, isPreviousData, page, queryClient]);

  return (
    <Wrapper>
      <GoBackButton />
      <SubHeading>Companies</SubHeading>
      
        {isError && <Alert severity="error">An error occurred</Alert>}
        {isLoading && <CircularProgress />}

        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ margin: 1, width: "100%", backgroundColor: 'common.white' }}
        >
          {data?.content.length > 0 && (
            <CompanyGrid data={data} />
          )}
          {data?.content.length === 0 && (
            <Alert severity="error">No data to display</Alert>
          )}
        </Grid>
       
     
    </Wrapper>
  );
}

export default CompanyPage;
