import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";

import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import ReportLink from "./ReportLink";
import { selectBearerToken } from "../../globalSlice";
import { getAnomaliesReport } from "../../fetchers/reports";

function ReportsPage() {
  const token = useSelector(selectBearerToken);

  const { data, isLoading } = useQuery({
    queryKey: ["anomaliesReport"],
    queryFn: () => getAnomaliesReport(token),
    staleTime: 5000,
    retry: false,
  });

  let href;

  if (data) {
    href = window.URL.createObjectURL(data);
  }

  return (
    <Stack
      height="calc(100vh - 80px)"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {isLoading && <CircularProgress />}
      {href && (
        <ReportLink href={href} title="Anomalies Report" />
      )}
      {!isLoading && !data && <Alert>Unable to generate the report</Alert>}
    </Stack>
  );
}

export default ReportsPage;
