import axios from "axios";

import { apiBaseUrl } from "../utilities";

const baseUrl = `${apiBaseUrl}/cmn/v1/anomaly-types`;

export const getAnomalyTypes = async (token) => {
  const { data } = await axios.get(baseUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getAnomaliesOfType = async (token, anomalyType) => {
  const { data } = await axios.get(`${baseUrl}/${anomalyType}/anomalies`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};