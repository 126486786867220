import React from "react";

import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";

const cardTexts = [["grade"], ["layers"], ["anomaly", "Name"]];
const cardTextLastKey = cardTexts[cardTexts.length - 1].join("");

function RecentSnap({ snap, onUpload, onDelete }) {
  return (
    <Card elevation={0} sx={{ m: 1, position: "relative" }}>
      <Box sx={{ position: "relative" }}>
        <CardMedia component="img" image={snap.data} alt="Recent Snap" />
        <Box sx={{ position: "absolute", top: 8, right: 8 }}>
          <Tooltip title="Upload snapshot">
            <span>
              <IconButton
                color="primary"
                disabled={snap.fileName || snap.isLoading || !!snap.error}
                onClick={() => onUpload(snap)}
              >
                <CloudUploadIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        {!snap.fileName && (
          <Box sx={{ position: "absolute", top: 8, left: 8 }}>
            <Tooltip title="Delete snapshot">
              <IconButton onClick={() => onDelete(snap.id)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <CardContent>
        <Stack flexDirection="row" alignItems="center" marginBottom={1} flexWrap="wrap">
        {cardTexts.map((cardTextArr) => {
          const cardKey = cardTextArr.join("");
          return (
            <React.Fragment key={`card_texy_${cardKey}`}>
              <Typography color="grey.700" textTransform="capitalize">
                {cardTextArr.join(" ")}:
                <Typography marginLeft={1} component="span" fontWeight={600}>
                  {snap[cardKey]}
                </Typography>
              </Typography>
              {cardKey !== cardTextLastKey && (
                <Divider flexItem orientation="vertical" sx={{ marginX: 1 }} />
              )}
            </React.Fragment>
          );
        })}
        </Stack>
        {!!snap.error && <Alert sx={{ flexBasis: '100%' }} severity="error">{snap.error}</Alert>}
      </CardContent>
      {snap.isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 50,
            backgroundColor: "rgba(0, 0,0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
}

export default RecentSnap;
