import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Box, Typography, Switch, FormControlLabel, CircularProgress, Snackbar, Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectBearerToken } from '../../globalSlice';
import { apiBaseUrl } from '../../utilities';
import { useNavigate } from 'react-router-dom'; 
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const MidWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 768px;
  padding: 0 20px;
  margin: 0 auto;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

const Footer = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 60px;
`;

const Column = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const ColumnHide = styled.div`
  display: none;
  width: 100%;
`;


const PracticeUserForm = ({ onSuccess }) => {
  const { practiceId } = useParams();
  const [userData, setUserData] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
    accountEnabled: true,
    group: 'PRACTICE_USER',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const token = useSelector(selectBearerToken);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSwitchChange = (e) => {
    setUserData((prevData) => ({
      ...prevData,
      accountEnabled: e.target.checked,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const userResponse = await axios.post(
        `${apiBaseUrl}/admin/v1/users`,
        {
          name: userData.name,
          username: userData.username,
          email: userData.email,
          password: userData.password,
          accountEnabled: userData.accountEnabled,
          group: 'PRACTICE_USER',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      const userId = userResponse?.data?.id;
  
      if (!userId) {
        throw new Error('User ID could not be retrieved');
      }
  
      const practiceUserResponse = await axios.post(
        `${apiBaseUrl}/admin/v1/practice/${practiceId}/users`,
        null,
        {
          params: { userId: userId },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (practiceUserResponse.status === 200 || practiceUserResponse.status === 201) {
        navigate(`/practices/${practiceId}`);  
        onSuccess(practiceUserResponse.data);
      } else {
        throw new Error('error received when associating the user');
      }
  
    } catch (error) {
      console.error("error creating user or associating with practice:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
  <Wrapper>
  <GoBackButton />
    <MidWrapper>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Column>
        <p className="compact-form-heading">Add User to Practice</p>
        <p className="compact-form">Name *</p>
        <TextField
          fullWidth
          name="name"
          value={userData.name}
          onChange={handleChange}
          required
          margin="normal"
        />
        <p className="compact-form">Username *</p>
        <TextField
          fullWidth
          name="username"
          value={userData.username}
          onChange={handleChange}
          required
          margin="normal"
        />
        <p className="compact-form">Password *</p>
        <TextField
          fullWidth
          name="password"
          value={userData.password}
          onChange={handleChange}
          type="password"
          required
          margin="normal"
        />
        <p className="compact-form">Email *</p>
        <TextField
          fullWidth
          name="email"
          value={userData.email}
          onChange={handleChange}
          type="email"
          required
          margin="normal"
        />
      
        <ColumnHide>
          <FormControlLabel
            control={
              <Switch
                checked={userData.accountEnabled}
                onChange={handleSwitchChange}
                name="accountEnabled"
              />
            }
            label="Account Enabled"
          />
        </ColumnHide>
        <Footer>
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }} disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Save'}
          </Button>

          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}

          <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Footer>

      </Column>
      </Box>
    </MidWrapper>
  </Wrapper>
  );
};

export default PracticeUserForm;
