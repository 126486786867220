import React from 'react';

function AdminPage() {
  return (
    <div>
       
    </div>
  );
}

export default AdminPage;
