import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const selectableGrades = ['1', '2', '3', '4', '5'];

function GradeSelect({ grade, setGrade }) {
  const handleChange = (event) => {
    setGrade(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }} color="secondary">
        <InputLabel id="select-grade-label">Grade</InputLabel>
        <Select
          labelId="select-grade-label"
          id="select-grade"
          value={grade}
          label="Grade"
          onChange={handleChange}
        >
          {selectableGrades.map(grade => (
            <MenuItem key={`grade_select_item_${grade}`} sx={{ ':hover': { color: 'common.black' }}} value={grade}>{grade}</MenuItem>
          ))}
        </Select>
        <FormHelperText>Snapshot Grade</FormHelperText>
      </FormControl>
    </div>
  );
}

export default GradeSelect;