import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import FourOFour from "./404";
import LoginPage from "./LoginPage/LoginPage";
import AuthWrapper from "./AuthWrapper/AuthWrapper";
import WorkInProgress from "./WorkInProgress";
import { PRAC_USER, ADMIN, BOUSER } from "./AccessGroups";
import SnapshotPage from "./SnapshotPage/SnapshotPage";
import VisitSelectionPage from "./VisitSelectionPage/VisitSelectionPage";
import PracticeSelectionPage from "./PracticeSelectionPage/PracticeSelectionPage";
import PatientSelection from "./PatientSelection/PatientSelection";
import VisitSelectionByPatientDetailsId from "./VisitSelection/VisitSelectionByPatientDetailsId";
import VisitSelectionByPracticeId from "./VisitSelection/VisitSelectionByPracticeId";
import PatientsPage from "./PatientsPage/PatientsPage";
import PatientDetailsPage from "./PatientDetailsPage/PatientDetailsPage";
import VisitsSelectionPage from "./VisitsSelectionPage/VisitsSelectionPage";
import AddVisitPage from './AddVisitPage/AddVisitPage';
import EditVisitPage from './EditVisitPage/EditVisitPage';
import VisualizationPage from './VisualizationPage/VisualizationPage';
import ReportsPage from "./ReportsPage/ReportsPage";
import AdminPage from './AdminPage/AdminPage';
import CompanyPage from "./CompanyPage/CompanyPage";
import CompanyDetailsPage from "./CompanyDetailsPage/CompanyDetailsPage";
import CompanyDetailsForm from "./CompanyDetailsPage/CompanyDetails/CompanyDetailsForm";
import PatientManagementPage from "./PatientManagement/PatientManagementPage";
import AddPatientPage from "./PatientManagement/PatientForm";
import PracticeDetailsPage from "./PracticeDetailsPage/PracticeDetailsPage";
import PatternLibrary from "./PatternLibrary/PatternLibrary";
import CompanyLayout from "./CompanyLayout/CompanyLayout";
import PracticeDetailsForm from "./PracticeDetailsForm/PracticeDetailsForm";
import PracticeUserForm from "./PracticeUserForm/PracticeUserForm";
import { useAuth } from '../hooks/useAuth';
import PracticeUsersGrid from './PracticeGrid/PracticeUsersGrid';
import PracticeUserDetailsPage from './PracticeUserDetailsPage/PracticeUserDetailsPage';
import GetUserProfile from './GetUserProfile/GetUserProfile';
import { FormMode } from "../utilities";

const DefaultPageRedirect = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.group === 'ADMIN') {
      navigate('/patientManagement');
    } else {
      navigate('/patientManagement');
    }
  }, [user, navigate]);

  return null;
};

const privatize = (element, props = {}) => (
  <PrivateRoute {...props}>{element}</PrivateRoute>
);

export default function Routing() {
  return (
    <Router>
      <Routes>
       <Route path="/" element={<DefaultPageRedirect />} />      
       {/* Default route redirects based on user role */}
       <Route path="/patientManagement" element={<PrivateRoute allowedGroups={['PRAC_USER', 'ADMIN']}><PatientManagementPage /></PrivateRoute>} />
        <Route path="/" element={privatize(<AdminPage />)} />
        <Route path="/snapshot">
          <Route index element={privatize(<VisitSelectionPage subtitle="You need to select a visit to capture a snapshot for." />)} />
          <Route path="practices" element={privatize(<PracticeSelectionPage />)} />
          <Route path="practices/:practiceId" element={privatize(<VisitSelectionByPracticeId linkBase="/snapshot/" />)} />
          <Route path="patients" element={privatize(<PatientSelection />)} />
          <Route path="patients/:patientDetailsId" element={privatize(<VisitSelectionByPatientDetailsId linkBase="/snapshot/" />)} />
          <Route path=":visitId" element={privatize(<SnapshotPage />)} />
        </Route>
        <Route path="/profile" element={privatize(<GetUserProfile />, { allowedGroups: [PRAC_USER] })} />
        <Route path="/visits">
          <Route index element={privatize(<VisitsSelectionPage />)} />
          <Route path="add" element={privatize(<PatientSelection subtitle="Please select a client to add a visit for:" />)} />
          <Route path="add/:patientDetailsId" element={privatize(<AddVisitPage />)} />
          <Route path="view" element={privatize(<VisitSelectionPage isGoBackable />)} />
          <Route path="view/practices" element={privatize(<PracticeSelectionPage />)} />
          <Route path="view/practices/:practiceId" element={privatize(<VisitSelectionByPracticeId linkBase="/visits/" />)} />
          <Route path="view/patients" element={privatize(<PatientSelection />)} />
          <Route path="view/patients/:patientDetailsId" element={privatize(<VisitSelectionByPatientDetailsId linkBase="/visits/" />)} />
          <Route path="edit/:visitId" element={privatize(<EditVisitPage />)} />
          <Route path="edit/:visitId/visualization" element={privatize(<VisualizationPage />)} />
        </Route>
       
        <Route path="/reports" element={privatize(<ReportsPage />, { allowedGroups: [ADMIN, BOUSER] })} />

        <Route path="/companies/*" element={privatize(<CompanyLayout />, { allowedGroups: [ADMIN] })} />
        <Route path="/companies" element={privatize(<CompanyPage />)} />
        <Route exact path="/companies/:companyId" element={privatize(<CompanyDetailsPage />)} />
        <Route path="/companies/add" element={privatize(<CompanyDetailsForm mode={FormMode.ADD} />)} />
        <Route path="/companies/:companyId/edit" element={privatize(<CompanyDetailsForm mode={FormMode.EDIT} />)} />
        <Route path="/companies/:companyId/add-practice" element={privatize(<PracticeDetailsForm />)} />

        <Route path="/patients">
          <Route index element={privatize(<PatientsPage />)} />
          <Route path=":patientDetailsId" element={privatize(<PatientsPage />)} />
        </Route>

        <Route exact path="/practices" element={privatize(<PracticeDetailsPage />)} />
        <Route exact path="/practices/:practiceId" element={privatize(<PracticeDetailsPage />)} />
        <Route exact path="/practices/:practiceId/edit" element={privatize(<PracticeDetailsForm mode={FormMode.EDIT} />)} />
        <Route exact path="/practices/:practiceId/add-user" element={privatize(<PracticeUserForm />)} />
        <Route path="/practices/:practiceId/users" element={<PracticeUsersGrid />} />
        <Route path="/practices/:practiceId/users/:userId" element={<PracticeUserDetailsPage />} />

        <Route path="/patient">
          <Route index element={privatize(<PatientDetailsPage />)} />
          <Route path=":patientDetailsId" element={privatize(<PatientDetailsPage key={window.location.pathname} />)} />
        </Route>
        <Route path="/patientManagement">
          <Route index element={privatize(<PatientManagementPage />)} />
          <Route path="add" element={privatize(<AddPatientPage />)} />
        </Route>
        <Route path="/anomalies" element={privatize(<WorkInProgress />)} />
        <Route path="/pattern-library" element={privatize(<PatternLibrary />)} />
        <Route path="auth" element={<AuthWrapper />}>
          <Route path="login" element={<LoginPage open={true} />} />
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Route>
        <Route path="*" element={<FourOFour />} />
      </Routes>
    </Router>
  );
}
