import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Routing from "./Containers/Routing";
import { NavLink } from "react-router-dom";
import ErrorBoundary from './Components/ErrorBoundary';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WebcamCaptureProvider } from "./Components/WebcamCapture/WebcamCaptureContext";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Global } from '@emotion/react';
import { globalStyles } from './globalStyles.js';
import { Helmet } from 'react-helmet';

const theme = createTheme({
  palette: {
    primary: {
      main: "#333366",
    },
    secondary: {
      main: "#1976d2",
    },
    background: {
      default: "#cfd8dc",
      black: "#000000",
    },
    blueGrey: {
      light: "#333366",
      main: "#333366",
      dark: "#333366",
      contrastText: "#ffffff",
    },
    genderMale: {
      light: "#333366",
      main: "#333366",
      dark: "#333366",
      contrastText: "#ffffff",
    },
    genderFemale: {
      light: "#ff6090",
      main: "#e91e63",
      dark: "#b0003a",
      contrastText: "#ffffff",
    },
    active: {
      light: "#0da139",
      main: "#0da139",
      dark: "#0da139",
      contrastText: "#ffffff",
    },
    disabled: {
      light: "#d22e2e",
      main: "#d22e2e",
      dark: "#d22e2e",
      contrastText: "#ffffff",
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: NavLink,
      },
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: NavLink,
      },
      styleOverrides: {
        root: {
          ':hover': {
            color: "#fff",
          }
        }
      }
    },
  },
});

const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <WebcamCaptureProvider>
              <ThemeProvider theme={theme}>
                <Helmet>
                  <meta http-equiv="Cache-Control" content="no-store, no-cache, must-revalidate, max-age=0" />
                  <meta http-equiv="Pragma" content="no-cache" />
                  <meta http-equiv="Expires" content="0" />
                </Helmet>
                <Global styles={globalStyles} />
                <Routing />
              </ThemeProvider>
            </WebcamCaptureProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
