import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from '@mui/material/Unstable_Grid2';
import Pagination from "@mui/material/Pagination";

import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { selectBearerToken } from "../../globalSlice";
import { getCompaniesPaginated } from '../../fetchers/companies';
import CompanyCard from "./CompanyCard";
import SelectionGridItem from '../../Components/SelectionGridItem/SelectionGridItem';

function CompanySelection({ selectedCompany, setSelectedCompany }) {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);

  const token = useSelector(selectBearerToken);

  const { isLoading, isError, data, isPreviousData } =
    useQuery({
      queryKey: ["companies", page],
      queryFn: async () => getCompaniesPaginated(token, page, 12),
      keepPreviousData: true,
      staleTime: 5000,
      retry: false,
    });

  useEffect(() => {
    if (!isPreviousData && data?.totalPages < page - 1) {
      console.log("pre");
      queryClient.prefetchQuery({
        queryKey: ["companies", page + 1],
        queryFn: () => getCompaniesPaginated(token, page + 1, 2),
      });
    }
  }, [data, isPreviousData, page, queryClient]);

  return (
    <>
      <GoBackButton sx={{ marginX: 3, marginY: 2 }} />
      <Typography
        variant="h3"
        textAlign="center"
        sx={{ marginTop: 2 }}
      >
        Company Selection
      </Typography>
      <Typography
        color="grey.800"
        variant="subtitle1"
        textAlign="center"
        gutterBottom
      >
        Please select a company to load practices for:
      </Typography>
      <Stack alignItems="center" direction="column" marginX={2}>
        {isLoading && <CircularProgress />}
        {isError && <Alert severity="error">An error occurred</Alert>}
        <Grid container spacing={2} justifyContent="center" sx={{ margin: 2, width: '100%' }}>
          {data?.content.map((company) => (
            <SelectionGridItem key={`patient_card_${company.id}`}>
              <CompanyCard company={company} selected={selectedCompany?.id === company.id} onSelect={setSelectedCompany} />
            </SelectionGridItem>
          ))}
          {data?.content.length === 0 && (
            <Alert severity="error">No data to display</Alert>
          )}
        </Grid>
        <Box>
          <Pagination
            disabled={isLoading || isError}
            count={data?.totalPages}
            page={page + 1}
            onChange={(e, nextPage) => setPage(nextPage - 1)}
            shape="rounded"
          />
        </Box>
      </Stack>
    </>
  )
}

export default CompanySelection;