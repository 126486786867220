import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useQuery } from "@tanstack/react-query";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { getAnomalyTypes, getAnomaliesOfType } from "../../fetchers/anomalies";
import { selectBearerToken } from "../../globalSlice";

function AnomalySelection({ anomaly, setAnomaly }) {
  const [anomalyType, setAnomalyType] = useState("");
  const token = useSelector(selectBearerToken);

  const anomalyTypeQ = useQuery({
    queryKey: ["anomalyTypes"],
    queryFn: () => getAnomalyTypes(token),
    retry: false,
  });

  const anomaliesOfTypeQ = useQuery({
    queryKey: ["anomaliesOfType", anomalyType],
    queryFn: () => getAnomaliesOfType(token, anomalyType),
    enabled: !!anomalyType,
    retry: false,
  });

  const handleAnomalyTypeChange = (e) => {
    const { value } = e.target;
    setAnomalyType(value);
    setAnomaly(null);
  };

  const handleAnomalyChange = (event, newValue) => {
    setAnomaly(newValue);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl sx={{ m: 1, width: "200px" }} color="secondary">
        <InputLabel id="select-anomaly-type-label">Anomaly Type</InputLabel>
        <Select
          labelId="select-anomaly-type-label"
          id="select-anomaly-type"
          value={anomalyType}
          label="Anomaly Type"
          onChange={handleAnomalyTypeChange}
        >
          {anomalyTypeQ.data &&
            anomalyTypeQ.data.map((anomalyTypeItem) => (
              <MenuItem
                sx={{ textTransform: "uppercase", ':hover': { color: 'common.black' } }}
                key={`anomaly_type_select_item_${anomalyTypeItem}`}
                value={anomalyTypeItem}
              >
                {anomalyTypeItem.split("_").join(" ")}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>Anomaly Type</FormHelperText>
      </FormControl>
      <Autocomplete
        disablePortal
        disabled={anomalyTypeQ.isError || anomalyTypeQ.isLoading}
        loading={anomaliesOfTypeQ.isLoading}
        id="anomaly-search"
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={anomaliesOfTypeQ.data || []}
        onChange={handleAnomalyChange}
        value={anomaly}
        sx={{ minWidth: "300px", m: 1 }}
        loadingText={anomalyType ? 'Loading...' : 'Please select an anomaly type first'}
        renderInput={(params) => <TextField {...params} label="Anomaly" />}
      />
    </Box>
  );
}

export default AnomalySelection;
