import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';

function CompanyDetail({ companyDetails }) {
  return (
    <Grid container spacing={2} >
      <Grid item xs={6}>
        <Box sx={{ m: 2 }} >

        
          <Typography
            color="grey.800"
            variant="subtitle1"
            sx={{
              transition: "color 0.15s ease-in-out",
              textTransform: "capitalize",
            }}
          >
            name: <b>{companyDetails.name ?? ''}</b>
          </Typography>

        
          <Typography color="grey.800" variant="subtitle1">
            Date Joined: <b>{companyDetails.dateJoined ?? ''}</b>
          </Typography>
          <Typography color="grey.800" variant="subtitle1">
            Status: <Chip
              sx={{ marginTop: 2 }}
              color={companyDetails.status === 'ACTIVE'? 'active': 'disabled' }
              label={companyDetails.status}
            />
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Box sx={{ m: 2 }} >
         
          <Typography sx={{ color: "grey.800" }}>
            <b>{companyDetails.address.addressLine1 ?? ''} {companyDetails.address.addressLine2 ?? ''}</b>
          </Typography>
          <Typography fontWeight={600} sx={{ color: "grey.800" }}>
            {companyDetails.address.city ?? "-"},{` `}
            {companyDetails.address.province ?? "-"},{` `}
            {companyDetails.address.postalCode ?? "-"}
          </Typography>

        </Box>
      </Grid>
    </Grid>
  );
}

export default CompanyDetail;
