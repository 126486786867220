import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadUserViaToken, selectGlobalUser } from '../globalSlice';
import Unauthorized from './Unauthorized';
import Header from '../Components/Header/Header';
import { headerAccessSettings, AccessGroups, ALL_GROUPS } from './AccessGroups';
import { Box, CircularProgress, Typography } from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import styled from "@emotion/styled";

const MenuWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const drawerWidth = 200;

const PrivateRoute = ({ allowedGroups = ALL_GROUPS, children }) => {
  const { loading, user, error } = useSelector(selectGlobalUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && !loading && !error) {
      dispatch(loadUserViaToken());
    }
  }, [dispatch, user, loading, error]);

  if (loading) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <CircularProgress />
        <Typography marginTop={2} variant="h5">
          Validating Token...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Navigate to="/auth/login" />;
  }

  if (user) {
    if (!allowedGroups.includes(user.group)) {
      return <Unauthorized additionalError={{}} />;
    }

    return (
      <MenuWrapper>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          >
            <Header
              pages={headerAccessSettings.pages[user.group]}
              userLinks={headerAccessSettings.userLinks[user.group]}
            />
            {children ? children : <Outlet />}
          </Box>
        </Box>
      </MenuWrapper>
    );
  }

  return null;
};

export default PrivateRoute;
