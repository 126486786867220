import React from "react";
import { Box, Link, Typography } from "@mui/material";
import GoBackButton from "../Components/GoBackBtn/GoBackBtn";

function FourOFour() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Typography>Page not found! Please go back.</Typography>
      <Link to="/">Home page</Link>
      <GoBackButton sx={{ marginTop: 2 }} />
    </Box>
  );
}

export default FourOFour;
