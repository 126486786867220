import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import FormHelperText from '@mui/material/FormHelperText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const selectableLayers = ["1", "2", "3", "4", "5", "6", "7", "8"];

const sortLayers = (a,b) => parseInt(a, 10) - parseInt(b, 10);

function LayersSelect({ layers, setLayers }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLayers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",").sort(sortLayers) : value.sort(sortLayers)
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1 }}>
        <InputLabel id="select-layers-select-label" color="secondary">Layers*</InputLabel>
        <Select
          labelId="select-layers-select-label"
          id="select-layers-select"
          multiple
          color="secondary"
          value={layers}
          onChange={handleChange}
          input={<OutlinedInput id="outlined-layers-input" label="Layers*" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} color="secondary" />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {selectableLayers.map((layer) => (
            <MenuItem
              key={`layer_item_${layer}`}
              value={layer}
            >
              Layer: {layer}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Snapshot Layers</FormHelperText>
      </FormControl>
    </div>
  );
}

export default LayersSelect;
