import React, { useState } from "react";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";

import CompanySelection from "../CompanySelection/CompanySelection";
import PracticeSelection from "../PracticesSelection/PracticesSelection";

function PracticeSelectionPage() {
  const [selectedCompany, setSelectedCompany] = useState(null);

  return (
    <Box>
      <CompanySelection
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
      {selectedCompany && (
        <PracticeSelection companyId={selectedCompany?.id} />
      )}
    </Box>
  );
}

export default PracticeSelectionPage;
