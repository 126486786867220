import React, { createContext, useContext, useState } from "react";

export const WebcamCaptureContext = createContext();

export const useWebcamCaptureContext = () => {
  return useContext(WebcamCaptureContext);
};

export const WebcamCaptureProvider = ({ children }) => {
  // Define your state and functions here, including gradeValues
  const [gradeValues, setGradeValues] = useState({});

  // Provide the gradeValues and any other context data here
  const contextValue = {
    gradeValues,
    setGradeValues, // If you need to update gradeValues
  };

  return (
    <WebcamCaptureContext.Provider value={contextValue}>
      {children}
    </WebcamCaptureContext.Provider>
  );
};