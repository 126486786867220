import React from 'react';
import Divider from '@mui/material/Divider';
import PatientSelection from '../PatientSelection/PatientSelection';
import PatientDetailsForm from './PatientDetails/PatientDetailsForm';

function PatientsPage() {
  return (
    <>
      <PatientSelection />
      <Divider sx={{ marginY: 4 }} />
      <PatientDetailsForm />
    </>
  )
}

export default PatientsPage;