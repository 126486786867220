import React from 'react';
import {TextField} from "@mui/material";

function MaterialField({formik, name, label, children, ...props}) {
    return <TextField
        fullWidth
        margin="normal"
        id={name}
        name={name}
        label={label}
        value={formik.values[name]}
        onChange={formik.handleChange}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        {...props}
    >
        {children}
    </TextField>
}

export default MaterialField;
