import React, { useState } from 'react';
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  border: 2px solid red;
  background-color: #ffffff;
`;

const MidWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
   padding: 80px 0;
  height: 100%;
  border: 2px solid green;
`;

const SideHalfLight = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
 
  width: 100%;
  height: 100%;
  border: 2px solid blue;
`;

const SideHalfDark = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width:  50%;
  height: 100%;
  background-color: #333366;
`;

function Login() {
 

  return (
    <Wrapper>
    <MidWrapper>
      <SideHalfLight>
      
      </SideHalfLight>
      </MidWrapper>
      <SideHalfDark>
      
      </SideHalfDark>
   
    </Wrapper>
  );
}

export default Login;