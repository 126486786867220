import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import EditVisitForm from "./EditVisitForm";
import { selectBearerToken } from "../../globalSlice";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { apiBaseUrl } from "../../utilities";

const Row = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

export default function EditVisitPage() {
  const { visitId } = useParams();
  const [visitData, setVisitData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = useSelector(selectBearerToken);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/cmn/v1/visits/${visitId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Received visitData:", response.data);
        setVisitData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [visitId, token]);

  if (loading) {
    return(
      <Row>
        <CircularProgress />
      </Row>
    )
  }

  if (error) {
    return(
      <Row>
       error: { error }
      </Row>
    )
  }

  return (
    <Box>
      <GoBackButton sx={{ m: 2 }} />
      <Box>
        {visitData && <EditVisitForm visitData={visitData} />}
      </Box>
    </Box>
  );
}
