import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import TypeSelectionCard from '../../Components/TypeSelectionCard/TypeSelectionCard';

const Card = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  min-height: 180px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
`;

const CardHeading = styled.h4`
  position: relative;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
`;

const Row = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const RowLocation = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
`;

const Block = styled.div`
  position: relative;
  display: inline-flex;
`;

const Footer = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-self: flex-end;
`;

function PatientCard({ patient, baseLink = "" }) {
  let genderColor;
  switch (patient.gender) {
    case "MALE":
      genderColor = "genderMale";
      break;
    case "FEMALE":
      genderColor = "genderFemale";
      break;
    default:
    // Keep undefined
  }

  const physicalAddress = patient.physicalAddress || {};
  const {
    addressLine1 = "-",
    city = "-",
    province = "-",
    postalCode = "-"
  } = physicalAddress;

  return (
    <Card to={`${baseLink}${patient.id}`}>
      <Row>
        <Block>
          <CardHeading
            color="secondary.light"
            variant="h5"
            sx={{
              transition: "color 0.15s ease-in-out",
              textTransform: "capitalize",
            }}
          >
            {patient.name} {patient.surname}
          </CardHeading>
        </Block>
        <Block>
          <Chip
            sx={{ marginBottom: 1 }}
            color={genderColor}
            label={patient.gender}
          />
        </Block>
      </Row>
      <Row>
        <Block>
          <Typography fontWeight={600} color="grey.800" variant="subtitle1">
            {patient.idNumber}
          </Typography>
        </Block>
        <Block>
          <Typography color="grey.600" variant="subtitle1">
            {patient.dateOfBirth}
          </Typography>
        </Block>
      </Row>

      <RowLocation>
        <Typography sx={{ color: "grey.600" }}>
          {addressLine1}, {city}, {province}, {postalCode}
        </Typography>
      </RowLocation>

      <Footer>
        <button className="btn btn-light">Reports</button>
        <button className="btn btn-light">Visits</button>
      </Footer>
    </Card>
  );
}

export default PatientCard;
