import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useSelector } from "react-redux";

import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";

import Grid from "@mui/material/Unstable_Grid2";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  Divider,
  Switch,
  FormControlLabel,
  Stack,
  Alert,
} from "@mui/material";

import "./PatientDetailsForm.css";
import { selectBearerToken } from "../../../globalSlice";
import MaterialField from "../../../Components/Formik/MaterialField";
import { apiBaseUrl } from "../../../utilities";
import { provinceOptions } from "../../../utilities_forms";

const PatientDetailsForm = () => {
  const [usePhysicalAddress, setUsePhysicalAddress] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const token = useSelector(selectBearerToken);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const genderOptions = [
    { key: "Male", value: "MALE" },
    { key: "Female", value: "FEMALE" },
  ];

  const initialValues = {
    fName: "",
    lName: "",
    dateOfBirth: null,
    title: "",
    gender: "",
    southAfricanId: "",
    cNumber: "",
    hNumber: "",
    wNumber: "",
    email: "",
    relativeName: "",
    rContactNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    province: "",
    postalCode: "",
    medicalAidProvider: "",
    medicalAidNumber: "",
    mainMember: "",
    currentConditions: "",
    currentSupplements: "",
    currentMedication: "",
    pAddressLine1: "",
    pAddressLine2: "",
    pCity: "",
    pProvince: "",
    pPostalCode: "",
  };
  const validationSchema = Yup.object({
    fName: Yup.string().required("Required field"),
    lName: Yup.string().required("Required field"),
    dateOfBirth: Yup.date().required("Required field").nullable(),
    title: Yup.string().required("Required field"),
    gender: Yup.string().required("Required field"),
    southAfricanId: Yup.string()
      .required("Required field")
      .length(13, "Invalid ID Number: Too short")
      .test(
        "Numbers Only",
        "Invalid ID Number: ID can only contain numbers",
        (value) => /^\d+$/.test(value)
      ),
    cNumber: Yup.string()
      .required("Required field")
      .matches(phoneRegExp, "Phone number is not valid"),
    hNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    wNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Required field"),
    relativeName: Yup.string().required("Required field"),
    rContactNumber: Yup.string()
      .required("Required field")
      .matches(phoneRegExp, "Phone number is not valid"),
    addressLine1: Yup.string().required("Required field"),
    city: Yup.string().required("Required field"),
    province: Yup.string().required("Required field"),
    postalCode: Yup.string()
      .required("Required field")
      .length(4, "Invalid postal code. Should be 4 digits long.")
      .test("Numbers Only", "Postal code can only contain numbers", (value) =>
        /^\d+$/.test(value)
      ),
    medicalAidProvider: Yup.string().required("Required field"),
    medicalAidNumber: Yup.string().required("Required field"),
    mainMember: Yup.string().required("Required field"),
    currentConditions: Yup.string().required("Required field"),
    currentSupplements: Yup.string().required("Required field"),
    currentMedication: Yup.string().required("Required field"),
  });

  const triggerSuccessMsg = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  };

  const submitForm = (values, { resetForm }) => {
    setErrorMsg(null);
    const jsonForm = {
      name: values.fName,
      surname: values.lName,
      dateOfBirth: values.dateOfBirth,
      title: values.title,
      gender: values.gender,
      idNumber: values.southAfricanId,
      contactDetails: {
        cellphoneNumber: values.cNumber,
        homeNumber: values.hNumber,
        workNumber: values.wNumber,
        email: values.email,
        familyMember: values.familyMember,
        familyMemberNumber: values.familyMemberNumber,
      },
      medicalDetails: {
        provider: values.medicalAidProvider,
        medicalNumber: values.medicalAidNumber,
        mainMember: values.mainMember,
        currentConditions: values.currentConditions,
        currentMedication: values.currentMedication,
        currentSupplements: values.currentSupplements,
      },
      physicalAddress: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        province: values.province,
        postalCode: values.postalCode,
      },
      postalAddress: {
        addressLine1: usePhysicalAddress
          ? values.addressLine1
          : values.pAddressLine1,
        addressLine2: usePhysicalAddress
          ? values.addressLine2
          : values.pAddressLine2,
        city: usePhysicalAddress ? values.city : values.pCity,
        province: usePhysicalAddress ? values.province : values.pProvince,
        postalCode: usePhysicalAddress ? values.postalCode : values.pPostalCode,
      },
    };
    setSubmitting(true);
    axios
      .post(`${apiBaseUrl}/cmn/v1/patient-details`, JSON.stringify(jsonForm), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((results) => {
        triggerSuccessMsg();
        resetForm();
      })
      .catch((error) => {
        try {
          setErrorMsg(error.response.data.message);
        } catch (e) {
          setErrorMsg(error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
  });

  const datePickerProps = {
    label: "*Date of Birth",
    inputFormat: "dd/MM/yyyy",
    disableFuture: true,
    value: formik.values.dateOfBirth,

    onChange: (value) => formik.setFieldValue("dateOfBirth", value, true),
    renderInput: (params) => (
      <TextField
        {...params}
        fullWidth
        error={Boolean(formik.touched.dateOfBirth && formik.errors.dateOfBirth)}
        helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
        margin="normal"
        name="dateOfBirth"
      />
    ),
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3} sx={{ paddingX: 3 }}>
        <Grid md={12} lg={8} lgOffset={2} xl={12} xlOffset={0}>
          <Typography variant="h4">Add Client</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
          <Box>
            <Typography variant="h5">Personal Details</Typography>
            <MaterialField formik={formik} name="fName" label="*First Name" />
            <MaterialField formik={formik} name="lName" label="*Last Name" />
           
            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
              <MobileDatePicker {...datePickerProps} />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' }}}>
              <DesktopDatePicker {...datePickerProps} />
            </Box>
            <MaterialField formik={formik} name="title" label="*Title" />
            <MaterialField formik={formik} name="gender" label="*Gender" select>
              {genderOptions.map((genderOption) => (
                <MenuItem
                  key={genderOption.key}
                  value={genderOption.value}
                  disabled={!Boolean(genderOption.value)}
                >
                  {genderOption.key}
                </MenuItem>
              ))}
            </MaterialField>
            <MaterialField
              formik={formik}
              name="southAfricanId"
              label="*South African ID"
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
          <Box>
            <Typography variant="h5">Contact Details</Typography>
            <MaterialField
              formik={formik}
              name="cNumber"
              label="*Cellphone Number"
            />
            <MaterialField formik={formik} name="hNumber" label="Home Number" />
            <MaterialField formik={formik} name="wNumber" label="Work Number" />
            <MaterialField
              formik={formik}
              name="email"
              label="*Email Address"
            />
            <MaterialField
              formik={formik}
              name="relativeName"
              label="*Relative Name"
            />
            <MaterialField
              formik={formik}
              name="rContactNumber"
              label="*Relative Contact Number"
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
          <Box>
            <Typography variant="h5">Physical Address</Typography>
            <MaterialField
              formik={formik}
              name="addressLine1"
              label="*Address Line 1"
            />
            <MaterialField
              formik={formik}
              name="addressLine2"
              label="Address Line 2"
            />
            <MaterialField formik={formik} name="city" label="*City" />
            <MaterialField
              formik={formik}
              name="province"
              label="*Province"
              select
            >
              {provinceOptions.map((provinceOption) => (
                <MenuItem
                  key={provinceOption.key}
                  value={provinceOption.value}
                  disabled={!Boolean(provinceOption.value)}
                >
                  {provinceOption.key}
                </MenuItem>
              ))}
            </MaterialField>
            <MaterialField
              formik={formik}
              name="postalCode"
              label="*Postal Code"
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
          <Box>
            <Stack
              spacing={3}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5">Postal Address</Typography>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={usePhysicalAddress}
                    onChange={(event) => {
                      setUsePhysicalAddress(event.target.checked);
                    }}
                  />
                }
                label="Same as Physical"
              />
            </Stack>

            <MaterialField
              formik={formik}
              name="pAddressLine1"
              label="Address Line 1"
              disabled={usePhysicalAddress}
            />
            <MaterialField
              formik={formik}
              name="pAddressLine2"
              label="Address Line 2"
              disabled={usePhysicalAddress}
            />
            <MaterialField
              formik={formik}
              name="pCity"
              label="City"
              disabled={usePhysicalAddress}
            />
            <MaterialField
              formik={formik}
              name="pProvince"
              label="Province"
              select
              disabled={usePhysicalAddress}
            >
              {provinceOptions.map((provinceOption) => (
                <MenuItem
                  key={`postal_${provinceOption.key}`}
                  value={provinceOption.value}
                  disabled={!Boolean(provinceOption.value)}
                >
                  {provinceOption.key}
                </MenuItem>
              ))}
            </MaterialField>
            <MaterialField
              formik={formik}
              name="pPostalCode"
              label="*Postal Code"
              disabled={usePhysicalAddress}
            />
          </Box>
        </Grid>
        <Grid xs={12} md={6} lg={8} lgOffset={2} xl={6} xlOffset={0}>
          <Box>
            <Typography variant="h5">Medical Detail</Typography>
            <MaterialField
              formik={formik}
              name="medicalAidProvider"
              label="*Medical Aid Provider"
            />
            <MaterialField
              formik={formik}
              name="medicalAidNumber"
              label="*Medical Aid Number"
            />
            <MaterialField
              formik={formik}
              name="mainMember"
              label="*Main Member"
            />
            <MaterialField
              formik={formik}
              name="currentConditions"
              label="*Current Conditions"
            />
            <MaterialField
              formik={formik}
              name="currentMedication"
              label="*Current Medication"
            />
            <MaterialField
              formik={formik}
              name="currentSupplements"
              label="*Current Supplements"
            />
          </Box>
        </Grid>
        <Grid xs={12} md={12} lg={8} lgOffset={2} xl={12} xlOffset={0}>
          <Box paddingBottom={3}>
            {showSuccess && (
              <Alert severity="success">Details successfully captured.</Alert>
            )}
            {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end", my: 3 }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={submitting}
            >
              {submitting ? "Submitting" : "Submit"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default PatientDetailsForm;
